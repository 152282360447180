export const GET_MINHAS_CONTAS_PERIODO = 'GET_MINHAS_CONTAS_PERIODO';
export const GET_MINHAS_CONTAS_MES_ANTERIOR = 'GET_MINHAS_CONTAS_MES_ANTERIOR';
export const GET_MINHAS_CONTAS_PERIODO_ANALISE = 'GET_MINHAS_CONTAS_PERIODO_ANALISE';
export const GET_MINHAS_CONTAS_PERIODO_CALENDARIO = 'GET_MINHAS_CONTAS_PERIODO_CALENDARIO';
export const GET_MINHAS_CONTAS_PERIODO_ANUALIZADO = 'GET_MINHAS_CONTAS_PERIODO_ANUALIZADO';
export const SET_DATA_CONTEXT_LANCAMENTO = 'SET_DATA_CONTEXT_LANCAMENTO';
export const SET_DATA_CONTEXT_LANCAMENTO_ANUALIZADO = 'SET_DATA_CONTEXT_LANCAMENTO_ANUALIZADO';
export const SET_DATA_CONTEXT_LANCAMENTO_TREEMAP = 'SET_DATA_CONTEXT_LANCAMENTO_TREEMAP';
export const SET_LOADING_LANCAMENTO = 'SET_LOADING_LANCAMENTO';
export const SET_LOADING_LANCAMENTO_ANUALIZADO = 'SET_LOADING_LANCAMENTO_ANUALIZADO';
export const SET_LOADING_LANCAMENTO_TREEMAP = 'SET_LOADING_LANCAMENTO_TREEMAP';
export const SET_LANCAMENTO = 'SET_LANCAMENTO';
export const SET_SHOW_MODAL_LANCAMENTO = 'SET_SHOW_MODAL_LANCAMENTO';
export const ADD_LANCAMENTO = 'ADD_LANCAMENTO';
export const UPDATE_LANCAMENTO = 'UPDATE_LANCAMENTO';
export const ERROR_LANCAMENTO = 'ERROR_LANCAMENTO';
export const CLEAR_STATE_LANCAMENTO = 'CLEAR_STATE_LANCAMENTO';
export const REMOVE_LANCAMENTO = 'REMOVE_LANCAMENTO';
export const GET_ARRAY_VALORES_TOTAIS = 'GET_ARRAY_VALORES_TOTAIS';
export const SET_ARRAY_VALORES_MESES = 'SET_ARRAY_VALORES_MESES';
export const GET_SERIE_ARRAY_VALORES_TOTAIS_LANCAMENTOS = 'GET_SERIE_ARRAY_VALORES_TOTAIS_LANCAMENTOS';
export const GET_SERIE_ARRAY_TREEMAP_LANCAMENTOS = 'GET_SERIE_ARRAY_TREEMAP_LANCAMENTOS';
export const GET_ARRAY_SERIE_EVOLUCAO_PATRIMONIAL = 'GET_ARRAY_SERIE_EVOLUCAO_PATRIMONIAL';
export const GET_LANCAMENTOS_BY_OBJETIVO_SK = 'GET_LANCAMENTOS_BY_OBJETIVO_SK';