import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import 'react-responsive-modal/styles.css';

import Tabs from '../../../../components/tabs/Tabs';
import TabItem from '../../../../components/tabs/TabItem';
import TabContent from '../../../../components/tabs/TabContent';
import { setTabActiveById } from '../../../../components/tabs/action/TabsAction';
import {
	setShowModalCarteira,
	setIdCarteiraCurrent,
	getMinhaCarteiraBySK,
	setLoadingCarteira
} from './action/MinhaCarteiraAction';
import Spinner from '../../../../components/preLoader/Spinner';
// import Button from '../../../../components/form/Button';
// import Tooltip from '../../../../components/common/Tooltip';
// import If from '../../../../components/common/If';
// import TableCarteiraRendaVariavel from './TableCarteiraRendaVariavel';
// import TableCarteiraRendaFixa from './TableCarteiraRendaFixa';
import Row from '../../../../components/common/Row';
// import { formataMoeda } from '../../../../../config/helper/utils';
import { setErroAutenticacao } from '../../../../minimal/autenticacao/action/AuthAction';
import NaoHaAtivoCarteira from './NaoHaAtivoCarteira';

const TabsAtivos = (props) => {
	const {
		setShowModalCarteira,
		setIdCarteiraCurrent,
		carteira,
		setLoadingCarteira,
		setErroAutenticacao,
		getMinhaCarteiraBySK,
		loadingCarteira,
		skCarteira
	} = props;

	useEffect(() => {
		setLoadingCarteira(true);
		setTabActiveById(1); //Deixando a primeira tab como selecionada
		getMinhaCarteiraBySK(skCarteira)
			.then(() => {
				setLoadingCarteira(false);
			})
			.catch((err) => {
				if (err.code === 'NotAuthorizedException') {
					setErroAutenticacao(err);
				}
				setLoadingCarteira(false);
			});
	}, []);

	if (loadingCarteira) {
		return <Spinner id='spinner-centro-sm' />;
	}

	return (
		!loadingCarteira &&
		carteira !== null && ( //testar para saber se carteira tá NULL
			<>
				<Row>
					<div className='col-12'></div>
				</Row>
				<Tabs classes='nav-fill'>
					<TabItem id='1' text='Renda Variável' />
					<TabItem id='2' text='Renda Fixa' />
				</Tabs>
				<TabContent id='1'>
					{carteira.carteiraRV.length > 0 ? (
						<>Renda Variável</>
					) : (
						<NaoHaAtivoCarteira height='60vh' />
					)}
				</TabContent>
				<TabContent id='2'>
					{carteira.carteiraRF.length > 0 ? (
						<>Renda Variável</>
					) : (
						<NaoHaAtivoCarteira height='60vh' />
					)}
				</TabContent>
			</>
		)
	);
};

const mapStateToProps = (state) => ({
	showModalCarteira: state.carteiraStore.showModalCarteira,
	carteira: state.carteiraStore.carteira,
	erroCountInvestidorCarteira: state.carteiraStore.erroCountInvestidorCarteira,
	loadingCarteira: state.carteiraStore.loadingCarteira,
	skCarteira: state.carteiraStore.skCarteira
});

export default connect(mapStateToProps, {
	setShowModalCarteira,
	setIdCarteiraCurrent,
	setLoadingCarteira,
	setErroAutenticacao,
	getMinhaCarteiraBySK
})(TabsAtivos);
