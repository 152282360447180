import React from 'react';

import Title from '../../../components/common/Title';
import { callAlert } from '../../../../config/helper/utils';

const CarteiraRendaVariavel = (props) => {
	return (
		<>
			<Title text='Carteira de Renda Variável' />
			{callAlert(props)}
		</>
	);
};

export default CarteiraRendaVariavel;
