import React from 'react';
import { connect } from 'react-redux';
import { useField, Field } from 'formik';
import PropTypes from 'prop-types';

import { setPasswordVisibility } from './action/FormAction';

const CustomTextInput = (props) => {
	const [field, meta] = useField(props);
	const { className, passwordVisibility, setPasswordVisibility, isPassword = false } = props;

	return (
		<>
			<Field {...field} >
				{({ field, meta }) => (
					<input
						{...field}
						{...props}
						className={`${
							meta.error && `border border-secondary`
						} form-control ${className}`}
					/>
				)}
			</Field>
			{isPassword && (
				<span
					className={`fas ${passwordVisibility ? 'fa-eye-slash' : 'fa-eye'} eye-slash`}
					onClick={() => {
						setPasswordVisibility(!passwordVisibility)
					}}
				></span>
			)}
			{/* meta.touched &&  */}
			{meta.error ? (
				<div className='form-be-custom-error'>{meta.error}</div>
			) : null}
		</>
	);
};

CustomTextInput.propTypes = {
	name: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	placeholder: PropTypes.string,
	className: PropTypes.string.isRequired,
	isPassword: PropTypes.bool
};

const mapStateToProps = (state) => ({
	passwordVisibility: state.formStore.passwordVisibility
});
export default connect(mapStateToProps, { setPasswordVisibility })(CustomTextInput);
