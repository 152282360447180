import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import SimpleBar from 'simplebar-react';
import swal from 'sweetalert';
import moment from 'moment';
// import BootstrapTable from 'react-bootstrap-table-next';
// import Spinner from '../../../components/preLoader/Spinner';
import Button from '../../../components/form/Button';
import { formataMoeda, formataData } from '../../../../config/helper/utils';
import {
	remove,
	setLancamentoCurrent,
	getMinhasContasPeriodo
} from './action/ArrumandoCasaAction';
import {
	success,
	error,
	MSG_TOAST_ERRO_DE_NOVO
} from '../../../components/toast/action/ToastAction';
import { setErroAutenticacao } from '../../../minimal/autenticacao/action/AuthAction';
import { setLoading } from '../../../components/common/action/CommonAction';
import Row from '../../../components/common/Row';
import If from '../../../components/common/If';
import CardQuantoRecebi from './CardQuantoRecebi';
import CardQuantoGastei from './CardQuantoGastei';
import CardQuantoInvesti from './CardQuantoInvesti';
import CardQuantoGasteiDesejos from './CardQuantoGasteiDesejos';
import Spinner from '../../../components/preLoader/Spinner';
import NaoHaLancamento from './NaoHaLancamento';

const ListaLancamento = (props) => {
	const {
		lancamentos,
		remove,
		setLancamentoCurrent,
		erroCountLancamentos,
		getMinhasContasPeriodo,
		dataContextLancamentos,
		setLoading,
		loading,
		success,
		error,
		setErroAutenticacao
	} = props;

	const handleRemove = async (lancamento) => {
		let myhtml = document.createElement('div');
		myhtml.innerHTML = `Tem certeza que deseja apagar o lançamento <b>${
			lancamento.nome
		}</b>
		no valor de <b>${formataMoeda(lancamento.valor)}</b>`;

		swal({
			title: 'Tem certaza?!',
			content: myhtml,
			icon: 'warning',

			buttons: {
				reenviar: {
					text: 'Cancelar',
					value: 'cancelar',
					className: 'btn-secondary'
				},

				confirmar: {
					text: 'Confirmar',
					value: 'confirmar',
					className: 'btn-primary'
				}
			}
		}).then((value) => {
			setLoading(true);
			if (value === 'confirmar') {
				remove(lancamento)
					.then(() => {
						success();
						setLoading(false);
					})
					.catch((err) => {
						if (erroCountLancamentos >= 1) {
							error(MSG_TOAST_ERRO_DE_NOVO);
						} else {
							error();
						}
						setLoading(false);
					});
			} else {
				setLancamentoCurrent(null, false);
			}
		});
	};

	const handleUpdate = (lancamento) => {
		if (moment(lancamento.dataOperacao).isValid()) {
			lancamento.dataOperacao = moment(lancamento.dataOperacao).utc();
		}
		setLancamentoCurrent(lancamento, true);
	};

	const [column, setColumn] = useState();
	const [order, setOrder] = useState(false); //Crescente

	useEffect(() => {
		setLoading(true);
		// eslint-disable-next-line

		getMinhasContasPeriodo(dataContextLancamentos).then(() => {
			setLoading(false);
		}).catch((err) => {
			if (err.code === 'NotAuthorizedException') {
				setErroAutenticacao(err);
			}
			setLoading(false);		
		});
		// eslint-disable-next-line

		// eslint-disable-next-line
	}, [dataContextLancamentos]);

	if (loading) {
		return <Spinner id='spinner-centro-sm' />;
	}

	const renderHeader = () => (
		<tr>
			<th>
			</th>
			<th style={{ width: '150px' }}>Lançamento</th>
			<th style={{ width: '300px' }}>Descrição</th>
			<th
				onClick={() => {
					setColumn('dataOperacao');
					setOrder(!order);
				}}
			>
				Data
				<span className='ml-2'>
					{order && column === 'dataOperacao' ? (
						<i className='fas fa-sort-up'></i>
					) : (
						<i className='fas fa-sort-down'></i>
					)}
				</span>
			</th>
			<th
				onClick={() => {
					setColumn('valor');
					setOrder(!order);
				}}
			>
				Valor
				<span className='ml-2'>
					{order && column === 'valor' ? (
						<i className='fas fa-sort-up'></i>
					) : (
						<i className='fas fa-sort-down'></i>
					)}
				</span>
			</th>
			<th style={{ width: '125px' }}>Ações</th>
		</tr>
	);

	const renderRows = (order, column = 'dataOperacao') => {
		return lancamentos
			.sort((a, b) => {
				if (order) {
					return a[column] < b[column] ? -1 : a[column] > b[column] ? 1 : 0;
				} else {
					return b[column] < a[column] ? -1 : b[column] > a[column] ? 1 : 0;
				}
			})
			.map((lancamento, index) => (
				<tr key={index}>
					<td>
						<h5>
							<span
								className={`badge ${
									lancamento.tipoLancamento.value === 'gasto' ||
									lancamento.tipoLancamento.value === 'desejo'
										? 'badge-secondary-lighten'
										: 'badge-primary-lighten'
								}`}
							>
								<If test={lancamento.tipoLancamento.value === 'recebimento'}>
									<i className='fas fa-dollar-sign px-1'></i>
								</If>
								<If test={lancamento.tipoLancamento.value === 'gasto'}>
									<i className='far fa-credit-card'></i>
								</If>
								<If test={lancamento.tipoLancamento.value === 'desejo'}>
									<i className='fas fa-glass-cheers'></i>
								</If>
								<If test={lancamento.tipoLancamento.value === 'investimento'}>
									<i className='fas fa-hand-holding-usd'></i>
								</If>
							</span>
						</h5>
					</td>
					<td>
						<span className='text-gray-800'>{lancamento.nome}</span>
					</td>
					<td>{lancamento.descricao}</td>
					<td>{formataData(lancamento.dataOperacao)}</td>
					<td>{formataMoeda(lancamento.valor)}</td>
					<td>
						<Button
							icon='fas fa-edit'
							title='Editar lançamento'
							classes='btn action-icon'
							onClick={() => handleUpdate(lancamento)}
							disabled={false}
						/>

						<Button
							icon='fas fa-trash'
							title='Remover lançamento'
							classes='btn action-icon'
							onClick={() => handleRemove(lancamento)}
							disabled={false}
						/>

						{/* <Button
							icon='fas fa-plus'
							title='Lançar com investimento'
							classes='btn action-icon'
							onClick={() => handleLancaInvestimento(lancamento)}
							disabled={false}
						/> */}
					</td>
				</tr>
			));
	};

	return lancamentos && lancamentos.length > 0 ? (
		<>
			<Row classes='mt-sm-4 mt-2'>
				<div className='col-lg-3 col-md-6 mb-4'>
					<CardQuantoRecebi />
				</div>
				<div className='col-lg-3 col-md-6 mb-4'>
					<CardQuantoGastei />
				</div>
				<div className='col-lg-3 col-md-6 mb-4'>
					<CardQuantoGasteiDesejos />
				</div>
				<div className='col-lg-3 col-md-6 mb-4'>
					<CardQuantoInvesti />
				</div>
				{/* <div className='col-lg-3 col-md-12 mb-sm-4 mb-0'>
					<CardMeuSaldo
						leftColor={`${
							totalRecebimentos - totalInvestimento - totalGastos > 0
								? 'border-left-primary'
								: 'border-left-secondary'
						}`}
						value={totalRecebimentos - totalInvestimento - totalGastos}
					/>
				</div> */}
			</Row>
			<Row>
				<div className='table-responsive fixed-first-row lista-height'>
					<SimpleBar style={{ height: '100%' }}>
						<table className='table table-centered mb-0'>
							<thead className='thead-light'>{renderHeader()}</thead>
							<tbody>{renderRows(order, column)}</tbody>
						</table>
					</SimpleBar>
				</div>
			</Row>
		</>
	) : (
		<NaoHaLancamento height='60vh' />
	);
};

const mapStateToProps = (state) => ({
	lancamentos: state.arrumaCasaStore.lancamentos,
	erroCountLancamentos: state.arrumaCasaStore.erroCountLancamentos,
	isErroGetLancamentos: state.arrumaCasaStore.isErroGetLancamentos,
	dataContextLancamentos: state.arrumaCasaStore.dataContextLancamentos,
	loading: state.commonStore.loading,
});

export default connect(mapStateToProps, {
	remove,
	setLancamentoCurrent,
	getMinhasContasPeriodo,
	setLoading,
	success,
	error,
	setErroAutenticacao
})(ListaLancamento);
