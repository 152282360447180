import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from './Tooltip';

const Title = ({ text, textTooltip, hasTooltip = true }) => {
	return (
		<div className='d-sm-flex align-items-center mb-1'>
			<h1 className='h4 mb-0 text-gray-800'>{text}</h1>
			{hasTooltip && (
				<Tooltip
					text={textTooltip}
					icon='fas fa-1x fa-info-circle'
					trigger='hover'
				/>
			)}
		</div>
	);
};

Title.propTypes = {
	text: PropTypes.string.isRequired,
	textTooltip: PropTypes.string,
	hasTooltip: PropTypes.bool
};

export default Title;
