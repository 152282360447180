import {
	SEND_EMAIL,
	ERROR_EMAIL,
	SET_LOADING,
	SET_DATA_CONTEXT_ANO
} from './types';

import moment from 'moment';
const INITIAL_STATE = {
	email: null,
	loading: true,
	erroEmail: null,
	erroCountEmail: 0,
	dataContextAno: moment([moment().year(), 0]).utc()
};

export const commonReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SEND_EMAIL: {
			return {
				...state,
				email: action.payload,
				erroEmail: null,
				loading: false
			};
		}
		case ERROR_EMAIL: {
			const hasCount = action.payload[1] && ++state.erroCountEmail;
			return {
				...state,
				erroEmail: action.payload[0],
				erroCountEmail: hasCount,
				loading: false
			};
		}
		case SET_LOADING: {
			return {
				...state,
				loading: action.payload
			};
		}
		case SET_DATA_CONTEXT_ANO: {
			return {
				...state,
				dataContextAno: action.payload
			};
		}
		default:
			return state;
	}
};
