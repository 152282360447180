import React from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

const CustomTextArea = (props) => {
	const [field, meta] = useField(props);
	const { className, rows = 5, disabled = false } = props;
	return (
		<OverlayScrollbarsComponent>
			<textarea
				{...field}
				{...props}
				rows={rows}
				disabled={disabled}
				className={`${
					meta.error && `border border-warning`
				} form-control ${className}`}
			/>
			{/* meta.touched &&  */}
			{meta.error ? (
				<div className='ml-1 form-be-custom-error'>{meta.error}</div>
			) : null}
		</OverlayScrollbarsComponent>
	);
};

CustomTextArea.propTypes = {
	name: PropTypes.string.isRequired,
	placeholder: PropTypes.string,
	className: PropTypes.string.isRequired,
	rows: PropTypes.number,
	disabled: PropTypes.bool.isRequired,
};

export default CustomTextArea;
