import {
	CONNECT_CEI,
	SHOW_MODAL_CONNECT_CEI,
	ERROR_CONECT_CEI,
	ERROR_GET_AUTORIZACAO_CEI,
	GET_AUTORIZACAO_CEI,
	CLEAR_STATE_USUARIO,
	GET_MINHAS_ATIVIDADES_PERIODO,
	ERROR_ATIVIDADES,
	SET_DATA_CONTEXT_ATIVIDADES
} from './types';
import {
	success,
	error,
	MSG_TOAST_SUCESSO_SENHA_CEI,
	MSG_TOAST_ERRO_SENHA_CEI,
	MSG_TOAST_ERRO_DE_NOVO_SENHA_CEI
} from '../../../../components/toast/action/ToastAction';
import moment from 'moment';
import { API, Auth } from 'aws-amplify';
const apiName = 'be-usuario';

export const connectCei = (values, autorizacao, erroCountConexao) => {
	const datas = [];
	if (autorizacao) {
		datas.push({ ...autorizacao.dataAutorizacao, data: moment.utc().format() });
	} else {
		datas.push({ data: moment.utc().format() });
	}
	values.dataAutorizacao = datas;
	// console.log(values)
	return (dispatch) =>
		new Promise((resolve, reject) => {
			API.post(apiName, '/cei', {
				response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
				body: values
			})
				.then((res) => {
					dispatch(
						{
							type: CONNECT_CEI,
							payload: res.data
						},
						success(MSG_TOAST_SUCESSO_SENHA_CEI)
					);
					resolve(res.data);
				})
				.catch((err) => {
					const msgErro =
						erroCountConexao >= 1
							? MSG_TOAST_ERRO_DE_NOVO_SENHA_CEI
							: MSG_TOAST_ERRO_SENHA_CEI;
					dispatch(
						{
							type: ERROR_CONECT_CEI,
							payload: [err, true]
						},
						error(msgErro)
					);
					reject(err);
				});
		});
};
export const setShowModalConectemeCei = (show) => {
	return {
		type: SHOW_MODAL_CONNECT_CEI,
		payload: show
	};
};

export const clearStateUsuario = () => {
	return {
		type: CLEAR_STATE_USUARIO
	};
};

export const getAutorizacaoCei = () => {
	return (dispatch) =>
		new Promise((resolve, reject) => {
			API.get(apiName, '/cei', {
				response: true // OPTIONAL (return the entire Axios response object instead of only response.data)
			})
				.then((res) => {
					dispatch({
						type: GET_AUTORIZACAO_CEI,
						// payload: {teste: "aqui", dataAutorizacao:['10/10/2020']}
						payload: res.data.Item
					});
					resolve();
				})
				.catch((err) => {
					dispatch({
						type: ERROR_GET_AUTORIZACAO_CEI,
						payload: [err, true]
					});
					reject(err);
				});
		});
};

export const getMinhasAtividadesPeriodo = (dataContext) => {
	const dataInicio = moment(dataContext).utc().startOf('month').format();
	const dataFim = moment(dataContext).utc().endOf('month').format();
	return (dispatch) =>
		new Promise((resolve, reject) => {
			API.get(apiName, '/minhas-atividades', {
				response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
				queryStringParameters: {
					// OPTIONAL
					dataInicio: dataInicio,
					dataFim: dataFim
				}
			})
				.then((items) => {
					// throw new Error('teste')
					const atividades = items.data.Items;
					dispatch([
						{
							type: GET_MINHAS_ATIVIDADES_PERIODO,
							payload: atividades
						}
					]);
					resolve(atividades);
				})
				.catch((err) => {
					dispatch({
						type: ERROR_ATIVIDADES,
						payload: [err, true, false]
					});
					reject(err);
				});
		});
};

export const setDataContextAtividades = (dataContext) => {
	return {
		type: SET_DATA_CONTEXT_ATIVIDADES,
		payload: dataContext
	};
};