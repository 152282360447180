import React from 'react';
import { Link } from 'react-router-dom';

const LinkAbraSuaConta = () => {
	return (
		<Link to='/abrir-conta' className='btn btn-secondary btn-lg rounded-pill'>
			Abra sua conta e comece já. É grátis!
		</Link>
	);
};

export default LinkAbraSuaConta;
