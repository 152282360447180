import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Title from '../../../../components/common/Title';
import Row from '../../../../components/common/Row';
import Erro500 from '../../../../components/common/Erro500';
import ModalConectemeCei from '../../usuario/ModalConectemeCei';
import ValidarConexaoCEI from '../ValidarConexaoCEI';
import Card from '../../../../components/common/Card';
import CardBody from '../../../../components/common/CardBody';
// import Instituicoes from './manual/Instituicoes';
import {
	carregarUsuario
} from '../../../../minimal/autenticacao/action/AuthAction';
import ListaCarteira from './ListaCarteira';


const MinhaCarteira = (props) => {
	const {
		isErroGetInvestidorCarteira,
		erroInvestidorCarteira,
		erroAutenticacao,
		carregarUsuario
	} = props;

	useEffect(() => {
		carregarUsuario();
		// eslint-disable-next-line
	}, []);

	if (erroAutenticacao !== null) {
		return <Redirect to='/login' />;
	} else if (isErroGetInvestidorCarteira && erroInvestidorCarteira !== null) {
		return <Erro500 erro={erroInvestidorCarteira} />;
	} 

	return (
		<>
			<Title
				text='Minha Carteira'
				textTooltip={`Aqui você acompanha sua carteira de investimentos. Você pode tanto cadastrar seus investimentos como também, acompanhar àqueles que foram importados do CEI.`}
			/>

			<Row>
				<div className='col-12 mb-3'>
					<Card>
						<CardBody classes='card-body-customheight'>
							<ListaCarteira />
						</CardBody>
					</Card>
				</div>
			</Row>
			<Row>
				<div className='col-12 text-right'>
					<ValidarConexaoCEI /> colocar botão atualizar CEI
				</div>
			</Row>
			{/* <Row classes='mt-3'>
				<div className='col-12 text-right'>
					<Button
						icon='fas fa-plus'
						texto='Novo Ativo Financeiro'
						classes='btn btn-primary'
						onClick={() => setShowModalNovoAtivo(true)}
						disabled={false}
					/>
				</div>
			</Row> */}
			<ModalConectemeCei />
			{/* <ModalAddUpdateAtivoCarteira /> */}
		</>
	);
};

const mapStateToPros = (state) => ({
	isErroGetInvestidorCarteira: state.carteiraStore.isErroGetInvestidorCarteira,
	erroInvestidorCarteira: state.carteiraStore.erroInvestidorCarteira,
	erroAutenticacao: state.authStore.erroAutenticacao
});

export default connect(mapStateToPros, {
	carregarUsuario
})(MinhaCarteira);
