import {
	GET_MINHAS_CONTAS_PERIODO,
	GET_MINHAS_CONTAS_PERIODO_CALENDARIO,
	GET_MINHAS_CONTAS_PERIODO_ANUALIZADO,
	SET_DATA_CONTEXT_LANCAMENTO,
	SET_SHOW_MODAL_LANCAMENTO,
	ADD_LANCAMENTO,
	UPDATE_LANCAMENTO,
	ERROR_LANCAMENTO,
	REMOVE_LANCAMENTO,
	SET_LOADING_LANCAMENTO,
	SET_LOADING_LANCAMENTO_ANUALIZADO,
	SET_LANCAMENTO,
	GET_ARRAY_VALORES_TOTAIS,
	SET_ARRAY_VALORES_MESES,
	GET_SERIE_ARRAY_TREEMAP_LANCAMENTOS,
	SET_DATA_CONTEXT_LANCAMENTO_TREEMAP,
	SET_LOADING_LANCAMENTO_TREEMAP,
	CLEAR_STATE_LANCAMENTO,
	GET_LANCAMENTOS_BY_OBJETIVO_SK,
	GET_ARRAY_SERIE_EVOLUCAO_PATRIMONIAL
} from './types';

import { API } from 'aws-amplify';
import moment from 'moment';
import { uniqueArray } from '../../../../../config/helper/utils';
const apiName = 'be-minhas-contas';
const path = '/be-minhas-contas';

//Action disparada pelo GraficoRecebimentosgastos para trazer todos os lancamentos para uma data inicio e data fim.
export const getValoresLancamentosSumarizadosPorPeriodo = () => {
	const dataInicio = moment()
		.utc()
		.subtract(11, 'month')
		.startOf('month')
		.format();
	const dataFim = moment().utc().endOf('month').format();
	return (dispatch) =>
		new Promise((resolve, reject) => {
			API.get(apiName, path + '/summary-period', {
				queryStringParameters: {
					// OPTIONAL
					response: true,
					dataInicio: dataInicio,
					dataFim: dataFim
				}
			})
				.then((items) => {
					// console.log(items)
					dispatch([
						{
							type: GET_ARRAY_VALORES_TOTAIS,
							payload: items
						},
						setArrayValoresMeses(
							items[0],
							items[1],
							items[2],
							items[3],
							SET_ARRAY_VALORES_MESES
						)
					]);
					resolve();
				})
				.catch((err) => {
					dispatch({
						type: ERROR_LANCAMENTO,
						payload: [err, true, false]
					});
					reject(err);
				});
		});
};

//Action disparada pelo ListaLancamentos para trazer todos os lancamentos para uma data inicio e data fim.
export const getMinhasContasPeriodo = (dataContext) => {
	const dataInicio = moment(dataContext).utc().startOf('month').format();
	const dataFim = moment(dataContext).utc().endOf('month').format();
	return (dispatch) =>
		new Promise((resolve, reject) => {
			API.get(apiName, path + '/data', {
				response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
				queryStringParameters: {
					// OPTIONAL
					dataInicio: dataInicio,
					dataFim: dataFim
				}
			})
				.then((items) => {
					// throw new Error('teste')
					const lancamentos = items.data.Items;
					dispatch([
						{
							type: GET_MINHAS_CONTAS_PERIODO,
							payload: lancamentos
						}
					]);
					resolve(lancamentos);
				})
				.catch((err) => {
					dispatch({
						type: ERROR_LANCAMENTO,
						payload: [err, true, false]
					});
					reject(err);
				});
		});
};

//Action disparada pelo Dashboard para trazer todos os lancamentos para uma data inicio e data fim.
export const getMinhasContasPeriodoDashboard = () => {
	const dataInicio = moment()
		.utc()
		.subtract(1, 'month')
		.startOf('month')
		.format();
	const dataFim = moment().utc().endOf('month').format();
	return (dispatch) =>
		new Promise((resolve, reject) => {
			API.get(apiName, path + '/data', {
				response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
				queryStringParameters: {
					// OPTIONAL
					dataInicio: dataInicio,
					dataFim: dataFim
				}
			})
				.then((items) => {
					// throw new Error('teste')
					const lancamentos = items.data.Items;
					dispatch([
						{
							type: GET_MINHAS_CONTAS_PERIODO,
							payload: lancamentos
						}
					]);
					resolve(lancamentos);
				})
				.catch((err) => {
					console.log(err);
					dispatch({
						type: ERROR_LANCAMENTO,
						payload: [err, true, false]
					});
					reject(err);
				});
		});
};

//Action disparada pelo MeuCalendario para trazer todos os lancamentos do mês já com a formatação CSS por tipo de lançamento.
export const getMinhasContasPeriodoCalendario = (dataInicio, dataFim) => {
	return async (dispatch) => {
		try {
			const items = await API.get(apiName, path + '/data-calendar', {
				response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
				queryStringParameters: {
					// OPTIONAL
					dataInicio: dataInicio,
					dataFim: dataFim
				}
			});

			dispatch([
				{
					type: GET_MINHAS_CONTAS_PERIODO_CALENDARIO,
					payload: items.data
				}
			]);
		} catch (err) {
			dispatch({
				type: ERROR_LANCAMENTO,
				payload: [err, true, false]
			});
		}
	};
};

//Action disparada pelo TableTotalAnualizado para trazer todos os lancamentos do ano com o somatório por tipo de lançamento.
export const getMinhasContasPeriodoAnualizado = (dataContext) => {
	const dataInicio = moment(dataContext).utc().startOf('month').format();
	const dataFim = moment([dataContext.year(), 11])
		.utc()
		.endOf('month')
		.format();
	return (dispatch) =>
		new Promise((resolve, reject) => {
			API.get(apiName, path + '/anualizado', {
				response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
				queryStringParameters: {
					// OPTIONAL
					dataInicio: dataInicio,
					dataFim: dataFim
				}
			})

				.then((items) => {
					dispatch([
						{
							type: GET_MINHAS_CONTAS_PERIODO_ANUALIZADO,
							payload: items.data
						}
					]);
					resolve();
				})
				.catch((err) => {
					dispatch({
						type: ERROR_LANCAMENTO,
						payload: [err, true, false]
					});
					reject(err);
				});
		});
};

//Action disparada pelo onSubmit do Formik no ModalAddUpdateLancamento para incluir ou alterar um lançamento.
export const submit = (values, valorAnteriorLancamento) => {
	return (dispatch) =>
		new Promise((resolve, reject) => {
			// console.log("values original: ", JSON.stringify(values));
			values.dataOperacao = moment(values.dataOperacao).utc();

			if (typeof values.sk === 'undefined') {
				values.objetivo_sk = values.objetivo ? values.objetivo.value : null;

				API.post(apiName, path, {
					response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
					body: values
				})
					.then((res) => {
						dispatch([
							{
								type: ADD_LANCAMENTO,
								payload: res.data
							}
						]);
						resolve();
					})
					.catch((err) => {
						dispatch(errorDispatch(err, false, true, values, true));
						reject(err);
					});
			} else {
				values.valorAnteriorLancamento = valorAnteriorLancamento;
				API.put(apiName, path, {
					response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
					body: values
				})
					.then(() => {
						dispatch([
							{
								type: UPDATE_LANCAMENTO,
								payload: values
							}
						]);
						resolve();
					})
					.catch((err) => {
						console.log(err);
						dispatch(errorDispatch(err, false, true, values, true));
						reject(err);
					});
			}
		});
};

//Action disparada pelo onClick de ícone de remover do ListaLancamentos.
export const remove = (values) => {
	// console.log("values original: ", JSON.stringify(values));
	// values.objetivo_sk = values.tipoLancamento.value === "investimento" ? values.objetivo.value : null;
	// Remove the objetivo property
	// delete values.objetivo;
	values.delecao = true;
	return (dispatch) =>
		new Promise((resolve, reject) => {
			API.del(apiName, path, {
				response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
				body: values
			})
				.then(() => {
					dispatch([
						{
							type: REMOVE_LANCAMENTO,
							payload: values.sk
						}
					]);
					resolve();
				})
				.catch((err) => {
					dispatch(errorDispatch(err, false, true, values));
					reject(err);
				});
		});
};

const errorDispatch = (err, erroGet, erroCount, values, showModal = false) => {
	return [
		{
			type: ERROR_LANCAMENTO,
			payload: [err, erroGet, erroCount]
		},
		setLancamentoCurrent(values, showModal)
	];
};

export const setLancamentoCurrent = (lancamento, showModal = false) => {
	return (dispatch) =>
		dispatch([
			{
				type: SET_LANCAMENTO,
				payload: lancamento
			},
			setShowModalLancamentos(showModal)
		]);
};

export const clearStateLancamento = () => {
	return {
		type: CLEAR_STATE_LANCAMENTO
	};
};

export const setDataContextLancamentos = (dataContext) => {
	return {
		type: SET_DATA_CONTEXT_LANCAMENTO,
		payload: dataContext
	};
};

export const setDataContextLancamentosTreemap = (dataContext) => {
	return {
		type: SET_DATA_CONTEXT_LANCAMENTO_TREEMAP,
		payload: dataContext
	};
};

export const setShowModalLancamentos = (showModal) => {
	return {
		type: SET_SHOW_MODAL_LANCAMENTO,
		payload: showModal
	};
};

export const setLoadingLancamentos = (value) => {
	return {
		type: SET_LOADING_LANCAMENTO,
		payload: value
	};
};

export const setLoadingLancamentosTreemap = (value) => {
	return {
		type: SET_LOADING_LANCAMENTO_TREEMAP,
		payload: value
	};
};

export const setLoadingLancamentosAnualizado = (value) => {
	return {
		type: SET_LOADING_LANCAMENTO_ANUALIZADO,
		payload: value
	};
};

//Ta vindo formatado da API
// const mapMesesLacanmento = (array, tipoLancamento) => {
// 	const arrayValoresMeses = [];
// 	// eslint-disable-next-line
// 	array.filter(
// 		(item) =>
// 			item.tipoLancamento.value === tipoLancamento &&
// 			arrayValoresMeses.push({
// 				valor: item.valor,
// 				data: moment(item.dataOperacao).month()
// 			})
// 	);

// 	return arrayValoresMeses;
// };

const somaValoresLancamentobyData = (array) => {
	const resultValoresMesesAgregado = [];

	// eslint-disable-next-line
	array.filter((item) => {
		let hasElement = false;
		let j;
		for (j = 0; j < resultValoresMesesAgregado.length; j++) {
			if (resultValoresMesesAgregado[j].data === item.data) {
				hasElement = true;
				break;
			}
		}
		if (hasElement) {
			resultValoresMesesAgregado[j].valor += item.valor;
		} else {
			resultValoresMesesAgregado.push(item);
		}
	});

	return resultValoresMesesAgregado;
};

const validaAdicionaMesSemLancamento = (arrayBase, arrayDestino) => {
	// eslint-disable-next-line
	arrayBase.filter((item) => {
		let hasElement = false;
		let j;
		for (j = 0; j < arrayDestino.length; j++) {
			if (arrayDestino[j].data === item) {
				hasElement = true;
				break;
			}
		}
		if (!hasElement) {
			// eslint-disable-next-line
			arrayDestino.push({
				valor: 0,
				data: item
			});
		}
	});
};

const ordenaArrayPorMes = (array) => {
	return array.sort((a, b) => {
		// return a.mes < b.mes ? -1 : a.mes > b.mes ? 1 : 0;
		// return b.data < a.data ? -1 : b.data > a.data ? 1 : 0;
		var dateA = new Date(a.data),
			dateB = new Date(b.data);
		return dateA - dateB;
	});
};

const setArrayValoresMeses = (
	arrayValoresMesesRecebimento,
	arrayValoresMesesGastos,
	arrayValoresMesesDesejos,
	arrayValoresMesesInvestimentos
) => {
	// const arrayValoresMesesRecebimento = mapMesesLacanmento(
	// 	arrayValores,
	// 	'recebimento'
	// );
	// const arrayValoresMesesGastos = mapMesesLacanmento(arrayValores, 'gasto');

	const resultValoresMesesAgregadoRec = somaValoresLancamentobyData(
		arrayValoresMesesRecebimento
	);
	const resultValoresMesesAgregadoGastos = somaValoresLancamentobyData(
		arrayValoresMesesGastos
	);
	const resultValoresMesesAgregadoDesejos = somaValoresLancamentobyData(
		arrayValoresMesesDesejos
	);

	const resultValoresMesesAgregadoInvestimentos = somaValoresLancamentobyData(
		arrayValoresMesesInvestimentos
	);

	const soMesesGastos = resultValoresMesesAgregadoGastos.map(
		(item) => item.data
	);
	// console.log(soMesesGastos);
	const soMesesRecebimentos = resultValoresMesesAgregadoRec.map(
		(item) => item.data
	);
	// console.log(soMesesRecebimentos);
	const soMesesInvestimentos = resultValoresMesesAgregadoInvestimentos.map(
		(item) => item.data
	);
	// console.log(soMesesInvestimentos);
	const soMesesDesejos = resultValoresMesesAgregadoDesejos.map(
		(item) => item.data
	);
	// console.log(soMesesDesejos);

	let meses = uniqueArray(
		soMesesGastos.concat(
			soMesesRecebimentos,
			soMesesInvestimentos,
			soMesesDesejos
		)
	);

	//Recebimentos
	validaAdicionaMesSemLancamento(meses, resultValoresMesesAgregadoRec);

	//Gastos essenciais
	validaAdicionaMesSemLancamento(meses, resultValoresMesesAgregadoGastos);

	//Gastos não-essenciais
	validaAdicionaMesSemLancamento(meses, resultValoresMesesAgregadoDesejos);

	//Investimentos
	validaAdicionaMesSemLancamento(
		meses,
		resultValoresMesesAgregadoInvestimentos
	);

	const recebimentos = ordenaArrayPorMes(resultValoresMesesAgregadoRec);
	const gastos = ordenaArrayPorMes(resultValoresMesesAgregadoGastos);
	const desejos = ordenaArrayPorMes(resultValoresMesesAgregadoDesejos);
	const investimentos = ordenaArrayPorMes(
		resultValoresMesesAgregadoInvestimentos
	);

	return {
		type: SET_ARRAY_VALORES_MESES,
		payload: [recebimentos, gastos, desejos, investimentos]
	};
};

//Action disparada pelo ListaLancamentos para trazer todos os lancamentos para uma data inicio e data fim.
export const getMinhasContasTreemap = (dataContext) => {
	const dataInicio = moment(dataContext).utc().startOf('month').format();
	const dataFim = moment(dataContext).utc().endOf('month').format();
	return (dispatch) =>
		new Promise((resolve, reject) => {
			API.get(apiName, path + '/data', {
				response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
				queryStringParameters: {
					// OPTIONAL
					dataInicio: dataInicio,
					dataFim: dataFim
				}
			})
				.then((items) => {
					// console.log(items);
					// console.log(items.data.Items);
					const result = items.data.Items.map((item) => {
						return {
							x: item.nome,
							y: parseFloat(item.valor)
						};
					});

					dispatch([
						{
							type: GET_SERIE_ARRAY_TREEMAP_LANCAMENTOS,
							payload: result
						}
					]);
					resolve(result);
				})
				.catch((err) => {
					dispatch({
						type: ERROR_LANCAMENTO,
						payload: [err, true, false]
					});
					reject(err);
				});
		});
};

export const getLancamentosByObjetivoSK= (objetivo_sk) => {
	return (dispatch) =>
		new Promise((resolve, reject) => {
			API.get(apiName, path + '/data-objetivo', {
				response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
				queryStringParameters: {
					objetivo_sk: objetivo_sk
				}
			}).then((items) => {
				// console.log("items: ", JSON.stringify(items.data));
				dispatch([
					{
						type: GET_LANCAMENTOS_BY_OBJETIVO_SK,
						payload: items.data
					}
				]);
				resolve(items.data);
			})
				.catch((err) => {
					dispatch({
						type: ERROR_LANCAMENTO,
						payload: [err, true, false]
					});
					reject(err);
				});
		});
};

//Action disparada pelo GraficoEvolucaoPatrimonial para trazer todos os objetivos que há na base.
export const getArraySerieEvolucaoPatrimonial = () => {
	return (dispatch) =>
		new Promise((resolve, reject) => {
			API.get(apiName, path + '/all-investimentos', {
				response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
				queryStringParameters: {
					dataInicio: moment()
						.utc()
						.subtract(11, 'month')
						.startOf('month')
						.format(),
					dataFim: moment().utc().endOf('month').format()
				}
			})
				.then((items) => {
					// console.log('getMeusObjetivoDetalhes');
					// console.log(items);
					// console.log(items.data.Items);					
					dispatch([
						{
							type: GET_ARRAY_SERIE_EVOLUCAO_PATRIMONIAL, //TODO
							payload: items.data.Items
						}
					]);
					resolve();
				})
				.catch((err) => {
					dispatch({
						type: ERROR_LANCAMENTO,
						payload: [err, true, false]
					});
					reject(err);
				});
		});
};

// const percorreNomesLancamentosSomaValores = (
// 	nomeLancamentos,
// 	lancamentos,
// 	dataInicio,
// 	dataFim
// ) => {
// 	const res = [];
// 	const periodo = moment(dataFim).diff(dataInicio, 'months');
// 	nomeLancamentos.forEach((lancamento) => {
// 		const valores = [];
// 		for (let i = 0; i < periodo; i++) {
// 			const dataAtualInicio = moment()
// 				.utc()
// 				.subtract(i, 'month')
// 				.startOf('month')
// 				.format();
// 			const dataAtualFim = moment()
// 				.utc()
// 				.subtract(i, 'month')
// 				.endOf('month')
// 				.format();
// 			const valor = lancamentos
// 				.filter(
// 					(item) =>
// 						item.nome === lancamento.nome &&
// 						moment(item.dataOperacao).utc().isBetween(
// 							dataAtualInicio,
// 							dataAtualFim,
// 							null, // can be year, month .... the granularity of your comaprison
// 							'[]'
// 						)
// 				)
// 				.reduce((sum, record) => sum + record.valor, 0);
// 			valores.push(valor);
// 		}
// 		res.push({
// 			name: lancamento.nome,
// 			data: valores
// 		});
// 	});
// 	return res;
// };

// export const getSerieValoresTotaisByLancamento = (dataInicio, dataFim) => {
// 	return (dispatch) =>
// 		new Promise((resolve, reject) => {
// 			API.get(apiName, path + '/data', {
// 				response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
// 				queryStringParameters: {
// 					// OPTIONAL
// 					dataInicio: dataInicio,
// 					dataFim: dataFim
// 				}
// 			})
// 				.then((items) => {
// 					// throw new Error('teste')
// 					//Mapeando somente o nome dos lançamentos e retirando a duplicidade
// 					const nomeLancamentos = items.data.Items.map((item) => ({
// 						nome: item.nome
// 					}))
// 						.map((e) => JSON.stringify(e))
// 						// eslint-disable-next-line
// 						.reduce((acc, cur) => (acc.includes(cur) || acc.push(cur), acc), [])
// 						.map((e) => JSON.parse(e));

// 					//Descobrir em quais datas há os lancamentos usando a data da pesquisa como base
// 					const serie = percorreNomesLancamentosSomaValores(
// 						nomeLancamentos,
// 						items.data.Items,
// 						dataInicio,
// 						dataFim
// 					);

// 					const arrayCategories = getNomeMesesDoPeriodo(dataInicio, dataFim);
// 					dispatch({
// 						type: GET_SERIE_ARRAY_VALORES_TOTAIS_LANCAMENTOS,
// 						payload: [arrayCategories, serie]
// 					});
// 					resolve();
// 				})
// 				.catch((err) => {
// 					console.log(err);
// 					dispatch({
// 						type: ERROR_LANCAMENTO,
// 						payload: [err, true, false]
// 					});
// 					reject();
// 				});
// 			});
// };

// const removeObjetivoProperty = (jsonObject) => {
// 	delete jsonObject.objetivo;
// 	return jsonObject;
// };

// // Example usage:
// const jsonObject = {
// 	"nome": "teste SNS inv 2",
// 	"descricao": "",
// 	"dataOperacao": "2024-10-10T19:20:05.000Z",
// 	"valor": 40,
// 	"investimento": false,
// 	"tipoLancamento": {
// 		"label": "É um investimento",
// 		"value": "investimento"
// 	},
// 	"objetivo": {
// 		"label": "teste SNS",
// 		"value": "MEUS-OBJETIVOS#9045f57f-0835-42d2-8453-10e6c427f989",
// 		"valorAtual": 688
// 	},
// 	"recorrente": false
// };

// const updatedJsonObject = removeObjetivoProperty(jsonObject);
// // console.log(updatedJsonObject);
