import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Title from '../../../components/common/Title';
import Row from '../../../components/common/Row';
import Card from '../../../components/common/Card';
import CardBody from '../../../components/common/CardBody';
import Erro500 from '../../../components/common/Erro500';
import Spinner from '../../../components/preLoader/Spinner';
import GraficoRecebimentosGastos from './GraficoRecebimentosGastos';
import GraficoPizzaTipoLancamento from './GraficoPizzaTipoLancamento';
import GraficoTreemapLancamentos from './GraficoTreemapLancamentos';
import {
	getValoresLancamentosSumarizadosPorPeriodo
} from './action/ArrumandoCasaAction';
// import GraficoStackedBarLancamentos from './GraficoStackedBarLancamentos';
import { setLoading } from '../../../components/common/action/CommonAction';
import { carregarUsuario } from '../../../minimal/autenticacao/action/AuthAction';

const MeusGraficos = (props) => {
	const {
		isErroGetLancamentos,
		getValoresLancamentosSumarizadosPorPeriodo,
		loading,
		setLoading,
		erroLancamentos,
		carregarUsuario,
		erroAutenticacao
	} = props;

	useEffect(() => {
		// setLoading(true);
		let promises = [
			carregarUsuario(),
			getValoresLancamentosSumarizadosPorPeriodo()
		];
		Promise.all(promises)
			.then(() => {
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
			});
		// eslint-disable-next-line
	}, []);

	if (erroAutenticacao !== null) {
		return <Redirect to='/login' />;
	} else if (isErroGetLancamentos && erroLancamentos !== null) {
		return <Erro500 erro={erroLancamentos} />;
	} else if (loading) {
		return <Spinner id='spinner-centro-lg' />;
	}

	return (
		!loading && (
			<>
				<Title
					text='Meus gráficos'
					textTooltip={`Esta funcionalidade te permite fazer diferentes análises do seu dinheiro!`}
				/>
				<Row classes='mb-3'>
					<div className='col-12'>
						<Card>
							<CardBody>
								<GraficoPizzaTipoLancamento />
							</CardBody>
						</Card>
					</div>
				</Row>
				<Row classes='mb-3'>
					<div className='col-12'>
						<Card>
							<CardBody>
								<GraficoTreemapLancamentos />
							</CardBody>
						</Card>
					</div>
				</Row>
				<Row>
					<div className='col-12'>
						<Card>
							<CardBody>
								<GraficoRecebimentosGastos height={400} />
							</CardBody>
						</Card>
					</div>
				</Row>
			</>
		)
	);
};

const mapStateToProps = (state) => ({
	isErroGetLancamentos: state.arrumaCasaStore.isErroGetLancamentos,
	erroLancamentos: state.arrumaCasaStore.erroLancamentos,
	loading: state.commonStore.loading,
	erroAutenticacao: state.authStore.erroAutenticacao
});

export default connect(mapStateToProps, {
	getValoresLancamentosSumarizadosPorPeriodo,
	setLoading,
	carregarUsuario
})(MeusGraficos);
