import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import multi from 'redux-multi';
import promise from 'redux-promise';
import { authReducer } from './layouts/minimal/autenticacao/action/AuthReducer';
import { arrumaCasaReducer } from './layouts/private/dashboard/arrumandoCasa/action/ArrumaCasaReducer';
import { sideBarReducer } from './layouts/private/dashboard/sidebar/action/SideBarReducer';
import { toastReducer } from './layouts/components/toast/action/ToastReducer';
import { fazendoCaixaReducer } from './layouts/private/dashboard/fazendoCaixa/action/FazendoCaixaReducer';
import { commonReducer } from './layouts/components/common/action/CommonReducer';
import { tabsReducer } from './layouts/components/tabs/action/tabsReducer';
import { usuarioReducer } from './layouts/private/dashboard/usuario/action/UsuarioReducer';
import { minhasOperacoesReducer } from './layouts/private/dashboard/investidorSucesso/minhasOperacoes/action/MinhasOperacoesReducer';
import { minhaCarteiraReducer } from './layouts/private/dashboard/investidorSucesso/minhaCarteira/action/MinhaCarteiraReducer';
import { formReducer } from './layouts/components/form/action/FormReduce';
// import { preLoaderReducer } from './layouts/components/preLoader/action/PreLoaderReducer';

const mainReducer = combineReducers({
	authStore: authReducer,
	sideBarStore: sideBarReducer,
	arrumaCasaStore: arrumaCasaReducer,
	toastStore: toastReducer,
	fazendoCaixaStore: fazendoCaixaReducer,
	commonStore: commonReducer,
	tabStore: tabsReducer,
	usuarioStore: usuarioReducer,
	operacoesStore: minhasOperacoesReducer,
	carteiraStore: minhaCarteiraReducer,
	formStore: formReducer
});

const devTools =
	window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();

const store = applyMiddleware(thunk, multi, promise)(createStore)(
	mainReducer,
	devTools
);

export default store;
