import React, { useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Link, withRouter } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import swal from "sweetalert";

import LogoBeganinha from "../../../components/common/SVGIcon";
import Button from "../../../components/form/Button";
import CustomTextInput from "../../../components/form/CustomTextInput";
import CustomMaskedTextInput from "../../../components/form/CustomMaskedTextInput";
import { isCpfValido } from "../../../../config/helper/utils";
import { signIn, reenviaCodigo } from "../action/AuthAction";
import Row from "../../../components/common/Row";
import { clearStateLancamento } from "../../../private/dashboard/arrumandoCasa/action/ArrumandoCasaAction";
import { clearStateObjetivo } from "../../../private/dashboard/fazendoCaixa/action/FazendoCaixaAction";
import { clearStateCarteira } from "../../../private/dashboard/investidorSucesso/minhaCarteira/action/MinhaCarteiraAction";
import { clearStateUsuario } from "../../../private/dashboard/usuario/action/UsuarioAction";
import { clearStateForm } from "../../../components/form/action/FormAction";

const Login = (props) => {
  const {
    signIn,
    reenviaCodigo,
    history,
    clearStateLancamento,
    clearStateObjetivo,
    clearStateCarteira,
    clearStateUsuario,
	clearStateForm,
    passwordVisibility,
  } = props;

  const reenviarCodigo = async (cpf) => {
    reenviaCodigo(cpf)
      .then(() => {
        swal({
          title: "Perfeito",
          text: "Reenviamos o código! Por favor, verifique teu email.",
          icon: "success",
          buttons: {
            ok: {
              text: "Ok",
              className: "btn-primary",
            },
          },
        }).then(() => {
          props.history.push("/confirmar-conta");
        });
      })
      .catch((err) => {
        swal({
          title: "Ops",
          text: "Não conseguimos reenviar o código para teu e-mail. Tente novamente mais tarde!",
          icon: "warning",
          buttons: {
            ok: {
              text: "Ok",
              className: "btn-primary",
            },
          },
        });
      });
  };

  const validationSchema = Yup.object({
    cpf: Yup.string()
      .test("CPF-valido", "CPF Inválido", (value) => isCpfValido(value))
      .required("O CPF é obrigatório"),
    senha: Yup.string()
      .min(8, "A senha deve ter no mínimo oito caracteres com letras e números")
      .required("A senha é obrigatória"),
  });

  const [INITIAL_VALUES] = useState({
    cpf: "",
    senha: "",
  });

  const onSubmitLogin = (values, { setSubmitting, setFieldError }) => {
    signIn(values)
      .then(() => {
        history.push("/dashboard");
        clearStateLancamento();
        clearStateObjetivo();
        clearStateCarteira();
        clearStateUsuario();
		clearStateForm();
      })
      .catch((err) => {
        if (err.code === "UserNotConfirmedException") {
          swal({
            title: "Estamos quase lá!",
            text: "A tua conta ainda não está verificada. Por favor, use o código que enviamos em teu e-mail para validar a conta!",
            icon: "warning",

            buttons: {
              reenviar: {
                text: "Reenviar código",
                value: "reenviar",
                className: "btn-secondary",
              },

              confirmar: {
                text: "Confirmar código",
                value: "confirmar",
                className: "btn-primary",
              },
            },
          }).then((value) => {
            switch (value) {
              case "confirmar":
                history.push("/confirmar-conta");
                break;
              case "reenviar":
                reenviarCodigo(values.cpf);
                break;
              default:
                break;
            }
          });
        } else if (err.code === "NotAuthorizedException") {
          setFieldError("cpf", "CPF ou senha inválidos");
          setFieldError("senha", "CPF ou senha inválidos");
        } else {
          swal({
            title: "Ops",
            text: "Não conseguimos te conectar a sua conta. Tente novamente mais tarde.",
            icon: "warning",
            buttons: {
              ok: {
                text: "Ok",
                className: "btn-primary",
              },
            },
          });
        }
        setSubmitting(false);
      });
  };

  return (
    <Row>
      <div className="col-lg-6 d-none d-lg-block bg-login-image"></div>
      <div className="col-lg-6">
        <div className="p-5">
          <div className="d-flex justify-content-center pb-3">
            <Link to="/home">
              <LogoBeganinha name="be_preto" classNameCustom="logo-be" />
            </Link>
          </div>
          <div className="text-center">
            <h1 className="h4 text-gray-900 mb-4">Bem-vindo de volta</h1>
          </div>
          <Formik
            initialValues={INITIAL_VALUES}
            validationSchema={validationSchema}
            onSubmit={onSubmitLogin}
          >
            {({ handleSubmit, isSubmitting }) => (
              <Form className="form-be-custom" onSubmit={handleSubmit}>
                <div className="form-group">
                  <CustomMaskedTextInput
                    name="cpf"
                    type="tel"
                    placeholder="CPF"
                    mask={[
                      /[0-9]/,
                      /\d/,
                      /\d/,
                      ".",
                      /\d/,
                      /\d/,
                      /\d/,
                      ".",
                      /\d/,
                      /\d/,
                      /\d/,
                      "-",
                      /\d/,
                      /\d/,
                    ]}
                    className="custom-form-control"
                  />
                </div>
                <div className="form-group">
                  <CustomTextInput
                    name="senha"
                    type={passwordVisibility ? "text" : "password"}
                    placeholder="Senha com letras e números"
                    className="custom-form-control"
                    isPassword={true}
                  />
                </div>
                {/* <div className='form-group'>
									<div className='custom-control custom-checkbox small'>
										<input
											type='checkbox'
											className='custom-control-input'
											id='customCheck'
										/>
										<label
											className='custom-control-label'
											htmlFor='customCheck'
										>
											Lembre de mim
										</label>
									</div>
								</div>
								<hr />
						<a href='index.html' className='btn btn-google btn-user btn-block'>
							<i className='fab fa-google fa-fw'></i> Login with Google
						</a>
						<a
							href='index.html'
							className='btn btn-facebook btn-user btn-block'
						>
							<i className='fab fa-facebook-f fa-fw'></i> Login with Facebook
						</a> */}
                <Button
                  classes="btn btn-secondary btn-block rounded-pill"
                  texto="Entre na sua conta"
                  disabled={isSubmitting}
                />
              </Form>
            )}
          </Formik>
          <hr />
          <div>
            <Link to="/esqueci-senha" className="text-gray-700">
              Esqueci minha senha?
            </Link>
          </div>
          <div>
            <Link to="/abrir-conta" className="text-gray-700">
              Ainda não tem uma conta, então clique aqui!
            </Link>
          </div>
          <div className="pt-md-5"></div>
        </div>
      </div>
    </Row>
  );
};

const mapStateToProps = (state) => ({
  passwordVisibility: state.formStore.passwordVisibility,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    signIn,
    reenviaCodigo,
    clearStateLancamento,
    clearStateObjetivo,
    clearStateCarteira,
    clearStateUsuario,
	clearStateForm
  })
)(Login);
// export default Login;
