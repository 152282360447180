import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { setSideBarToggle } from '../action/SideBarAction';

const FazendoCaixaItem = (props) => {
	const { setSideBarToggle } = props;
	const [show, setShow] = useState('');

	return (
		<div className='tour-passo-fazendo'>
			{/* <!-- Nav Item - Utilities Collapse Menu --> */}
			<li className='nav-item'>
				<a
					className='nav-link collapsed'
					href='#!'
					data-toggle='collapse'
					data-target='#collapseObjetivos'
					aria-expanded='true'
					aria-controls='collapseObjetivos'
					onClick={() => setShow('')}
				>
					<i className='fas fa-fw fa-seedling'></i>
					<span>Fazendo um bom caixa</span>
				</a>
				<div
					id='collapseObjetivos'
					className={`collapse ${show}`}
					aria-labelledby='headingUtilities'
					data-parent='#accordionSidebar'
				>
					<div className='bg-white py-2 collapse-inner rounded'>
						{/* <h6 className='collapse-header'>Faça um bom caixa</h6> */}
						<Link
							className='collapse-item'
							to='/dashboard/caixa/meus-objetivos'
							onClick={() => {
								setShow('hide');
								setSideBarToggle('toggled');
							}}
						>
							Meus Objetivos
						</Link>
						{/* <Link
							className='collapse-item'
							to='/dashboard/caixa/minha-evolucao'
							onClick={() => {
								setShow('hide');
								setSideBarToggle('toggled');
							}}
						>
							Minha evolução
						</Link> */}
						<Link
							className='collapse-item'
							to='/dashboard/caixa/meus-graficos'
							onClick={() => {
								setShow('hide');
								setSideBarToggle('toggled');
							}}
						>
							Meus gráficos
						</Link>
					</div>
				</div>
			</li>
		</div>
	);
};

export default connect(null, { setSideBarToggle })(FazendoCaixaItem);
