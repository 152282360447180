import {
	SET_SHOW_MODAL_NOVO_ATIVO,
	SET_ATIVO_OPERACAO,
	ADD_OPERACOES,
	UPDATE_OPERACOES,
	ERROR_GENERIC,
	GET_MINHAS_INSTITUICOES,
	GET_MINHAS_OPERACOES_BY_SK,
	SET_INSTITUICAO,
	SET_LOADING_GENERIC
} from './types';

import { API } from 'aws-amplify';
import moment from 'moment';

const apiName = 'be-investidor-sucesso';
const path = '/minhas-operacoes';

export const setShowModalNovoAtivo = (showModal) => {
	return {
		type: SET_SHOW_MODAL_NOVO_ATIVO,
		payload: showModal
	};
};

export const setAtivoCurrent = (ativo, showModal = false) => {
	return (dispatch) =>
		dispatch([
			{
				type: SET_ATIVO_OPERACAO,
				payload: ativo
			},
			setShowModalNovoAtivo(showModal)
		]);
};

const errorDispatch = (err, erroGet, erroCount) => {
	return [
		{
			type: ERROR_GENERIC,
			payload: [err, erroGet, erroCount]
		}
		// setLancamentoCurrent(values, showModal)
	];
};

export const getMinhasOperacoesBySk = (dataContext, sk) => {
	const dataInicio = moment(dataContext).utc().startOf('month').format();
	const dataFim = moment([dataContext.year(), 11])
		.utc()
		.endOf('month')
		.format();

	return (dispatch) =>
		new Promise((resolve, reject) => {
			API.get(apiName, path + '/data', {
				response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
				queryStringParameters: {
					// OPTIONAL
					sk: sk,
					dataInicio: dataInicio,
					dataFim: dataFim
				}
			})
				.then((items) => {
					dispatch({
							type: GET_MINHAS_OPERACOES_BY_SK,
							payload: items.data.Items
						});
					resolve();
				})
				.catch((err) => {
					dispatch(errorDispatch(err, true, false));
					reject(err);
				});
		});
};


export const getMinhasInstituicoes = () => {
	return (dispatch) =>
		new Promise((resolve, reject) => {
			API.get(apiName, path + '/instituicoes', {
				response: true // OPTIONAL (return the entire Axios response object instead of only response.data)
			})
				.then((items) => {
					dispatch({
							type: GET_MINHAS_INSTITUICOES,
							payload: items.data.Items
						});
					resolve();
				})
				.catch((err) => {
					dispatch(errorDispatch(err, true, false));
					reject(err);
				});
		});
};

export const submit = (values) => {
	return (dispatch) =>
		new Promise((resolve, reject) => {
			if (typeof values.sk === 'undefined') {
				API.post(apiName, path, {
					response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
					body: values
				})
					.then((res) => {
						dispatch([
							{
								type: ADD_OPERACOES, //Altera um valor booleano para que o useEffect da ListaOperacoes rode novamente
								payload: res.data
							}
						]);
						resolve();
					})
					.catch((err) => {
						dispatch(errorDispatch(err, false, true));
						reject(err);
					});
			} else {
				values.operacoes[0].valorTotal = (values.operacoes[0].valorUnitario * values.operacoes[0].quantidade) + values.operacoes[0].taxas
				console.log(JSON.stringify(values))
				API.put(apiName, path, {
					response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
					body: values
				})
					.then(() => {
						dispatch([
							{
								type: UPDATE_OPERACOES,
								payload: values
							}
						]);
						resolve();
					})
					.catch((err) => {
						dispatch(errorDispatch(err, false, true));
						reject(err);
					});
			}
		});
};

//Action disparada pelo onClick de ícone de remover do ListaDetalhesOperacoes.
export const remove = (values) => {
	return (dispatch) =>
		new Promise((resolve, reject) => {
			console.log("remove");
			console.log(JSON.stringify(values));
			resolve();
			// API.del(apiName, path, {
			// 	response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
			// 	body: values
			// })
			// 	.then(() => {
			// 		dispatch([
			// 			{
			// 				type: REMOVE_OPERACOES,
			// 				payload: values.sk
			// 			}
			// 		]);
			// 		resolve();
			// 	})
			// 	.catch((err) => {
			// 		dispatch(errorDispatch(err, false, true));
			// 		reject(err);
			// 	});
		});
};

export const setInstituicaoCurrent = (value) => {
	return (dispatch) =>
		dispatch({
			type: SET_INSTITUICAO,
			payload: value
		});
};

export const setLoadingGeneric = (value) => {
	return (dispatch) =>
		dispatch({
			type: SET_LOADING_GENERIC,
			payload: value
		});
};