import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { setShowModalLancamentos } from './action/ArrumandoCasaAction';

const NaoHaLancamento = ({ height, setShowModalLancamentos }) => {
	return (
		<div
			className='col-12 d-flex align-items-center justify-content-center'
			style={{ height: height }}
		>
			<div>
				<p className='h5 font-weight-bold'>
					{`Você ainda não cadastrou nenhum lançamento neste mês. Quer resolver
					isso? É fácil, basta `}
					<Link to='/dashboard/casa/meus-lancamentos' onClick={() => setShowModalLancamentos(true)}>clicar aqui</Link>.
				</p>
			</div>
		</div>
	);
};

NaoHaLancamento.propTypes = {
	height: PropTypes.string.isRequired
};

export default connect(null, { setShowModalLancamentos })(NaoHaLancamento);
