export const listaTipoLancamentos = [
    {
        label: 'Selecione o tipo do lançamento',
        value: ''
    },
    {
        label: 'É um recebimento',
        value: 'recebimento'
    },
    {
        label: 'É um investimento',
        value: 'investimento'
    },
    {
        label:
            'É um gasto com despesas essenciais (Alimentação, moradia, saúde, estudos, transporte ou similares)',
        value: 'gasto'
    },
    {
        label:
            'É um gasto com despesas não essenciais (Jantares, lazer, hobbies, assinaturas -TV a cabo, Netflix, Spotify- ou similares)',
        value: 'desejo'
    }
];