import React from 'react';
import PropTypes from 'prop-types';

const Spinner = (props) => {
	const { id } = props;

	return (
		<div className='div-spinner-height align-center text-center' style={{ height: 400 }}>
			<div id={id} className='spinner-border text-primary' role='status'>
				<span className='sr-only'>Loading...</span>
			</div>
		</div>
	);
};

Spinner.propTypes = {
	id: PropTypes.string.isRequired
};

export default Spinner;
