import React, { useState } from 'react';
import { compose } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import swal from 'sweetalert';

import LogoBeganinha from '../../../components/common/SVGIcon';
import Button from '../../../components/form/Button';
import CustomTextInput from '../../../components/form/CustomTextInput';
import CustomMaskedTextInput from '../../../components/form/CustomMaskedTextInput';
import { isCpfValido } from '../../../../config/helper/utils';
import Row from '../../../components/common/Row';
import { confirmaCodigo, reenviaCodigo } from '../action/AuthAction';
const ConfirmarConta = (props) => {
	const { confirmaCodigo, reenviaCodigo, history } = props;

	const [INITIAL_VALUES] = useState({
		cpf: '',
		codigo: ''
	});

	const validationSchema = Yup.object({
		cpf: Yup.string()
			.test('CPF-valido', 'CPF Inválido', (value) => isCpfValido(value))
			.required('O CPF é obrigatório'),
		codigo: Yup.string()
			.min(6, 'Digite o código com 6 dígitos')
			.required('O código é obrigatório')
	});

	const reenviarCodigo = (cpf) => {
		reenviaCodigo(cpf)
			.then(() => {
				swal({
					title: 'Perfeito',
					text:
						'Reenviamos o código! Por favor, verifique teu email. A gente te espera aqui.',
					icon: 'success',
					buttons: {
						ok: {
							text: 'Ok',
							className: 'btn-primary'
						}
					}
				}).then(() => {
					history.push('/confirmar-conta');
				});
			})
			.catch((err) => {
				swal({
					title: 'Ops',
					text:
						'Não conseguimos reenviar o código para teu e-mail. Tente novamente mais tarde!',
					icon: 'warning',
					buttons: {
						ok: {
							text: 'Ok',
							className: 'btn-primary'
						}
					}
				});
			});
	};

	const onSubmitConfirmar = (values, { resetForm, setSubmitting }) => {
		confirmaCodigo(values.cpf, values.codigo)
			.then(() => {
				history.push('/login');
			})
			.catch((err) => {
				swal({
					title: 'Ops',
					text:
						'Código inválido. Insira o código correto recebido por e-mail ou solicite um novo código.',
					icon: 'warning',
					buttons: {
						cancelar: {
							text: 'Voltar',
							className: 'btn-secondary'
						},
						reenviar: {
							text: 'Reenviar código',
							value: 'reenviar',
							className: 'btn-primary'
						}
					}
				}).then((value) => {
					if (value === 'reenviar') {
						reenviarCodigo(values.cpf);
					}
				});
				setSubmitting(false);
				resetForm({});
			});
	};

	return (
		<Row>
			<div className='col-lg-6 d-none d-lg-block bg-confirmar-codigo-image'></div>
			<div className='col-lg-6'>
				<div className='p-5'>
					<div className='d-flex justify-content-center py-3'>
						<Link to='/home'>
							<LogoBeganinha name='be_preto' classNameCustom='logo-be' />
						</Link>
					</div>
					<div className='text-center'>
						<h1 className='h4 py-3'>Código de verificação</h1>
					</div>
					<Formik
						initialValues={INITIAL_VALUES}
						validationSchema={validationSchema}
						onSubmit={onSubmitConfirmar}
					>
						{({ handleSubmit, isSubmitting }) => (
							<Form className='form-be-custom' onSubmit={handleSubmit}>
								<div className='form-group'>
									<CustomMaskedTextInput
										name='cpf'
										type='tel'
										placeholder='CPF'
										mask={[
											/[0-9]/,
											/\d/,
											/\d/,
											'.',
											/\d/,
											/\d/,
											/\d/,
											'.',
											/\d/,
											/\d/,
											/\d/,
											'-',
											/\d/,
											/\d/
										]}
										className='custom-form-control'
									/>
								</div>
								<div className='form-group'>
									<CustomTextInput
										name='codigo'
										type='tel'
										placeholder='Código de verificação'
										className='custom-form-control'
									/>
								</div>
								<Button
									classes='btn btn-primary btn-block rounded-pill'
									texto='Confirmar conta'
									disabled={isSubmitting}
								/>
							</Form>
						)}
					</Formik>
				</div>
			</div>
		</Row>
	);
};

export default compose(
	withRouter,
	connect(null, { confirmaCodigo, reenviaCodigo })
)(ConfirmarConta);
