import React, { useState } from 'react';
import { connect } from 'react-redux';
import SimpleBar from 'simplebar-react';
import swal from 'sweetalert';

import { formataMoeda } from '../../../../../../config/helper/utils';
import Pagination from '../../../../../components/common/Pagination';
import If from '../../../../../components/common/If';
import Row from '../../../../../components/common/Row';
import Button from '../../../../../components/form/Button';
import {
	success,
	error,
	MSG_TOAST_ERRO_DE_NOVO
} from '../../../../../components/toast/action/ToastAction';

import {
	remove,
	setLoadingGeneric,
	setAtivoCurrent
} from '../action/MinhasOperacoesAction';

const ListaDetalhesOperacoes = (props) => {
	const {
		operacoes,
		title,
		remove,
		erroCountInvestidorGeneric,
		setLoadingGeneric,
		setAtivoCurrent
	} = props;

	const [column, setColumn] = useState(); //Crescente
	const [order, setOrder] = useState(false); //Crescente
	const [showTable, setShowTable] = useState(false);

	// =============== bloco necessário para a paginação funcionar =======
	const [itemsPerPage] = useState(5);

	const [currentPage, setCurrentPage] = useState(1);
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentOperacoes =
		operacoes && operacoes.slice(indexOfFirstItem, indexOfLastItem);
	// =============== fim do bloco necessário para a paginação funcionar =======

	const handleRemove = async (item) => {
		let myhtml = document.createElement('div');
		myhtml.innerHTML = `Tem certeza que deseja apagar a operação financeira<br>
		<b>Nome do ativo</b>: ${item.nome}</br>
		Quantidade: ${formataMoeda(item.quantidade)}</br>
		Valor unitário: ${formataMoeda(item.valorUnitario)}</br>
		Valor total: ${formataMoeda(item.valorTotal)}</br>`;

		swal({
			title: 'Tem certaza?!',
			content: myhtml,
			icon: 'warning',

			buttons: {
				reenviar: {
					text: 'Cancelar',
					value: 'cancelar',
					className: 'btn-secondary'
				},
				confirmar: {
					text: 'Confirmar',
					value: 'confirmar',
					className: 'btn-primary'
				}
			}
		}).then((value) => {
			if (value === 'confirmar') {
				setLoadingGeneric();
				remove(item)
					.then(() => {
						success();
					})
					.catch(() => {
						if (erroCountInvestidorGeneric >= 1) {
							error(MSG_TOAST_ERRO_DE_NOVO);
						} else {
							error();
						}
					});
			} else {
				setAtivoCurrent(null, false);
			}
		});
	};

	// const validaQuantidade = (item) => {
	// 	const qtdeTotal = operacoes.reducer((acc, item) => acc + item.quantidade, 0)
	// }

	const renderRows = (array, order, column = 'valorTotal') => {
		return array
			.sort((a, b) => {
				if (order) {
					return a[column] < b[column] ? -1 : a[column] > b[column] ? 1 : 0;
				} else {
					return b[column] < a[column] ? -1 : b[column] > a[column] ? 1 : 0;
				}
			})
			.map((item) => (
				<tr key={item.id}>
					<td>
						<span className='text-gray-800'>{item.codigo}</span>
					</td>
					<td>{item.nome}</td>
					<td>{item.dataOperacao}</td>
					<td>{item.quantidade}</td>
					<td>{formataMoeda(item.valorUnitario)}</td>
					<td>{formataMoeda(item.taxas)}</td>
					<td>{formataMoeda(item.valorTotal)}</td>
					<td className='text-center'>
						<Button
							icon='fas fa-edit'
							title='Editar operação'
							classes='btn action-icon'
							onClick={() => {
								setAtivoCurrent(item, true);
							}}
							disabled={false}
						/>

						<Button
							icon='fas fa-trash'
							title='Remover operação'
							classes='btn action-icon'
							onClick={() => handleRemove(item)}
							disabled={false}
						/>
					</td>
				</tr>
			));
	};

	const randerHeader = () => {
		return (
			<tr>
				<th>Código</th>
				<th style={{ width: '150px' }}>Nome</th>
				<th
					onClick={() => {
						setColumn('dataOperacao');
						setOrder(!order);
					}}
				>
					Data da operação
					<span className='ml-2'>
						{order && column === 'dataOperacao' ? (
							<i className='fas fa-sort-up'></i>
						) : (
							<i className='fas fa-sort-down'></i>
						)}
					</span>
				</th>
				<th>Quantidade</th>
				<th>Valor unitário</th>
				<th>Taxas</th>
				{/* <th
					onClick={() => {
						setColumn('valorAtual');
						setOrder(!order);
					}}
				>
					Valor já acumulado
					<span className='ml-2'>
						{order && column === 'valorAtual' ? (
							<i className='fas fa-sort-up'></i>
						) : (
							<i className='fas fa-sort-down'></i>
						)}
					</span>
				</th> */}
				<th
					onClick={() => {
						setColumn('valorTotal');
						setOrder(!order);
					}}
				>
					Valor Total
					<span className='ml-2'>
						{order && column === 'valorTotal' ? (
							<i className='fas fa-sort-up'></i>
						) : (
							<i className='fas fa-sort-down'></i>
						)}
					</span>
				</th>
				<th className='text-center'>Ações</th>
			</tr>
		);
	};

	return (
		<If test={currentOperacoes !== null && currentOperacoes.length > 0}>
			<Row classes='mb-2'>
				<div className='col-11 text-left'>
					<p className='font-weight-bold h5 pl-3'>{title}</p>
				</div>
				<div className='col-1'>
					<Button
						classes='btn btn-primary rounded-pill my-0'
						icon={`fas ${showTable ? 'fa-minus' : 'fa-plus'}`}
						disabled={false}
						onClick={() => {
							setShowTable(showTable === false && true);
							// handleClickDetalhes(objetivo.sk);
						}}
					/>
				</div>
			</Row>
			<If test={showTable}>
				<Row classes='mb-3'>
					<div className='table-responsive fixed-first-row'>
						<SimpleBar style={{ height: '100%' }}>
							<table className='table table-centered mb-0'>
								<thead className='thead-light'>{randerHeader()}</thead>
								<tbody>{renderRows(currentOperacoes, order, column)}</tbody>
							</table>
						</SimpleBar>
					</div>
				</Row>
				<Row>
					<div className='col-12 text-center'>
						<Pagination
							activePage={currentPage}
							itemsPerPage={itemsPerPage}
							totalItems={operacoes ? operacoes.length : 0}
							currentPage={currentPage}
							setCurrentPage={setCurrentPage}
						/>
					</div>
				</Row>
			</If>
		</If>
	);
};

const mapStateToProps = (state) => ({
	loadingInvestidorGeneric: state.operacoesStore.loadingInvestidorGeneric,
	erroCountInvestidorGeneric: state.operacoesStore.erroCountInvestidorGeneric
});

export default connect(mapStateToProps, {
	remove,
	setLoadingGeneric,
	setAtivoCurrent
})(ListaDetalhesOperacoes);
