import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { setSideBarToggle } from '../action/SideBarAction';

const InvestidorSucessoItem = (props) => {
	const { setSideBarToggle } = props;
	const [show, setShow] = useState('');
	return (
		<div className='tour-passo-investidor'>
			{/* <!-- Heading --> */}
			{/* <div className='sidebar-heading'>Investidor de Sucesso</div> */}

			{/* <!-- Nav Item - Pages Collapse Menu --> */}
			<li className='nav-item'>
				<a
					className='nav-link collapsed'
					href='#!'
					data-toggle='collapse'
					data-target='#collapseDinheiro'
					aria-expanded='true'
					aria-controls='collapseDinheiro'
					onClick={() => setShow('')}
				>
					<i className='fas fa-fw fa-comment-dollar'></i>
					<span>Investidor de Sucesso</span>
				</a>
				<div
					id='collapseDinheiro'
					className={`collapse ${show}`}
					aria-labelledby='headingPages'
					data-parent='#accordionSidebar'
				>
					<div className='bg-white py-2 collapse-inner rounded'>
						<Link
							className='collapse-item'
							to='/dashboard/investidor/minha-carteira'
							onClick={() => {
								setShow('hide');
								setSideBarToggle('toggled');
							}}
						>
							Minha carteira
						</Link>
						<Link
							className='collapse-item'
							to='/dashboard/investidor/minhas-operacoes'
							onClick={() => {
								setShow('hide');
								setSideBarToggle('toggled');
							}}
						>
							Minhas Operações
						</Link>
						<Link
							className='collapse-item'
							to='/dashboard/investidor/meus-proventos'
							onClick={() => {
								setShow('hide');
								setSideBarToggle('toggled');
							}}
						>
							Meus Proventos
						</Link>
						<div className='collapse-divider'></div>
						<Link
							className='collapse-item'
							to='/dashboard/investidor/meus-graficos'
							onClick={() => {
								setShow('hide');
								setSideBarToggle('toggled');
							}}
						>
							Meus gráficos
						</Link>
						<Link
							className='collapse-item'
							to='/dashboard/investidor/minhas-analises'
							onClick={() => {
								setShow('hide');
								setSideBarToggle('toggled');
							}}
						>
							Minhas análises
						</Link>
					</div>
				</div>
			</li>
		</div>
	);
};

export default connect(null, { setSideBarToggle })(InvestidorSucessoItem);
