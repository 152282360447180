import React from 'react';

import grafico from '../../../assets/img/Grafico.png';
import LinkAbraSuaConta from './LinkAbraSuaConta';
import Row from '../../components/common/Row';

const VamosComecar = () => {
	return (
		<div className='container-fluid' style={{ backgroundColor: 'white' }}>
			<div className='container py-5'>
				<Row classes='wow fadeInLeft justify-content-center'>
					<div className='col-12 col-md-10 col-lg-9'>
						<h3 className='heading'>Vamos Começar?</h3>
						<div className='heading-underline'></div>
					</div>
				</Row>
				<Row classes='wow fadeInUp justify-content-center'>
					<div className='col-sm-10 col-md-6 pb-4'>
						<img src={grafico} alt='Gráfico' className='w-100' />
					</div>
					<div className='col-sm-10 col-md-6 text-left'>
						<h4>
							Você sabia que mais da metade das pessoas foge de conversas
							relacionadas a dinheiro e evita até mesmo pensar nisso?
						</h4>
						<p className='lead text-justify'>
							A gente sabe da importância do dinheiro em nossas vidas e a gente
							está aqui para te mostrar de um jeito fácil e objetivo como anda
							sua vida financeira, para onde está indo seu dinheiro e onde você
							pode guardá-lo. Queremos te ajudar a melhorar o seu relacionamento
							com ele te guiando e oferecendo o suporte necessário.
							{/* <strong>Let's Be.</strong> */}
						</p>
					</div>
				</Row>
				<Row classes='wow fadeInRight justify-content-center'>
					<div className='col-sm-10 col-md-6 pb-4'></div>
					<div className='col-sm-10 col-md-6 pb-4'>
						<div className='text-center py-3'>
							<LinkAbraSuaConta />
						</div>
					</div>
				</Row>
			</div>
		</div>
	);
};

export default VamosComecar;
