import React from 'react';
import PropTypes from 'prop-types';
import CountUp from 'react-countup';

import { formataMoeda } from '../../../config/helper/utils';
import Card from './Card';
import CardBody from './CardBody';
import If from './If';

const CardWidget = (props) => {
	const {
		title,
		color,
		icon,
		value = 0,
		percent = 0,
		countup = false,
		showVariacao = true
	} = props;

	return (
		// <i className="mdi mdi-account-multiple widget-icon bg-success-lighten text-success"></i>
		<Card classes='widget-flat' style={{ minHeight: '200px' }}>
			<CardBody>
				<div className='float-right'>
					<i
						className={`${icon} fa-2x widget-icon bg-${color}-lighten text-${color} pt-1 pb-2`}
					></i>
				</div>
				<h5 className='text-muted font-weight-normal mt-0' title={title}>
					{title}
				</h5>
				<h3 className='mt-3 mb-3'>
					{countup ? (
						<CountUp
							end={value}
							separator='.'
							decimals={2}
							decimal=','
							prefix='R$ '
						/>
					) : (
						formataMoeda(value)
					)}
				</h3>
				{showVariacao && (
					<p className='mb-0 text-muted'>
						<span
							className={`text-${percent >= 0 ? 'primary' : 'secondary'} mr-2`}
						>
							<i
								className={`${
									percent > 0
										? 'fas fa-arrow-circle-up'
										: percent < 0
										? 'fas fa-arrow-circle-down'
										: 'fas fa-arrow-circle-right'
								}`}
							></i>{' '}
							<If test={percent !== 'valor2Zerado'}>{percent} %</If>
						</span>
						<span>{`${
							percent > 0
								? 'Maior que o mês anterior'
								: percent < 0
								? 'Menor que o mês anterior'
								: percent === 'valor2Zerado'
								? 'Não houve lançamento no mês anterior'
								: 'Não houve variação'
						}`}</span>
					</p>
				)}
			</CardBody>
		</Card>
	);
};

CardWidget.propTypes = {
	title: PropTypes.string.isRequired,
	color: PropTypes.string.isRequired,
	icon: PropTypes.string.isRequired,
	value: PropTypes.number.isRequired,
	percent: PropTypes.any,
	countup: PropTypes.bool,
	showVariacao: PropTypes.bool
};

export default CardWidget;
