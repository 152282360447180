import React from 'react';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import Select from 'react-select';

const CustomSelectOptionsInput = (props) => {
	const [field, meta] = useField(props);
	const { className, options } = props;
	const { setFieldValue, setFieldTouched } = useFormikContext();

	// const renderOptions = () => {
	// 	return options.map((opt) => <option value={opt.value} label={opt.label} />);
	// };
	return (
		<>
			<Select
				{...field}
				{...props}
				className={`${meta.error && `border border-secondary`} ${className}`}
				isSearchable={true}
				options={options}
				onChange={(val, e) => {
					setFieldValue(field.name, val);
				}}
				onBlur={() => setFieldTouched(field.name, true)}
			/>
			{meta.error ? (
				<div className='ml-1 form-be-custom-error'>{meta.error}</div>
			) : null}
		</>
	);
};

CustomSelectOptionsInput.propTypes = {
	name: PropTypes.string.isRequired,
	placeholder: PropTypes.any.isRequired,
	className: PropTypes.string.isRequired,
	options: PropTypes.array
};

export default CustomSelectOptionsInput;
