import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import swal from 'sweetalert';

import { sendEmail } from '../../components/common/action/CommonAction';
import LogoBeganinha from '../../components/common/SVGIcon';
import Row from '../../components/common/Row';
import CustomTextInput from '../../components/form/CustomTextInput';
import CustomTextArea from '../../components/form/CustomTextArea';
import Button from '../../components/form/Button';

const Footer = (props) => {
	const { sendEmail, erroCountEmail } = props;

	const [INITIAL_VALUES] = useState({
		nome: '',
		email: '',
		mensagem: ''
	});

	const validationSchema = Yup.object({
		nome: Yup.string().required('O nome é obrigatório'),
		email: Yup.string()
			.email('Endereço de e-mail inválido')
			.required('O e-mail é obrigatório'),
		mensagem: Yup.string().required('A mensagem é obrigatória')
	});

	const onSubmit = (values, { setSubmitting, resetForm }) => {
		values.title = 'Fale Conosco';
		sendEmail(values).then(() => {
			resetForm({});
			handleDialog('Tudo certo!', 'Recebemos seu e-mail. Pode ficar tranquilo que responderemos a você o mais breve possível.');
			setSubmitting(false);
		}).catch((err) => {
			if (erroCountEmail >= 1) {
				handleDialog('Ops', 'Parece que continuamos com problemas, mas não se preocupe que nossa equipe já está trabalhando para resolvê-lo.', 'warning');
			} else {
				handleDialog('Ops', 'Não conseguimos enviar seu e-mail. Vamos tentar de novo?', 'warning');
			}
			setSubmitting(false);
		});
	};

	const handleDialog = (title, text, type = 'success') => {
		swal({
			title: title,
			text: text,
			icon: type,
			buttons: {
				ok: {
					text: 'Ok',
					className: 'btn-primary'
				}
			}
		})
	}
	return (
		<div className='jumbotron m-0 rounded-0'>
			<div className='container'>
				<Row>
					<div className='col-md-5 px-0 pr-md-3'>
						<LogoBeganinha
							name='beganinha_verde_claro'
							classNameCustom='logo-beganinha-footer'
						/>
						<p className='lead pt-3 text-justify'>
							A gente te ajuda na dívida ou no investimento, mas a gente sabe
							que pra te ajudar é necessário que se crie uma relação de confiança e
							para isso queremos ficar sempre próximos a você. Então, segue a
							gente nas nossas redes sociais ou mande uma mensagem pra gente 
							e vamos estreitar nossos vínculos!
						</p>
						<ul className='social'>
							<li>
								<a href='https://www.instagram.com/beganinha/'>
									<i className='fab fa-instagram fa-2x' aria-hidden='true'></i>
								</a>
							</li>
							{/* <li>
								<a href='!#'>
									<i
										className='fab fa-twitter fa-2x mx-3'
										aria-hidden='true'
									></i>
								</a>
							</li>
							<li>
								<a href='!#'>
									<i className='fab fa-youtube fa-2x' aria-hidden='true'></i>
								</a>
							</li> */}
						</ul>
					</div>

					<div className='col-md-7 px-0 pl-md-3'>
						<hr className='d-md-none' />
						<h3 className='text-primary'>Fale Conosco</h3>
						<Formik
							enableReinitialize
							initialValues={INITIAL_VALUES}
							validationSchema={validationSchema}
							onSubmit={onSubmit}
						>
							{({ values, isSubmitting, resetForm }) => (
								<Form className='form-be-custom'>
									<div className='pt-3'>
										<div className='form-group'>
											<CustomTextInput
												name='nome'
												type='text'
												placeholder='Seu nome'
												className='custom-form-control-retangular'
											/>
										</div>

										<div className='form-group'>
											<CustomTextInput
												name='email'
												type='text'
												placeholder='Seu e-mail'
												className='custom-form-control-retangular'
											/>
										</div>

										<div className='form-group'>
											<CustomTextArea
												name='mensagem'
												type='text'
												placeholder='Sua mensagem'
												className='custom-form-control-retangular'
												rows={4}
												disabled={false}
											/>
										</div>
										<Button
											classes='btn btn-secondary rounded-pill btn-lg'
											texto='Enviar'
											disabled={isSubmitting}
											id='btn-enviar'
										/>
									</div>
									{/* <!-- End Controls --> */}
								</Form>
							)}
						</Formik>
					</div>
					{/* <!-- End Column --> */}
				</Row>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	erroCountEmail: state.commonStore.erroCountEmail
});

export default connect(mapStateToProps, {
	sendEmail
})(Footer);
