import { SET_TOGGLE } from './types';

const INITIAL_STATE = {
	sideBarToggle: 'toggled'
};

export const sideBarReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_TOGGLE: {
			return {
				...state,
				sideBarToggle: action.payload
			};
		}
		default:
			return state;
	}
};
