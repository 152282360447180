import {
    SET_PASSWORD_VISIBILITY,
	CLEAR_STATE_FORM
} from './types';

export const setPasswordVisibility = (value) => {
	return {
		type: SET_PASSWORD_VISIBILITY,
		payload: value
	};
};

export const clearStateForm = () => {
	return {
		type: CLEAR_STATE_FORM
	};
};