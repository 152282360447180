import React from 'react';
import PropTypes from 'prop-types';

const ScrollTo = (props) => {
	return (
		<a href={props.onde} className='top-scroll'>
			<i className='fa fa-angle-up fa-2x'></i>
		</a>
	);
};

ScrollTo.propTypes = {
    onde: PropTypes.string.isRequired,
};

export default ScrollTo;
