import React from 'react';
import { Helmet } from 'react-helmet';

// import { setLoading } from './autenticacao/action/AuthAction';
// import Spinner from '../components/preLoader/Spinner';
import Card from '../components/common/Card';
import CardBody from '../components/common/CardBody';
import Row from '../components/common/Row';

const MinimalLayout = (props) => {
	const { children, background } = props;

	// useEffect(() => {
	// 	setLoading(false);
	// 	// eslint-disable-next-line
	// }, [loading])

	// if(loading) {
	// 	return <Spinner  id='spinner-centro-sm'/>
	// }

	return (
		<div className='h-100'>
			<Helmet>
				<body className={background} />
				<style type='text/css'>
					{`
						body, html {
							height: 100%;
							max-height: 100%;
						}
					`}
				</style>
			</Helmet>
			<div className='container'>
				{/* <!-- Outer Row --> */}
				<Row classes='d-flex justify-content-center'>
					<div className='col-xl-10 col-lg-12 col-md-9'>
						<Card classes='overflow-hidden border-0 my-5'>
							<CardBody classes='p-0'>{children}</CardBody>
						</Card>
					</div>
				</Row>
			</div>
		</div>
	);
};

export default MinimalLayout;
