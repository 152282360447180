import {
	GET_MINHA_CARTEIRA,
	ERROR_CARTEIRA,
	SET_LOADING_CARTEIRA,
	SET_SHOW_MODAL_CARTEIRA,
	GET_MINHAS_INSTITUICOES_CART,
	SET_ID_CARTEIRA_CURRENT,
	GET_DETALHE_CARTEIRA,
	CLEAR_STATE_CARTEIRA,
	GET_COTACAO_ATIVO,
	SET_CLEAR_ERRO_SEM_ATIVOS
} from './types';

import { API } from 'aws-amplify';
// import moment from 'moment';

const apiName = 'be-investidor-sucesso';
const path = '/minha-carteira';

export const getMinhasInstituicoes = () => {
	return (dispatch) =>
		new Promise((resolve, reject) => {
			API.get(apiName, path + '/instituicoes', {
				response: true // OPTIONAL (return the entire Axios response object instead of only response.data)
			})
				.then((items) => {
					dispatch({
						type: GET_MINHAS_INSTITUICOES_CART,
						payload: items.data.Items
					});
					resolve();
				})
				.catch((err) => {
					dispatch({
						type: ERROR_CARTEIRA,
						payload: [err, true, false]
					});
					reject(err);
				});
		});
};

export const getMinhaCarteira = () => {
	return (dispatch) =>
		new Promise((resolve, reject) => {
			API.get(apiName, path + '/carteira', {
				response: true // OPTIONAL (return the entire Axios response object instead of only response.data)
			})
				.then((items) => {
					dispatch({
						type: GET_MINHA_CARTEIRA,
						payload: items.data
					});
					resolve();
				})
				.catch((err) => {
					dispatch({
						type: ERROR_CARTEIRA,
						payload: [err, true, false]
					});
					reject(err);
				});
		});
};

export const getMinhaCarteiraBySK = (sk) => {
	return (dispatch) =>
		new Promise((resolve, reject) => {
			API.get(apiName, path + '/carteira/sk', {
				response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
				queryStringParameters: {
					// OPTIONAL
					sk: sk
				}
			})
				.then((items) => {
					dispatch({
						type: GET_DETALHE_CARTEIRA,
						payload: items.data
					});
					resolve();
				})
				.catch((err) => {
					dispatch({
						type: ERROR_CARTEIRA,
						payload: [err, true, false]
					});
					reject(err);
				});
		});
};

export const getCotacaoAtivo = (ativo) => {
	return (dispatch) =>
		new Promise((resolve, reject) => {
			dispatch({
				type: GET_COTACAO_ATIVO,
				payload: null
			});
			resolve();
		});
};

export const setIdCarteiraCurrent = (value) => {
	return (dispatch) =>
		dispatch({
			type: SET_ID_CARTEIRA_CURRENT,
			payload: value
		});
};

// const errorDispatch = (err, erroGet, erroCount, values, showModal = false) => {
// 	return [
// 		{
// 			type: ERROR_CARTEIRA,
// 			payload: [err, erroGet, erroCount]
// 		}
// 		// setLancamentoCurrent(values, showModal)
// 	];
// };

export const setLoadingCarteira = (value) => {
	return (dispatch) =>
		dispatch({
			type: SET_LOADING_CARTEIRA,
			payload: value
		});
};

export const setShowModalCarteira = (showModal) => {
	return {
		type: SET_SHOW_MODAL_CARTEIRA,
		payload: showModal
	};
};

export const clearStateCarteira = () => {
	return {
		type: CLEAR_STATE_CARTEIRA
	};
};

export const clearErrorSemAtivos = () => {
	return {
		type: SET_CLEAR_ERRO_SEM_ATIVOS
	};
};
