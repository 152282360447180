import React from 'react';
import { useField, useFormikContext, Field } from 'formik';
import PropTypes from 'prop-types';
import Switch from 'react-switch';

const CustomSwitchInput = (props) => {
	const [field] = useField(props);
	const { setFieldValue } = useFormikContext();
	return (
		<Field {...field} {...props}>
			{({ field }) => (
				<Switch
					{...field}
					{...props}
					checked={field.value}
					onChange={(val) => {
						setFieldValue(field.name, val);
					}}
					height={24}
					className='react-switch'
				/>
			)}
		</Field>
	);
};

CustomSwitchInput.propTypes = {
	name: PropTypes.string.isRequired
};

export default CustomSwitchInput;
