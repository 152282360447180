import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { WOW } from 'wowjs';
// import { Auth } from 'aws-amplify';

//CSS customizado do Bootstrap em conjunto com as classes exclusivas do projeto
//Os imports de CSS de bibliotecas estão sendo realizados no dashboard.css
import './assets/css/style.css';
import './assets/css/comum.css';
import './assets/css/plugins.css';
import Routes from './Routes';
import { carregarUsuario } from './layouts/minimal/autenticacao/action/AuthAction';
import { setLoading } from './layouts/components/common/action/CommonAction';

function App(props) {
	const { carregarUsuario, setLoading } = props;



	useEffect(() => {
		carregarUsuario().then(() => {
			setLoading(true);
		}).catch(() => {
			setLoading(false);

		});

		new WOW({
			boxClass: 'wow', // animated element css class (default is wow)
			animateClass: 'animated', // animation css class (default is animated)
			live: false
		}).init();
		
		// eslint-disable-next-line
	}, []);

	return (
		<Router>
			{/* <Router forceRefresh={true}> */}
			<Routes />
		</Router>
	);
}

export default connect(null, {
	carregarUsuario,
	setLoading
})(App);
