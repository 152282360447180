import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
const TabContent = (props) => {
	const { id, tabSelected, children } = props;

	let active = id === tabSelected ? 'active show' : '';

	return (
		<div className='tab-content'>
			<div className={`tab-pane fade ${active}`} id={id}>
				{children}
			</div>
		</div>
	);
};

TabContent.propTypes = {
	id: PropTypes.number.isRequired
};

const mapStateToProps = (state) => ({
	tabSelected: state.tabStore.tabSelected
});

export default connect(mapStateToProps)(TabContent);
