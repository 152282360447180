import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { connect } from 'react-redux';
// import moment from 'moment';
import SimpleBar from 'simplebar-react';

import Card from '../../../components/common/Card';
import CardBody from '../../../components/common/CardBody';
import CardHeader from '../../../components/common/CardHeader';
import Row from '../../../components/common/Row';
import { formataMoeda } from '../../../../config/helper/utils';
import { calculaPrazo } from './helper/utils';
import Spinner from '../../../components/preLoader/Spinner';
import Tooltip from '../../../components/common/Tooltip';
import NaoHaObjetivos from './NaoHaObjetivos';

const GraficoPizzaPrazoObjetivo = (props) => {
	const { arraySerieGraficoPrazoObjetivo, objetivos, loading } = props;

	const [column, setColumn] = useState();
	const [order, setOrder] = useState(false); //Crescente
	const [itemGraficoClicado, setItemGraficoClicado] = useState(-1);

	const [options] = useState({
		chart: {
			width: 380,
			type: 'pie',
			events: {
				dataPointSelection: function (event, chartContext, config) {
					// console.log(config);
					// console.log(config.config.series[config.seriesIndex].name)
					// console.log(config.config.series[config.seriesIndex].data[config.dataPointIndex])
					if (config.dataPointIndex === 0) {
						setItemGraficoClicado('Curto Prazo');
					} else if (config.dataPointIndex === 1) {
						setItemGraficoClicado('Médio Prazo');
					} else if (config.dataPointIndex === 2) {
						setItemGraficoClicado('Longo Prazo');
					} else {
						setItemGraficoClicado(-1);
					}
				}
			}
		},
		// labels: ['Salário', 'Aluguel', 'Jantar', 'Bolsa', 'luz', 'agua', 'net', 'IPVA', 'Academia', 'seguro', 'gasolina', 'outros1'],
		labels: ['Curto Prazo', 'Médio Prazo', 'Longo Prazo'],
		legend: {
			position: 'bottom'
		},
		colors: ['#74d7d7', '#17a2b8', '#087e8b', '#0acf97'], //Curto, medio, Longo prazo
		dataLabels: {
			enabled: true
		},
		stroke: {
			curve: 'smooth',
			width: 4
		},
		zoom: {
			enabled: false
		}
	});

	const handleTableDetalhes = () => (
		<SimpleBar style={{ height: '400px' }}>
			<table className='table table-centered mb-0'>
				<thead className='thead-light'>
					<tr>
						<th>Objetivo</th>
						<th
							onClick={() => {
								setColumn('dataVencimento');
								setOrder(!order);
							}}
						>
							Data de vencimento
							<span className='ml-2'>
								{order && column === 'dataVencimento' ? (
									<i className='fas fa-sort-up'></i>
								) : (
									<i className='fas fa-sort-down'></i>
								)}
							</span>
						</th>
						<th
							onClick={() => {
								setColumn('valorAtual');
								setOrder(!order);
							}}
						>
							Valor já acumulado
							<span className='ml-2'>
								{order && column === 'valorAtual' ? (
									<i className='fas fa-sort-up'></i>
								) : (
									<i className='fas fa-sort-down'></i>
								)}
							</span>
						</th>
						<th
							onClick={() => {
								setColumn('valorMeta');
								setOrder(!order);
							}}
						>
							Valor da meta
							<span className='ml-2'>
								{order && column === 'valorMeta' ? (
									<i className='fas fa-sort-up'></i>
								) : (
									<i className='fas fa-sort-down'></i>
								)}
							</span>
						</th>
					</tr>
				</thead>
				<tbody>{renderRows(order, column)}</tbody>
			</table>
		</SimpleBar>
	);

	const renderRows = (order, column = 'valorMeta') => {
		const objetivosFiltrados =
			itemGraficoClicado === -1
				? objetivos
				: objetivos.filter(
						(item) => calculaPrazo(item.prazo) === itemGraficoClicado
				  );
		// console.log(tipoLancamentoClicado)
		// console.log(lancamentosFiltrados)
		return objetivosFiltrados
			.sort((a, b) => {
				if (order) {
					return a[column] < b[column] ? -1 : a[column] > b[column] ? 1 : 0;
				} else {
					return b[column] < a[column] ? -1 : b[column] > a[column] ? 1 : 0;
				}
			})
			.map((objetivo, index) => (
				<tr key={index}>
					<td>
						<span className='text-gray-800'>{objetivo.nome}</span>
					</td>
					<td>{objetivo.dataVencimento}</td>
					<td>{formataMoeda(objetivo.valorAtual)}</td>
					<td>{formataMoeda(objetivo.valorMeta)}</td>
				</tr>
			));
	};

	// if (loadingLancamentos) {
	// 	return <Spinner id='spinner-centro-sm' />;
	// }

	return (
		<>
			<Card classes='mb-4 tour-passo-grafico-recebimento-gastos'>
				<CardHeader classes='d-flex flex-row align-items-center'>
					<h6 className='m-0 font-weight-bold text-primary'>
						Análise da distribuição dos objetivos por prazo
					</h6>
					<Tooltip
						text={`O gráfico mostra a distribuição dos objetivos de acordo com o prazo (curto, médio e longo prazo) estipulado para o término. 
							Além disso, ao clicar em uma das fatias do gráfico, é exibido o detalhamento dos objetivos correspondentes ao prazo que a fatia representa.`}
						icon='fas fa-1x fa-info-circle'
						trigger='hover'
					/>
				</CardHeader>
				{/* <!-- Card Body --> */}
				<CardBody>
					{loading ? (
						<Spinner id='spinner-centro-sm' />
					) : arraySerieGraficoPrazoObjetivo !== null && objetivos !== null && objetivos.length > 0 ? (
						<Row>
							<div
								id='objetivos-pizza-chart'
								className='apex-charts col-lg-6 col-md-12'
							>
								<ReactApexChart
									options={options}
									series={arraySerieGraficoPrazoObjetivo}
									type='pie'
									width='450'
								/>
							</div>
							<div className=' col-lg-6 col-md-12'>{handleTableDetalhes()}</div>
						</Row>
					) : (
						<NaoHaObjetivos height='45vh' />
					)}
				</CardBody>
			</Card>
		</>
	);
};

const mapStateToProps = (state) => ({
	objetivos: state.fazendoCaixaStore.objetivos,
	loading: state.commonStore.loading,
	arraySerieGraficoPrazoObjetivo:
		state.fazendoCaixaStore.arraySerieGraficoPrazoObjetivo
});

export default connect(mapStateToProps)(GraficoPizzaPrazoObjetivo);
