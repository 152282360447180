import React from 'react';
import PropTypes from 'prop-types';

const Button = (props) => {
	const { classes, disabled, texto, id, icon, title } = props;
	return (
		<button
			type='submit'
			className={classes}
			disabled={disabled}
			id={id}
			{...props}
		>
			{icon && <i className={`${icon}`} title={title}></i>}
			{disabled && (
				<span
					className='spinner-border spinner-border-sm'
					role='status'
					aria-hidden='true'
				></span>
			)}
			{texto && <span className='ml-2'>{texto}</span>}
		</button>
	);
};

Button.propTypes = {
	classes: PropTypes.string.isRequired,
	disabled: PropTypes.bool.isRequired,
	texto: PropTypes.string,
	id: PropTypes.string,
	icon: PropTypes.string,
	title: PropTypes.string
	// zeroiconmargin: PropTypes.bool
};

export default Button;
