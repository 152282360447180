import {
	CARREGA_USUARIO_FALHA,
	CARREGA_USUARIO,
	LOGOUT_SUCESSO,
	LOGOUT_FALHA,
	SET_GUIDED_TOUR_SUCESSO,
	SET_GUIDED_TOUR_FALHA,
	LOGIN_SUCESSO,
	LOGIN_FALHA,
	REENVIAR_CODIGO_SUCESSO,
	REENVIAR_CODIGO_FALHA,
	ESQUECI_SENHA_SUCESSO,
	ESQUECI_SENHA_FALHA,
	CONFIRMA_CODIGO_SUCESSO,
	CONFIRMA_CODIGO_FALHA,
	CRIA_CONTA_SUCESSO,
	CRIA_CONTA_FALHA,
	SET_ERRO_AUTENTICACAO,
	UPDATE_USER_ATTRIBUTE,
	ERROR_USER_UPDATE
} from './types';

import { Auth } from 'aws-amplify';

export const logout = () => {
	return async (dispatch) => {
		// new Promise(async (resolve, reject) => {
		try {
			const res = await Auth.signOut();
			dispatch({
				type: LOGOUT_SUCESSO,
				payload: res
			});
			// resolve(res);
		} catch (err) {
			dispatch({
				type: LOGOUT_FALHA,
				payload: err
			});
			// reject(err);
		}
	};
};

//Recuperando dados de um usuário logado
export const carregarUsuario = () => {
	return (dispatch) =>
		new Promise(async (resolve, reject) => {
			try {
				const usuario = await Auth.currentAuthenticatedUser({
					bypassCache: false // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
				});
				dispatch({
					type: CARREGA_USUARIO,
					payload: usuario
				});
				resolve(usuario);
			} catch (err) {
				dispatch({
					type: CARREGA_USUARIO_FALHA,
					payload: err
				});
				reject(err);
			}
		});
};

//Recuperando dados de um usuário logado
export const alterarGuidedTour = (usuario) => {
	return (dispatch) =>
		new Promise(async (resolve, reject) => {
			try {
				const res = await Auth.updateUserAttributes(usuario, {
					'custom:isMostrarGuidedTour': '0'
				});
				dispatch({
					type: SET_GUIDED_TOUR_SUCESSO,
					payload: res
				});
				resolve(res);
			} catch (error) {
				dispatch({
					type: SET_GUIDED_TOUR_FALHA,
					payload: error
				});
				reject(error);
			}
		});
};

export const signIn = (values) => {
	return (dispatch) =>
		new Promise(async (resolve, reject) => {
			try {
				const usuario = await Auth.signIn(values.cpf, values.senha);
				dispatch({
					type: LOGIN_SUCESSO,
					payload: usuario
				});
				resolve();
			} catch (error) {
				dispatch({
					type: LOGIN_FALHA,
					payload: error
				});
				reject(error);
			}
		});
};

export const reenviaCodigo = (cpf) => {
	return (dispatch) =>
		new Promise(async (resolve, reject) => {
			try {
				const usuario = await Auth.resendSignUp(cpf);
				dispatch({
					type: REENVIAR_CODIGO_SUCESSO,
					payload: usuario
				});
				resolve(usuario);
			} catch (error) {
				dispatch({
					type: REENVIAR_CODIGO_FALHA,
					payload: error
				});
				reject(error);
			}
		});
};

export const esqueciMinhaSenha = (cpf) => {
	return (dispatch) =>
		new Promise(async (resolve, reject) => {
			try {
				const res = await Auth.forgotPassword(cpf);
				dispatch({
					type: ESQUECI_SENHA_SUCESSO,
					payload: res
				});
				resolve(res);
			} catch (error) {
				dispatch({
					type: ESQUECI_SENHA_FALHA,
					payload: error
				});
				reject(error);
			}
		});
};

export const novoCodigoSenha = (cpf, codigo, senha) => {
	return (dispatch) =>
		new Promise(async (resolve, reject) => {
			try {
				const res = await Auth.forgotPasswordSubmit(cpf, codigo, senha);
				dispatch({
					type: ESQUECI_SENHA_SUCESSO,
					payload: res
				});
				resolve(res);
			} catch (error) {
				dispatch({
					type: ESQUECI_SENHA_FALHA,
					payload: error
				});
				reject(error);
			}
		});
};

export const confirmaCodigo = (cpf, codigo) => {
	return (dispatch) =>
		new Promise(async (resolve, reject) => {
			try {
				const res = await Auth.confirmSignUp(cpf, codigo);
				dispatch({
					type: CONFIRMA_CODIGO_SUCESSO,
					payload: res
				});
				resolve(res);
			} catch (error) {
				dispatch({
					type: CONFIRMA_CODIGO_FALHA,
					payload: error
				});
				reject(error);
			}
		});
};

export const criarConta = (values) => {
	return (dispatch) =>
		new Promise(async (resolve, reject) => {
			try {
				const usuario = await Auth.signUp({
					username: values.cpf,
					password: values.senha,
					attributes: {
						email: values.email,
						name: values.nome,
						family_name: values.sobrenome,
						birthdate: values.dataNascimento,
						'custom:isMostrarGuidedTour': '1'
					}
				});
				dispatch({
					type: CRIA_CONTA_SUCESSO,
					payload: usuario
				});
				resolve();
			} catch (error) {
				dispatch({
					type: CRIA_CONTA_FALHA,
					payload: error
				});
				reject(error);
			}
		});
};

//Used to set Authentication Error that ocurred during API Call to the Backend
//This Action is called by other actions
export const setErroAutenticacao = (error) => {
	return {
		type: SET_ERRO_AUTENTICACAO,
		payload: error
	};
};

export const updateUserAttributes = (usuario, values) => {
	return (dispatch) =>
		new Promise(async (resolve, reject) => {
			try {
				const res = await Auth.updateUserAttributes(usuario, {
					name: values.name,
					family_name: values.family_name,
					'custom:isMostrarGuidedTour': values.isMostrarGuidedTour === true ? '1' : '0'
				});
				dispatch({
					type: UPDATE_USER_ATTRIBUTE,
					payload: res
				});
				resolve(res);
			} catch (error) {
				dispatch({
					type: ERROR_USER_UPDATE,
					payload: [error, true]
				});
				console.log(error)
				reject(error);
			}
		});
};