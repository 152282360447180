import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import SimpleBar from 'simplebar-react';

import Row from '../../../components/common/Row';
import { formataMoeda, formataData } from '../../../../config/helper/utils';
import Spinner from '../../../components/preLoader/Spinner';

import Pagination from '../../../components/common/Pagination';

const RowCardObjetivoDetalhe = (props) => {
	const { loadingObjetivoDetalhes, objetivoDetalhes, idx, index } = props;

	const [column, setColumn] = useState('dataOperacao');
	const [order, setOrder] = useState(false); //Crescente

	// =============== bloco necessário para a paginação funcionar =======
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage] = useState(5);

	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentObjetivoDetalhes =
		objetivoDetalhes &&
		objetivoDetalhes.slice(indexOfFirstItem, indexOfLastItem);
	// =============== fim do bloco necessário para a paginação funcionar =======

	return (
		idx === index &&
		(currentObjetivoDetalhes && currentObjetivoDetalhes.length > 0 ? (
			<>
				<Row classes='text-left'>
					<div className='col-12 d-flex align-items-center justify-content-center'>
						<span className='font-weight-bold h5'>
							Lançamentos relacionados a este objetivo
						</span>
					</div>

					<div className='table-responsive fixed-first-row'>
						{/* <SimpleBar style={{ height: 400 }}> */}
						<table className='table table-centered mb-0'>
							<thead className='thead-light'>
								<tr>
									<th>Lançamento relacionado</th>
									<th
										onClick={() => {
											setColumn('dataOperacao');
											setOrder(!order);
										}}
									>
										Data do aporte
										<span className='ml-2'>
											{order && column === 'dataOperacao' ? (
												<i className='fas fa-sort-up'></i>
											) : (
												<i className='fas fa-sort-down'></i>
											)}
										</span>
									</th>
									<th
										onClick={() => {
											setColumn('valor');
											setOrder(!order);
										}}
									>
										Valor do aporte
										<span className='ml-2'>
											{order && column === 'valor' ? (
												<i className='fas fa-sort-up'></i>
											) : (
												<i className='fas fa-sort-down'></i>
											)}
										</span>
									</th>
								</tr>
							</thead>
							<tbody>
								{currentObjetivoDetalhes
									.sort((a, b) => {
										if (order) {
											return a[column] < b[column]
												? -1
												: a[column] > b[column]
												? 1
												: 0;
										} else {
											return b[column] < a[column]
												? -1
												: b[column] > a[column]
												? 1
												: 0;
										}
									})
									.map((detalhe, key) => (
										<tr key={key}>
											<td>
												<span className='text-gray-800'>{detalhe.nome}</span>
											</td>
											<td>{formataData(detalhe.dataOperacao)}</td>
											<td>{formataMoeda(detalhe.valor)}</td>
										</tr>
									))}
							</tbody>
						</table>
						{/* </SimpleBar> */}
					</div>
				</Row>
				{currentObjetivoDetalhes && currentObjetivoDetalhes.length > 1 && (
					<Pagination
						activePage={currentPage}
						itemsPerPage={itemsPerPage}
						totalItems={objetivoDetalhes ? objetivoDetalhes.length : 0}
						currentPage={currentPage}
						setCurrentPage={setCurrentPage}
					/>
				)}
			</>
		) : loadingObjetivoDetalhes && currentObjetivoDetalhes === null ? (
			<Spinner id='spinner-centro-sm' />
		) : (
			<Row classes='text-center'>
				<div
					className='col-12 d-flex align-items-center justify-content-center'
					style={{ height: '45vh' }}
				>
					<span className='font-weight-bold h4'>
						Você ainda não deu o pontapé inicial para este seu objetivo de vida.
						Não deixe seu sonho pra depois!
					</span>
				</div>
			</Row>
		))
	);
};

RowCardObjetivoDetalhe.propTypes = {
	index: PropTypes.number.isRequired
};

const mapStateToProps = (state) => ({
	idx: state.fazendoCaixaStore.idx,
	objetivoDetalhes: state.fazendoCaixaStore.objetivoDetalhes,
	loadingObjetivoDetalhes: state.fazendoCaixaStore.loadingObjetivoDetalhes
});

export default connect(mapStateToProps)(RowCardObjetivoDetalhe);
