import {
	GET_MINHA_CARTEIRA,
	GET_DETALHE_CARTEIRA,
	SET_LOADING_CARTEIRA,
	SET_SHOW_MODAL_CARTEIRA,
    ERROR_CARTEIRA,
	SET_ID_CARTEIRA_CURRENT,
	CLEAR_STATE_CARTEIRA,
	GET_COTACAO_ATIVO,
	SET_CLEAR_ERRO_SEM_ATIVOS
} from './types';

const INITIAL_STATE = {
	carteiras: null,
	carteira: null,
	loadingCarteira: false,
	erroInvestidorCarteira: null,
	erroCountInvestidorCarteira: 0,
	isErroGetInvestidorCarteira: false,
	showModalCarteira: false,
	skCarteira: null,
	valorCotacao: 0
};

export const minhaCarteiraReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {	
		case GET_MINHA_CARTEIRA: {
			return {
				...state,
				carteiras: action.payload,
				erroInvestidorCarteira: null,
				isErroGetInvestidorCarteira: false
			};
		}
		case GET_DETALHE_CARTEIRA: {
			return {
				...state,
				carteira: action.payload,
				erroInvestidorCarteira: null,
				isErroGetInvestidorCarteira: false
			};
		}
        case ERROR_CARTEIRA: {
			const hasCount = action.payload[2] && ++state.erroCountInvestidorCarteira;
			return {
				...state,
				erroInvestidorCarteira: action.payload[0],
				isErroGetInvestidorCarteira: action.payload[1],
				erroCountInvestidorCarteira: hasCount,
			};
		}
		case SET_ID_CARTEIRA_CURRENT: {
			return {
				...state,
				skCarteira: action.payload
			};
		}
		case SET_SHOW_MODAL_CARTEIRA: {
			return {
				...state,
				showModalCarteira: action.payload,
			};
		}
		case SET_LOADING_CARTEIRA: {
			return {
				...state,
				loadingCarteira: action.payload
			};
		}
		case GET_COTACAO_ATIVO: {
			return {
				...state,
				valorCotacao: action.payload
			}
		}
		case CLEAR_STATE_CARTEIRA: {
			return INITIAL_STATE;
		}
		case SET_CLEAR_ERRO_SEM_ATIVOS: {
			return {
				...state,
				erroInvestidorCarteira: null,
				erroCountInvestidorCarteira: 0,
				isErroGetInvestidorCarteira: false				
			}
		}
		default:
			return state;
	}
};
