import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { setShowModalObjetivos } from './action/FazendoCaixaAction';

const NaoHaObjetivos = ({ height, setShowModalObjetivos }) => {
	return (
		<div
			className='col-12 d-flex align-items-center justify-content-center'
			style={{ height: height }}
		>
			<div>
				<p className='h5 font-weight-bold'>
					{`Não há objetivos de vida cadastrados. A gente te recomenda cadastrar e
					acompanhar seus sonhos. Vamos fazer isso juntos? Então, `}
					<Link to='/dashboard/caixa/meus-objetivos' onClick={() => setShowModalObjetivos(true)}>clica aqui.</Link>
				</p>
			</div>
		</div>
	);
};

NaoHaObjetivos.propTypes = {
	height: PropTypes.string.isRequired,
};

export default connect(null, { setShowModalObjetivos })(NaoHaObjetivos);
