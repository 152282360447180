import React from 'react';
import { connect } from 'react-redux';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

import Row from '../../../components/common/Row';
import Button from '../../../components/form/Button';
import { setShowModalLancamentos } from './action/ArrumandoCasaAction';

const ModalExplicacaoAnalise = (props) => {
	const { showModalLancamentos, setShowModalLancamentos } = props;
	return (
		<Modal
			open={showModalLancamentos}
			onClose={() => {
				setShowModalLancamentos(!showModalLancamentos);
			}}
			center
		>
			<Row classes='my-3'>
				<div className='col-12 text-center'>
					<span className='h4'>Análise 50-30-20</span>
				</div>
			</Row>
			<Row>
				<div className='col-10 mx-4'>
					<p className='font-weight-bold'>
						O que é a análise financeira 50-30-20?
					</p>
					<p className='text-justify'>
						Nada mais é do que uma forma de organizar seu orçamento financeiro,
						categorizando seu orçamento e colocando um peso a estas categorias.
						Na nossa visão, a grande vantagem desta análise é que ela ajuda
						aquelas pessoas que têm dificuldades em se organizar
						financeiramente.
					</p>
					<p className='font-weight-bold'>Tá, e como isso funciona?</p>
					<ul>
						<li className='text-justify'>
							<b>50%</b> dos seus recebimentos deve ser usado para seus{' '}
							<b>gastos essenciais</b>, como alimentação, moradia, saúde,
							estudos, transporte ou similares.
						</li>
						<li className='text-justify'>
							<b>30%</b> dos seus recebimentos deve ser usado para seus{' '}
							<b>gastos não-essenciais</b>, ou seja, é tudo o que você deseja
							comprar, mas não necessariamente precisa, como sair para jantar,
							lazer, hobbies, assinaturas (TV a cabo, Netflix, Spotify) ou
							similares.
						</li>
						<li className='text-justify'>
							E os <b>20%</b> devem ser usados para seus investimentos/objetivos
							financeiros, como construir uma reserva de emergência, pagar
							dívidas, investir para sua aposentadoria e similares.
						</li>
					</ul>
					<p className='text-justify'>
						Essa análise é uma das melhores ferramentas para controle de
						orçamento pessoal e é por isso que a escolhemos usá-la na análise do
						seu orçamento.
					</p>
				</div>
			</Row>
			<Row>
				<div className='col-12 text-right'>
					<Button
						classes='btn btn-primary mr-3'
						texto='Ok'
						disabled={false}
						onClick={() => {
							setShowModalLancamentos(false);
						}}
					/>
				</div>
			</Row>
		</Modal>
	);
};

const mapStateToProps = (state) => ({
	showModalLancamentos: state.arrumaCasaStore.showModalLancamentos
});

export default connect(mapStateToProps, { setShowModalLancamentos })(
	ModalExplicacaoAnalise
);
