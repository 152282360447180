import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import SimpleBar from 'simplebar-react';

import NaoHaLancamento from './NaoHaLancamento';
import {
	getMinhasContasPeriodo,
	setLoadingLancamentos
} from './action/ArrumandoCasaAction';
import { setErroAutenticacao } from '../../../minimal/autenticacao/action/AuthAction';
import Row from '../../../components/common/Row';
import { formataMoeda } from '../../../../config/helper/utils';
import Spinner from '../../../components/preLoader/Spinner';

const RowAnaliseFinanceira = (props) => {
	const {
		loadingLancamentos,
		dataContextLancamentos,
		getMinhasContasPeriodo,
		setLoadingLancamentos,
		setErroAutenticacao
	} = props;

	const [metaGastos, setMetaGastos] = useState(0);
	const [metaDesejos, setMetaDesejos] = useState(0);
	const [metaInvestimentos, setMetaInvestimentos] = useState(0);
	const [saldo, setSaldo] = useState(0);
	const [totalRecebimentos, setTotalRecebimentos] = useState(0);
	const [totalGastos, setTotalGastos] = useState(0);
	const [totalDesejos, setTotalDesejos] = useState(0);
	const [totalInvestimentos, setTotalInvestimentos] = useState(0);
	const [lancamentos, setLancamentos] = useState([]);

	const calculaTotalPorTipoLancamento = (array, tipoLancamento) => {
		return new Promise((resolve) => {
			resolve(
				array
					.filter((item) => item.tipoLancamento.value === tipoLancamento)
					.reduce((sum, record) => sum + record.valor, 0)
			);
		});
	};

	useEffect(() => {
		setLoadingLancamentos(true);
		
		getMinhasContasPeriodo(dataContextLancamentos).then((lancamentos) => {
			setLancamentos(lancamentos);
			Promise.all([
				calculaTotalPorTipoLancamento(lancamentos, 'recebimento'),
				calculaTotalPorTipoLancamento(lancamentos, 'gasto'),
				calculaTotalPorTipoLancamento(lancamentos, 'desejo'),
				calculaTotalPorTipoLancamento(lancamentos, 'investimento')
			]).then((valores) => {
				setTotalRecebimentos(valores[0]);
				setTotalGastos(valores[1]);
				setTotalDesejos(valores[2]);
				setTotalInvestimentos(valores[3]);
				
				setMetaGastos((valores[0] * 50) / 100);
				setMetaDesejos((valores[0] * 30) / 100);
				setMetaInvestimentos((valores[0] * 20) / 100);
				setSaldo(valores[0] - valores[1] - valores[2] - valores[3]);
				setLoadingLancamentos(false);
			}).catch((err) => {
				if (err.code === 'NotAuthorizedException') {
					setErroAutenticacao(err);
				}			
				setLoadingLancamentos(false);
			});
		});

		// eslint-disable-next-line
	}, [dataContextLancamentos]);

	const calculaPorcentagemDesvioMesta = (meta, realizado) => {
		let result = 0;
		if (meta > realizado) {
			//meta é o maior
			result = ((meta - realizado) * 100) / meta;
		} else {
			//meta é o menor
			result = ((realizado - meta) * 100) / meta;
		}

		return Math.round((result + Number.EPSILON) * 100) / 100;
	};

	const renderColunaResultado = (meta, realizado, tipo = 'gasto') => {
		return (
			<td
				className={`font-weight-bold ${
					tipo === 'gasto' || tipo === 'desejo'
						? meta >= realizado
							? `text-primary`
							: `text-secondary`
						: meta >= realizado
						? `text-secondary`
						: `text-primary`
				} `}
			>
				{meta > realizado && 'Abaixo'}
				{realizado > meta && 'Acima'}
				{realizado === meta && ''}{' '}
				{calculaPorcentagemDesvioMesta(meta, realizado)} % da meta
			</td>
		);
	};

	if (loadingLancamentos) {
		return <Spinner id='spinner-centro-sm' />;
	}

	const renderHeader = () => (
		<tr>
			<th></th>
			<th>Gastos essenciais (50%)</th>
			<th>Gastos não essenciais (30%)</th>
			<th>Investimentos (20%)</th>
		</tr>
	);

	const renderRows = () => (
		<>
			<tr>
				<td className='font-weight-bold'>Meta</td>
				<td>{formataMoeda(metaGastos)}</td>
				<td>{formataMoeda(metaDesejos)}</td>
				<td>{formataMoeda(metaInvestimentos)}</td>
			</tr>
			<tr>
				<td className='font-weight-bold'>Realizado</td>
				<td>{formataMoeda(totalGastos)}</td>
				<td>{formataMoeda(totalDesejos)}</td>
				<td>{formataMoeda(totalInvestimentos)}</td>
			</tr>
			<tr>
				<td className='font-weight-bold'>Resultado</td>
				{renderColunaResultado(metaGastos, totalGastos)}
				{renderColunaResultado(metaDesejos, totalDesejos)}
				{renderColunaResultado(
					metaInvestimentos,
					totalInvestimentos,
					'investimento'
				)}
			</tr>
		</>
	);

	return !loadingLancamentos &&
		lancamentos &&
		lancamentos.length > 0 &&
		totalRecebimentos > 0 ? (
		<>
			<Row classes='m-4'>
				<div className='col-12 text-center'>
					<span className='font-weigt-bold h5'>
						{`Análise baseada no total de `}
						<span className='font-weight-bold'>
							{formataMoeda(totalRecebimentos)}
						</span>{' '}
						{`em recebimentos.`}
					</span>
				</div>
			</Row>
			<Row classes='mt-sm-4'>
				<div className='table-responsive'>
					<SimpleBar style={{ maxHeight: 320 }}>
						<table className='table table-centered mb-0'>
							<thead className='thead-light'>{renderHeader()}</thead>
							<tbody>{renderRows()}</tbody>
						</table>
					</SimpleBar>
				</div>
			</Row>
			<Row classes='mt-sm-4'>
				<div className='col-12'>
					<span className='h5 font-weight-bold'>
						Seu saldo neste mês é de:{' '}
					</span>
					<span
						className={`h5 ${saldo > 0 ? `text-primary` : `text-secondary`}`}
					>
						{formataMoeda(saldo)}
					</span>
				</div>
			</Row>
		</>
	) : (
		<Row classes='mt-sm-4'>
			<NaoHaLancamento height='45vh' />
		</Row>
	);
};

const mapStateToProps = (state) => ({
	totalRecebimentos: state.arrumaCasaStore.totalRecebimentos,
	totalDesejos: state.arrumaCasaStore.totalDesejos,
	totalGastos: state.arrumaCasaStore.totalGastos,
	totalInvestimentos: state.arrumaCasaStore.totalInvestimentos,
	loadingLancamentos: state.arrumaCasaStore.loadingLancamentos,
	dataContextLancamentos: state.arrumaCasaStore.dataContextLancamentos
});

export default connect(mapStateToProps, {
	getMinhasContasPeriodo,
	setLoadingLancamentos,
	setErroAutenticacao
})(RowAnaliseFinanceira);
