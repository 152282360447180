import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import RouteWithLayout from './routes/RouteWithLayout';
import PrivateRouteWithLayout from './routes/PrivateRouteWithLayout';

//MinimalLayout e PrivateLayout
import MinimalLayout from './layouts/minimal/MinimalLayout';
import PrivateLayout from './layouts/private/PrivateLayout';

import LandingPageView from './layouts/public/landingPage/LandingPage';

import AbrirContaView from './layouts/minimal/autenticacao/abrirConta/AbrirConta';
import ConfirmarContaView from './layouts/minimal/autenticacao/confirmarConta/ConfirmarConta';
import EsqueciSenhaView from './layouts/minimal/autenticacao/esqueciSenha/EsqueciSenha';
import NovaSenhaCodigoView from './layouts/minimal/autenticacao/esqueciSenha/NovaSenhaCodigo';
import LoginView from './layouts/minimal/autenticacao/login/Login';

import NaoEncontradoView from './layouts/components/common/NaoEncontrado';

//Dashboard - private route
import DashboardView from './layouts/private/dashboard/Dashboard';
//Arrumando Casa
import MeusLancamentosView from './layouts/private/dashboard/arrumandoCasa/MeusLancamentos';
import ComoEstaDinheiroView from './layouts/private/dashboard/arrumandoCasa/ComoEstaDinheiro';
// import MeuCalendarioView from './layouts/private/dashboard/arrumandoCasa/MeuCalendario';
import MeusGraficosCasaView from './layouts/private/dashboard/arrumandoCasa/MeusGraficos';
//Fazendo Caixa
import MeusObjetivosView from './layouts/private/dashboard/fazendoCaixa/MeusObjetivos';
import MinhaEvolucaoObjetivosView from './layouts/private/dashboard/fazendoCaixa/MinhaEvolucaoObjetivos';
import MeusGraficosCaixaView from './layouts/private/dashboard/fazendoCaixa/MeusGraficos';
//Investidpr de Sucesso
import MinhasAnalisesView from './layouts/private/dashboard/investidorSucesso/MinhasAnalises';
import MeusGraficosCarteiraView from './layouts/private/dashboard/investidorSucesso/MeusGraficos';
import MeusProventosView from './layouts/private/dashboard/investidorSucesso/MeusProventos';
import MinhasOperacoesView from './layouts/private/dashboard/investidorSucesso/minhasOperacoes/MinhasOperacoes';
import ListaOperacoesView from './layouts/private/dashboard/investidorSucesso/minhasOperacoes/manual/ListaOperacoes';
import MinhaCarteiraView from './layouts/private/dashboard/investidorSucesso/minhaCarteira/MinhaCarteira';
import TabsAtivosView from './layouts/private/dashboard/investidorSucesso/minhaCarteira/TabsAtivos';
//
import CarteiraRendaFixaView from './layouts/private/dashboard/aprendendoMestres/CarteiraRendaFixa';
import CarteiraRendaVariavelView from './layouts/private/dashboard/aprendendoMestres/CarteiraRendaVariavel';
import CarteiraFiisView from './layouts/private/dashboard/aprendendoMestres/CarteiraRendaFiis';
import CarteiraFundosView from './layouts/private/dashboard/aprendendoMestres/CarteiraFundos';


import MinhaContaView from './layouts/private/dashboard/usuario/MinhaConta';
import MinhasAtividadesView from './layouts/private/dashboard/usuario/MinhasAtividades';
import ConecteMeView from './layouts/private/dashboard/usuario/ConecteMe';

const Routes = () => {
	// const randomGen = () => Math.random();
	return (
		<Switch>
			<Redirect exact from='/' to='/home' />

			<Route component={LandingPageView} path='/home' />
			{/* /** Esta view está assim, sem usar o MinimalLayout pois estava dando problema de completar a tela com bg-gradient-secondary*/}
			{/* <Route component={AbrirContaView} exact path='/abrir-conta' /> */}
			<RouteWithLayout
				component={AbrirContaView}
				exact
				layout={MinimalLayout}
				path='/abrir-conta'
				background='bg-gradient-primary'
			/>
			<RouteWithLayout
				component={ConfirmarContaView}
				exact
				layout={MinimalLayout}
				path='/confirmar-conta'
				background='bg-gradient-primary'
			/>
			<RouteWithLayout
				component={LoginView}
				exact
				layout={MinimalLayout}
				path='/login'
				background='bg-gradient-secondary'
			/>
			<RouteWithLayout
				component={EsqueciSenhaView}
				exact
				layout={MinimalLayout}
				path='/esqueci-senha'
				background='bg-gradient-secondary'
			/>
			<RouteWithLayout
				component={NovaSenhaCodigoView}
				exact
				layout={MinimalLayout}
				path='/nova-senha-codigo'
				background='bg-gradient-secondary'
			/>
			<PrivateRouteWithLayout
				component={DashboardView}
				exact
				layout={PrivateLayout}
				path='/dashboard'
			/>
			{/* Arrumando casa */}
			<PrivateRouteWithLayout
				component={MeusLancamentosView}
				exact
				layout={PrivateLayout}
				path='/dashboard/casa/meus-lancamentos'
			/>
			<PrivateRouteWithLayout
				component={ComoEstaDinheiroView}
				exact
				layout={PrivateLayout}
				path='/dashboard/casa/como-esta-dinheiro'
			/>
			<PrivateRouteWithLayout
				component={MeusGraficosCasaView}
				exact
				layout={PrivateLayout}
				path='/dashboard/casa/meus-graficos'
			/>
			{/* Fazendo bom caixa */}
			<PrivateRouteWithLayout
				component={MeusObjetivosView}
				exact
				layout={PrivateLayout}
				path='/dashboard/caixa/meus-objetivos'
			/>
			<PrivateRouteWithLayout
				component={MinhaEvolucaoObjetivosView}
				exact
				layout={PrivateLayout}
				path='/dashboard/caixa/minha-evolucao'
			/>
			<PrivateRouteWithLayout
				component={MeusGraficosCaixaView}
				exact
				layout={PrivateLayout}
				path='/dashboard/caixa/meus-graficos'
			/>
			
			{/* Investidor Sucesso */}
			<PrivateRouteWithLayout
				component={MinhaCarteiraView}
				exact
				layout={PrivateLayout}
				path='/dashboard/investidor/minha-carteira'
			/>
			<PrivateRouteWithLayout
				component={TabsAtivosView}
				exact
				layout={PrivateLayout}
				path='/dashboard/investidor/lista-carteira'
			/>
			<PrivateRouteWithLayout
				component={MinhasOperacoesView}
				exact
				layout={PrivateLayout}
				path='/dashboard/investidor/minhas-operacoes'
			/>
			<PrivateRouteWithLayout
				component={ListaOperacoesView}
				exact
				layout={PrivateLayout}
				path='/dashboard/investidor/lista-operacoes'
			/>
			<PrivateRouteWithLayout
				component={MeusProventosView}
				exact
				layout={PrivateLayout}
				path='/dashboard/investidor/meus-proventos'
			/>
			<PrivateRouteWithLayout
				component={MeusGraficosCarteiraView}
				exact
				layout={PrivateLayout}
				path='/dashboard/investidor/meus-graficos'
			/>
			<PrivateRouteWithLayout
				component={MinhasAnalisesView}
				exact
				layout={PrivateLayout}
				path='/dashboard/investidor/minhas-analises'
			/>
			{/* Navbar - private */}
			<PrivateRouteWithLayout
				component={MinhaContaView}
				exact
				layout={PrivateLayout}
				path='/dashboard/usuario/minha-conta'
			/>
			<PrivateRouteWithLayout
				component={ConecteMeView}
				exact
				layout={PrivateLayout}
				path='/dashboard/usuario/conecte-me'
			/>
			<PrivateRouteWithLayout
				component={MinhasAtividadesView}
				exact
				layout={PrivateLayout}
				path='/dashboard/usuario/minhas-atividades'
			/>
			{/* Aprendendo Mestres */}
			<PrivateRouteWithLayout
				component={CarteiraRendaFixaView}
				exact
				layout={PrivateLayout}
				path='/dashboard/dicas/carteira-renda-fixa'
			/>
			<PrivateRouteWithLayout
				component={CarteiraRendaVariavelView}
				exact
				layout={PrivateLayout}
				path='/dashboard/dicas/carteira-renda-variavel'
			/>
			<PrivateRouteWithLayout
				component={CarteiraFiisView}
				exact
				layout={PrivateLayout}
				path='/dashboard/dicas/carteira-fiis'
			/>
			<PrivateRouteWithLayout
				component={CarteiraFundosView}
				exact
				layout={PrivateLayout}
				path='/dashboard/dicas/carteira-fundos'
			/>
			<RouteWithLayout
				component={NaoEncontradoView}
				exact
				layout={MinimalLayout}
				background='bg-gradient-secondary'
			/>
		</Switch>
	);
};

export default Routes;
