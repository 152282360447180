import React from 'react';

import cardObjetivo from '../../../assets/img/card1_objetivos.png';
import cardAcompanhe from '../../../assets/img/card2_acompanhe.png';
import cardCarteira from '../../../assets/img/card3_carteira.png';

const Servicos = () => {
	return (
		<div className='jumbotron m-0 py-5 rounded-0'>
			<div className='wow fadeInUp mx-5 mb-4'>
				<div className='col-12 text-center'>
					<h4 className='heading'>Serviços</h4>
					<div className='heading-underline'></div>
				</div>

				<div className='row mb-2 mx-2 text-center'>
					<div
						className='col-sm-12 col-md-4 wow fadeInLeft'>
						<img src={cardObjetivo} alt='Objetivo' className='pb-3' />
						<h4 className='mb-3'>
							<strong>Acompanhe seu orçamento</strong>
						</h4>
						<p className='lead'>
							Acompanhe seu orçamento e trace planos e objetivos de vida e nós te mostraremos toda essa evolução.
						</p>
					</div>
					<div
						className='col-sm-12 col-md-4 wow fadeInUp'>
						<img src={cardAcompanhe} alt='Acompanhe' className='pb-3' />
						<h4 className='mb-3'>
							<strong>Receba uma seleção de ativos</strong>
						</h4>
						<p className='lead'>
							Você vai conhecer e ter acesso a uma seleção de ativos recomendados por um time ninja,
							como ações, Fundos Imobiliários e ETFs, além de ativos de renda
							fixa. Não se preocupe, te explicaremos como todos esses ativos funcionam.
						</p>
					</div>
					<div
						className='col-sm-12 col-md-4 wow fadeInRight'>
						<img src={cardCarteira} alt='Carteira' className='pb-3' />
						<h4 className='mb-3'>
							<strong>Gerencie sua carteira</strong>
						</h4>
						<p className='lead'>
							Fique por dentro do que acontence na sua carteira de investimentos e acompanhe a sua
							rentabilidade e faça simulações de aportes. Veja sua evolução
							patrimonial.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Servicos;
