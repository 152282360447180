import React from 'react';
import { useField, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import Datepicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import pt from 'date-fns/locale/pt-BR';
registerLocale('pt', pt);

const DatePicker = (props) => {
	const [field, meta] = useField(props);
	const { setFieldValue } = useFormikContext();
	const { className, dataContext } = props;
	return (
		<>
			<Datepicker
				{...field}
				{...props}
				className={`${
					meta.error && `border border-secondary`
				} form-control datepicker ${className}`}
				selected={(dataContext && new Date(dataContext)) || null}
				locale='pt'
				placeholderText={props.placeholder}
				// dateFormat="Pp"
				dateFormat={'dd/MM/yyyy'}
				onChange={(val) => {
					setFieldValue(field.name, val);
				}}
				isTypeable
			/>
			{meta.error ? (
				<div className='ml-1 form-be-custom-error'>{meta.error}</div>
			) : null}
		</>
	);
};

DatePicker.propTypes = {
	name: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	placeholder: PropTypes.string.isRequired,
	className: PropTypes.string.isRequired
};

export default DatePicker;
