import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { setSideBarToggle } from '../action/SideBarAction';

const MestresItem = (props) => {
	const { setSideBarToggle } = props;
	const [show, setShow] = useState('');

	return (
		<div data-tour='tour-passo-ninja'>
			{/* <!-- Heading --> */}
			{/* <div className='sidebar-heading'>Aprendendo com os Mestres</div> */}
			<li className='nav-item'>
				<a
					className='nav-link collapsed'
					href='#!'
					data-toggle='collapse'
					data-target='#collapseRecomendacoes'
					aria-expanded='true'
					aria-controls='collapseRecomendacoes'
					onClick={() => setShow('')}
				>
					<i className='fas fa-fw fa-chart-line'></i>
					<span>Dica dos Mestres</span>
				</a>
				<div
					id='collapseRecomendacoes'
					className={`collapse ${show}`}
					aria-labelledby='headingPages'
					data-parent='#accordionSidebar'
				>
					<div className='bg-white py-2 collapse-inner rounded'>
						<Link
							className='collapse-item'
							to='/dashboard/dicas/carteira-renda-fixa'
							onClick={() => {
								setShow('hide');
								setSideBarToggle('toggled');
							}}
						>
							Carteira de renda fixa
						</Link>
						<Link
							className='collapse-item'
							to='/dashboard/dicas/carteira-renda-variavel'
							onClick={() => {
								setShow('hide');
								setSideBarToggle('toggled');
							}}
						>
							Carteira de renda variável
						</Link>
						<Link
							className='collapse-item text-wrap'
							to='/dashboard/dicas/carteira-fiis'
							onClick={() => {
								setShow('hide');
								setSideBarToggle('toggled');
							}}
						>
							Carteira de Fundos de Investimentos Imobiliários - FIIs
						</Link>
						<Link
							className='collapse-item text-wrap'
							to='/dashboard/dicas/carteira-fundos'
							onClick={() => {
								setShow('hide');
								setSideBarToggle('toggled');
							}}
						>
							Carteira de Fundos de investimentos (FIs)
						</Link>
						{/* <div className='collapse-divider'></div>
						<Link className='collapse-item' to='/dashboard/dicas/outros-ativos'>
							Outros ativos
						</Link> */}
					</div>
				</div>
			</li>
		</div>
	);
};

export default connect(null, { setSideBarToggle })(MestresItem);
