import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { connect } from "react-redux";
import SimpleBar from "simplebar-react";

import HeaderBotoesMeses from '../../../components/common/HeaderBotoesMeses';
import Card from "../../../components/common/Card";
import CardBody from "../../../components/common/CardBody";
import CardHeader from "../../../components/common/CardHeader";
import If from "../../../components/common/If";
import Row from "../../../components/common/Row";
import {
  getMinhasContasPeriodo,
  setLoadingLancamentos,
  setDataContextLancamentos
} from "./action/ArrumandoCasaAction";
import { setErroAutenticacao } from "../../../minimal/autenticacao/action/AuthAction";
import { formataMoeda, formataData } from "../../../../config/helper/utils";
import Spinner from "../../../components/preLoader/Spinner";
import Tooltip from "../../../components/common/Tooltip";
import NaoHaLancamento from "./NaoHaLancamento";

const GraficoPizzaTipoLancamento = (props) => {
  const {
    getMinhasContasPeriodo,
    setLoadingLancamentos,
    dataContextLancamentos,
    loadingLancamentos,
    lancamentos,
    setErroAutenticacao,
	setDataContextLancamentos
  } = props;

  const [totalRecebimentos, setTotalRecebimentos] = useState(0);
  const [totalGastos, setTotalGastos] = useState(0);
  const [totalDesejos, setTotalDesejos] = useState(0);
  const [totalInvestimentos, setTotalInvestimentos] = useState(0);
  const [column, setColumn] = useState();
  const [order, setOrder] = useState(false); //Crescente
  const [itemGraficoClicado, setItemGraficoClicado] = useState(-1);

  useEffect(() => {
    setLoadingLancamentos(true);

    getMinhasContasPeriodo(dataContextLancamentos)
      .then((res) => {
        setLoadingLancamentos(false);

        setTotalRecebimentos(
          res
            .filter((item) => item.tipoLancamento.value === "recebimento")
            .reduce((sum, record) => sum + record.valor, 0)
        );
        setTotalGastos(
          res
            .filter((item) => item.tipoLancamento.value === "gasto")
            .reduce((sum, record) => sum + record.valor, 0)
        );
        setTotalDesejos(
          res
            .filter((item) => item.tipoLancamento.value === "desejo")
            .reduce((sum, record) => sum + record.valor, 0)
        );
        setTotalInvestimentos(
          res
            .filter((item) => item.tipoLancamento.value === "investimento")
            .reduce((sum, record) => sum + record.valor, 0)
        );
      })
      .catch((err) => {
        if (err.code === "NotAuthorizedException") {
          setErroAutenticacao(err);
        }
        setLoadingLancamentos(false);
      });
    // eslint-disable-next-line

    // eslint-disable-next-line
  }, [dataContextLancamentos]);

  const [options] = useState({
    chart: {
      width: 380,
      type: "pie",
      events: {
        dataPointSelection: function (event, chartContext, config) {
          // console.log(config);
          // console.log(config.config.series[config.seriesIndex].name)
          // console.log(config.config.series[config.seriesIndex].data[config.dataPointIndex])
          if (config.dataPointIndex === 0) {
            setItemGraficoClicado("recebimento");
          } else if (config.dataPointIndex === 1) {
            setItemGraficoClicado("gasto");
          } else if (config.dataPointIndex === 2) {
            setItemGraficoClicado("desejo");
          } else if (config.dataPointIndex === 3) {
            setItemGraficoClicado("investimento");
          } else {
            setItemGraficoClicado(-1);
          }
        },
      },
    },
    // labels: ['Salário', 'Aluguel', 'Jantar', 'Bolsa', 'luz', 'agua', 'net', 'IPVA', 'Academia', 'seguro', 'gasolina', 'outros1'],
    labels: [
      "Recebimentos",
      "Gastos essenciais",
      "Gastos não essenciais",
      "Investimentos",
    ],
    legend: {
      position: "bottom",
    },
    colors: ["#74d7d7", "#ff5a5f", "#17a2b8", "#087e8b", "#dc3545", "#0acf97"], //recebimento, gasto, desejos e investimento
    yaxis: {
      labels: {
        formatter: function (val = 0) {
          // if (typeof val !== 'undefined') {
          return formataMoeda(val);
        },
      },
    },
  });

  const handleSeries = () => [
    totalRecebimentos,
    totalGastos,
    totalDesejos,
    totalInvestimentos,
  ];

  const handleTableDetalhes = () => (
    <SimpleBar style={{ height: "400px" }}>
      <table className="table table-centered mb-0">
        <thead className="thead-light">
          <tr>
            <th></th>
            <th>Lançamento</th>
            <th
              onClick={() => {
                setColumn("dataOperacao");
                setOrder(!order);
              }}
            >
              Data
              <span className="ml-2">
                {order && column === "dataOperacao" ? (
                  <i className="fas fa-sort-up"></i>
                ) : (
                  <i className="fas fa-sort-down"></i>
                )}
              </span>
            </th>
            <th
              onClick={() => {
                setColumn("valor");
                setOrder(!order);
              }}
            >
              Valor
              <span className="ml-2">
                {order && column === "valor" ? (
                  <i className="fas fa-sort-up"></i>
                ) : (
                  <i className="fas fa-sort-down"></i>
                )}
              </span>
            </th>
          </tr>
        </thead>
        <tbody>{renderRows(order, column)}</tbody>
      </table>
    </SimpleBar>
  );

  const renderRows = (order, column = "dataOperacao") => {
    const lancamentosFiltrados =
      itemGraficoClicado === -1
        ? lancamentos
        : lancamentos.filter(
            (item) => item.tipoLancamento.value === itemGraficoClicado
          );
    // console.log(tipoLancamentoClicado)
    // console.log(lancamentosFiltrados)
    return lancamentosFiltrados
      .sort((a, b) => {
        if (order) {
          return a[column] < b[column] ? -1 : a[column] > b[column] ? 1 : 0;
        } else {
          return b[column] < a[column] ? -1 : b[column] > a[column] ? 1 : 0;
        }
      })
      .map((lancamento, index) => (
        <tr key={index}>
          <td>
            <h5>
              <span
                className={`badge ${
                  lancamento.tipoLancamento.value === "gasto" ||
                  lancamento.tipoLancamento.value === "desejo"
                    ? "badge-secondary-lighten"
                    : "badge-primary-lighten"
                }`}
              >
                <If test={lancamento.tipoLancamento.value === "recebimento"}>
                  <i className="fas fa-dollar-sign px-1"></i>
                </If>
                <If test={lancamento.tipoLancamento.value === "gasto"}>
                  <i className="far fa-credit-card"></i>
                </If>
                <If test={lancamento.tipoLancamento.value === "desejo"}>
                  <i className="fas fa-glass-cheers"></i>
                </If>
                <If test={lancamento.tipoLancamento.value === "investimento"}>
                  <i className="fas fa-hand-holding-usd"></i>
                </If>
              </span>
            </h5>
          </td>
          <td>
            <span className="text-gray-800">{lancamento.nome}</span>
          </td>
          <td>{formataData(lancamento.dataOperacao)}</td>
          <td>{formataMoeda(lancamento.valor)}</td>
        </tr>
      ));
  };

  // if (loadingLancamentos) {
  // 	return <Spinner id='spinner-centro-sm' />;
  // }

  return (
    <>
      <Card classes="mb-4 tour-passo-grafico-recebimento-gastos">
        <CardHeader classes="d-flex flex-row align-items-center">
          <h6 className="m-0 font-weight-bold text-primary">
            Análise mensal por tipo de lançamento
          </h6>
          <Tooltip
            text={`O gráfico mostra a distribuição dos lançamentos de acordo com seu tipo, ou seja, recebimento, gasto essencial, gasto não essencial ou investimento. 
							Além disso, ao clicar em uma das fatias do gráfico, é exibido o detalhamento dos lançamentos correspondentes ao tipo que a fatia representa.`}
            icon="fas fa-1x fa-info-circle"
            trigger="hover"
          />
        </CardHeader>
        {/* <!-- Card Body --> */}
        <CardBody>
          <Row>
            <div className="mb-3 col-12">
              <HeaderBotoesMeses
                functionName={setDataContextLancamentos}
                dataRedux={dataContextLancamentos}
              />
            </div>
          </Row>
          {loadingLancamentos ? (
            <Spinner id="spinner-centro-sm" />
          ) : lancamentos !== null && lancamentos.length > 0 ? (
            <Row>
              <div
                id="lancamentos-pizza-chart"
                className="apex-charts col-lg-6 col-md-12"
              >
                <ReactApexChart
                  options={options}
                  series={handleSeries()}
                  type="pie"
                  width="450"
                />
              </div>

              <div className=" col-lg-6 col-md-12">{handleTableDetalhes()}</div>
            </Row>
          ) : (
            <NaoHaLancamento height="45vh" />
          )}
        </CardBody>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  dataContextLancamentos: state.arrumaCasaStore.dataContextLancamentos,
  loadingLancamentos: state.arrumaCasaStore.loadingLancamentos,
  lancamentos: state.arrumaCasaStore.lancamentos,
});

export default connect(mapStateToProps, {
  getMinhasContasPeriodo,
  setLoadingLancamentos,
  setErroAutenticacao,
  setDataContextLancamentos
})(GraficoPizzaTipoLancamento);
