export const listaCorretora = [
	{ cnpj: '', nome: 'Selecione uma corretora', codigo: '' },
	{ cnpj: '00000000000191', nome: 'BANCO DO BRASIL S/A', codigo: '254' },
	{ cnpj: '00360305000104', nome: 'CAIXA ECONOMICA FEDERAL', codigo: '570' },
	{
		cnpj: '00806535000154',
		nome: 'PLANNER CORRETORA DE VALORES S/A',
		codigo: '129'
	},
	{ cnpj: '00997185000150', nome: 'BANCO B3 S.A.', codigo: '1829' },
	{ cnpj: '00997804000107', nome: 'OURINVEST DTVM S.A.', codigo: '1106' },
	{
		cnpj: '01522368000182',
		nome: 'BANCO BNP PARIBAS BRASIL S/A',
		codigo: '251'
	},
	{ cnpj: '01788147000150', nome: 'H.COMMCOR DTVM LTDA', codigo: '115' },
	{
		cnpj: '02038232000164',
		nome: 'BANCO COOPERATIVO SICOOB S.A. - BANCO SICOOB',
		codigo: '1953'
	},
	{ cnpj: '02332886000104', nome: 'XP INVESTIMENTOS CCTVM S/A', codigo: '3' },
	{ cnpj: '02332886001178', nome: 'CLEAR CORRETORA - GRUPO XP', codigo: '308' },
	{
		cnpj: '02332886001682',
		nome: 'RICO INVESTIMENTOS - GRUPO XP',
		codigo: '386'
	},
	{ cnpj: '02670590000195', nome: 'MERRILL LYNCH S/A CTVM', codigo: '13' },
	{
		cnpj: '02685483000130',
		nome: 'CM CAPITAL MARKETS CCTVM LTDA',
		codigo: '88'
	},
	{ cnpj: '02819125000173', nome: 'UBS BRASIL CCTVM S/A', codigo: '8' },
	{
		cnpj: '03384738000198',
		nome: 'VOTORANTIM ASSET MANAGEMENT DTVM LTDA',
		codigo: '21'
	},
	{
		cnpj: '03502968000104',
		nome: 'PI DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS S.A.',
		codigo: '3142'
	},
	{ cnpj: '03532415000102', nome: 'BANCO ABN AMRO S.A.', codigo: '2786' },
	{
		cnpj: '03751794000113',
		nome: 'TERRA INVESTIMENTOS DTVM LTDA',
		codigo: '107'
	},
	{ cnpj: '04257795000179', nome: 'NOVA FUTURA CTVM LTDA', codigo: '93' },
	{ cnpj: '04323351000194', nome: 'MORGAN STANLEY CTVM S/A', codigo: '40' },
	{ cnpj: '05389174000101', nome: 'MODAL DTVM LTDA', codigo: '1982' },
	{
		cnpj: '05816451000115',
		nome: 'GENIAL INSTITUCIONAL CCTVM S/A',
		codigo: '120'
	},
	{ cnpj: '09105360000122', nome: 'ICAP DO BRASIL CTVM LTDA', codigo: '735' },
	{
		cnpj: '09346601000125',
		nome: 'B3 S.A. – BRASIL  BOLSA  BALCÃO',
		codigo: '220'
	},
	{
		cnpj: '09512542000118',
		nome: 'CODEPE CORRETORA DE VALORES E CÂMBIO S/A',
		codigo: '234'
	},
	{
		cnpj: '09605581000160',
		nome: 'GOLDMAN SACHS DO BRASIL CTVM SA',
		codigo: '238'
	},
	{ cnpj: '11758741000152', nome: 'BANCO FINAXIS S.A.', codigo: '5247' },
	{
		cnpj: '12392983000138',
		nome: 'MIRAE ASSET WEALTH MANAGEMENT (BRASIL) CCTVM LTDA.',
		codigo: '262'
	},
	{
		cnpj: '13220493000117',
		nome: 'BR PARTNERS BANCO DE INVESTIMENTO S/A',
		codigo: '476'
	},
	{ cnpj: '13293225000125', nome: 'ORAMA DTVM S A', codigo: '3701' },
	{ cnpj: '13486793000142', nome: 'BRL TRUST DTVM S.A', codigo: '1064' },
	{ cnpj: '13673855000125', nome: 'FRAM CAPITAL DTVM SA', codigo: '4011' },
	{ cnpj: '14388516000160', nome: 'VIC DTVM S/A', codigo: '1445' },
	{ cnpj: '15114366000169', nome: 'BANCO BOCOM BBM SA', codigo: '269' },
	{ cnpj: '15213150000150', nome: 'BOCOM BBM CCVM S/A', codigo: '18' },
	{
		cnpj: '16683062000185',
		nome: 'MERCANTIL DO BRASIL C. S/A CTVM',
		codigo: '106'
	},
	{ cnpj: '17312661000155', nome: 'AMARIL FRANKLIN CTV LTDA', codigo: '226' },
	{ cnpj: '17315359000150', nome: 'SITA SOCIEDADE CCVM S/A', codigo: '187' },
	{ cnpj: '17352220000187', nome: 'SENSO C.C.V.M. S/A', codigo: '191' },
	{ cnpj: '18684408000195', nome: 'AZIMUT BRASIL DTVM LTDA', codigo: '741' },
	{ cnpj: '18945670000146', nome: 'INTER DTVM LTDA', codigo: '1099' },
	{ cnpj: '22610500000188', nome: 'VORTX DTVM LTDA', codigo: '3868' },
	{
		cnpj: '24933830000130',
		nome: 'BB BANCO DE INVESTIMENTO S/A',
		codigo: '820'
	},
	{
		cnpj: '27652684000162',
		nome: 'GENIAL INVESTIMENTOS CORRETORA DE VALORES MOBILIÁRIOS S.A.',
		codigo: '173'
	},
	{ cnpj: '27937333000106', nome: 'BANCO BRJ S/A', codigo: '1423' },
	{ cnpj: '28048783000100', nome: 'ELITE CCVM LTDA', codigo: '174' },
	{ cnpj: '28156214000170', nome: 'UNILETRA CCTVM LTDA.', codigo: '29' },
	{ cnpj: '28195667000106', nome: 'BANCO ABC BRASIL S/A', codigo: '661' },
	{ cnpj: '28650236000192', nome: 'BS2 DTVM S.A.', codigo: '4015' },
	{ cnpj: '29162769000198', nome: 'TORO CTVM LTDA', codigo: '4090' },
	{ cnpj: '30306294000145', nome: 'BANCO BTG PACTUAL S/A', codigo: '1026' },
	{ cnpj: '30723886000162', nome: 'BANCO MODAL S/A', codigo: '683' },
	{ cnpj: '31597552000152', nome: 'BANCO CLASSICO S/A', codigo: '1234' },
	{ cnpj: '31749596000150', nome: 'IDEAL CTVM S.A.', codigo: '1618' },
	{ cnpj: '31872495000172', nome: 'BANCO C6 S.A.', codigo: '6028' },
	{
		cnpj: '32345784000186',
		nome: 'C6 CORRETORA DE TITULOS E VALORES MOBILIARIOS LTDA',
		codigo: '6003'
	},
	{ cnpj: '32588139000194', nome: 'J.P. MORGAN CCVM S/A', codigo: '16' },
	{ cnpj: '33172537000198', nome: 'BANCO J.P.MORGAN S/A', codigo: '206' },
	{ cnpj: '33311713000125', nome: 'ITAU DTVM S/A', codigo: '225' },
	{
		cnpj: '33466988000138',
		nome: 'BANCO CAIXA GERAL - BRASIL S/A',
		codigo: '1309'
	},
	{ cnpj: '33479023000180', nome: 'BANCO CITIBANK S/A', codigo: '247' },
	{ cnpj: '33644196000106', nome: 'BANCO FATOR S/A', codigo: '3131' },
	{
		cnpj: '33657248000189',
		nome: 'BANCO NAC DE DESN ECO E SOCIAL - BNDES',
		codigo: '302'
	},
	{
		cnpj: '33709114000164',
		nome: 'CITIGROUP GLOBAL MARKETS BRASIL CCTVM S/A',
		codigo: '77'
	},
	{
		cnpj: '33775974000104',
		nome: 'ATIVA INVESTIMENTOS S/A CTCV',
		codigo: '147'
	},
	{ cnpj: '33850686000169', nome: 'BRB DTVM S/A', codigo: '672' },
	{ cnpj: '33851205000130', nome: 'J.P. MORGAN S/A DTVM', codigo: '3618' },
	{ cnpj: '33862244000132', nome: 'BGC LIQUIDEZ DTVM LTDA', codigo: '122' },
	{ cnpj: '33868597000140', nome: 'CITIBANK DTVM S/A', codigo: '298' },
	{ cnpj: '33886862000112', nome: 'MAXIMA S/A CCTVM', codigo: '83' },
	{
		cnpj: '33918160000173',
		nome: 'GRADUAL CORRET DE CAMBIO TIT E VALS MOB SA',
		codigo: '227'
	},
	{
		cnpj: '33987793000133',
		nome: 'BANCO DE INVEST. CREDIT SUISSE (BRASIL) S.A.',
		codigo: '833'
	},
	{ cnpj: '34711571000156', nome: 'VITREO DTVM S.A.', codigo: '1855' },
	{ cnpj: '42272526000170', nome: 'BNY MELLON BANCO S/A', codigo: '1737' },
	{
		cnpj: '42584318000107',
		nome: 'CREDIT SUISSE (BRASIL) S.A. CTVM',
		codigo: '45'
	},
	{ cnpj: '43060029000171', nome: 'NOVINVEST CVM LTDA', codigo: '63' },
	{ cnpj: '43815158000122', nome: 'BTG PACTUAL CTVM S/A', codigo: '85' },
	{ cnpj: '44077014000189', nome: 'BR-CAPITAL DTVM S/A', codigo: '2599' },
	{
		cnpj: '45246410000155',
		nome: 'BRASIL PLURAL S/A BANCO MÚLTIPLO',
		codigo: '1401'
	},
	{
		cnpj: '48795256000169',
		nome: 'BANCO ANDBANK (BRASIL) S.A.',
		codigo: '4002'
	},
	{ cnpj: '50657675000186', nome: 'SLW CVC LTDA', codigo: '110' },
	{ cnpj: '51014223000149', nome: 'SANTANDER CCVM S/A', codigo: '27' },
	{
		cnpj: '52904364000108',
		nome: 'NECTON INVESTIMENTOS S.A. CVMC',
		codigo: '23'
	},
	{
		cnpj: '54641030000378',
		nome: 'SSM - SISTEMA DE SIMULACAO DE MARGENS',
		codigo: '990'
	},
	{ cnpj: '58160789000128', nome: 'BANCO SAFRA S/A', codigo: '304' },
	{ cnpj: '58616418000108', nome: 'BANCO FIBRA S/A', codigo: '1173' },
	{ cnpj: '59588111000103', nome: 'BANCO VOTORANTIM S/A', codigo: '1110' },
	{ cnpj: '60701190000104', nome: 'ITAU UNIBANCO S/A', codigo: '1349' },
	{ cnpj: '60746948000112', nome: 'BANCO BRADESCO S/A', codigo: '497' },
	{
		cnpj: '60783503000102',
		nome: 'SAFRA CORRETORA DE VALORES E CAMBIO LTDA',
		codigo: '59'
	},
	{ cnpj: '60889128000180', nome: 'BANCO SOFISA S/A', codigo: '1093' },
	{ cnpj: '61024352000171', nome: 'BANCO INDUSVAL S/A', codigo: '795' },
	{ cnpj: '61194353000164', nome: 'ITAU CV S/A', codigo: '114' },
	{ cnpj: '61723847000199', nome: 'MAGLIANO S/A CCVM', codigo: '1' },
	{
		cnpj: '61747085000160',
		nome: 'TULLETT PREBON BRASIL CVC LTDA.',
		codigo: '127'
	},
	{ cnpj: '61820817000109', nome: 'BANCO PAULISTA S/A', codigo: '680' },
	{ cnpj: '61855045000132', nome: 'BRADESCO S/A CTVM', codigo: '72' },
	{
		cnpj: '61973863000130',
		nome: 'LEROSA S.A. CORRETORA DE VALORES E CAMBIO',
		codigo: '33'
	},
	{
		cnpj: '62073200000121',
		nome: 'BANK OF AMERICA MERRILL LYNCH BANCO MULTIPLO',
		codigo: '1817'
	},
	{ cnpj: '62090873000190', nome: 'INTL FCSTONE DTVM LTDA', codigo: '1130' },
	{ cnpj: '62169875000179', nome: 'EASYNVEST - TITULO CV S/A', codigo: '90' },
	{ cnpj: '62178421000164', nome: 'ALFA CCVM S/A', codigo: '4' },
	{ cnpj: '62232889000190', nome: 'BANCO DAYCOVAL S/A', codigo: '359' },
	{
		cnpj: '62285390000140',
		nome: 'SINGULARE CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
		codigo: '58'
	},
	{ cnpj: '62287735000103', nome: 'RENASCENCA DTVM LTDA', codigo: '92' },
	{
		cnpj: '62318407000119',
		nome: 'SANTANDER CACEIS BRASIL DTVM S.A.',
		codigo: '2570'
	},
	{
		cnpj: '62331228000111',
		nome: 'DEUTSCHE BANK S/A - BANCO ALEMAO',
		codigo: '659'
	},
	{
		cnpj: '63062749000183',
		nome: 'FATOR S/A - CORRETORA DE VALORES',
		codigo: '131'
	},
	{
		cnpj: '65913436000117',
		nome: 'GUIDE INVESTIMENTOS SA CORRETORA DE VALORES',
		codigo: '15'
	},
	{
		cnpj: '68757681000170',
		nome: 'SIM PAUL CORRETORA DE CAMBIO E VALORES MOBILIARIOS S.A',
		codigo: '177'
	},
	{ cnpj: '74014747000135', nome: 'AGORA CTVM S/A', codigo: '39' },
	{
		cnpj: '75647891000171',
		nome: 'BANCO CREDIT AGRICOLE BRASIL S.A.',
		codigo: '2205'
	},
	{ cnpj: '78632767000120', nome: 'BANCO OURINVEST S/A', codigo: '442' },
	{
		cnpj: '89960090000176',
		nome: 'RB CAPITAL INVESTIMENTOS DTVM LTDA',
		codigo: '1089'
	},
	{
		cnpj: '90400888000142',
		nome: 'BANCO SANTANDER (BRASIL) S.A.',
		codigo: '622'
	},
	{
		cnpj: '92702067000196',
		nome: 'BANCO DO ESTADO DO RIO GRANDE DO SUL S/A',
		codigo: '3601'
	},
	{
		cnpj: '92858380000118',
		nome: 'CORRETORA GERAL DE VALORES E CAMBIO LTDA',
		codigo: '186'
	},
	{ cnpj: '92875780000131', nome: 'WARREN CVMC LTDA', codigo: '190' },
	{ cnpj: '92894922000108', nome: 'BANCO ORIGINAL S/A', codigo: '1730' },
	{
		cnpj: '93026847000126',
		nome: 'BANRISUL S/A CORRETORA DE VAL MOB E CAMBIO',
		codigo: '172'
	}
];


// { codigo: 1, nome: 'MAGLIANO S.A. CCVM' },
// { codigo: 3, nome: 'XP INVESTIMENTOS CCTVM S/A' },
// { codigo: 4, nome: 'ALFA CCVM S.A.' },
// { codigo: 8, nome: 'UBS BRASIL CCTVM S/A' },
// { codigo: 13, nome: 'MERRILL LYNCH S/A CTVM' },
// { codigo: 15, nome: 'GUIDE INVESTIMENTOS S.A. CV' },
// { codigo: 16, nome: 'J. P. MORGAN CCVM S.A.' },
// { codigo: 18, nome: 'BOCOM BBM CCVM S/A' },
// { codigo: 21, nome: 'VOTORANTIM ASSET MANAG. DTVM' },
// { codigo: 23, nome: 'NECTON INVESTIMENTOS S.A. CVMC' },
// { codigo: 27, nome: 'SANTANDER CCVM S/A' },
// { codigo: 29, nome: 'UNILETRA CCTVM S.A.' },
// { codigo: 33, nome: 'LEROSA S.A. CVC' },
// { codigo: 37, nome: 'UM INVESTIMENTOS S.A. CTVM' },
// { codigo: 39, nome: 'AGORA CTVM S/A' },
// { codigo: 40, nome: 'MORGAN STANLEY CTVM S/A' },
// { codigo: 41, nome: 'ING CCT S/A' },
// { codigo: 45, nome: 'CREDIT SUISSE BRASIL S.A. CTVM' },
// { codigo: 58, nome: 'SOCOPA SC PAULISTA S.A.' },
// { codigo: 59, nome: 'SAFRA CVC LTDA.' },
// { codigo: 63, nome: 'NOVINVEST CVM LTDA.' },
// { codigo: 72, nome: 'BRADESCO S/A CTVM' },
// { codigo: 74, nome: 'COINVALORES CCVM LTDA.' },
// { codigo: 77, nome: 'CITIGROUP GMB CCTVM S.A.' },
// { codigo: 83, nome: 'MAXIMA S/A CTVM' },
// { codigo: 85, nome: 'BTG PACTUAL CTVM S.A.' },
// { codigo: 88, nome: 'CM CAPITAL MARKETS CCTVM LTDA' },
// { codigo: 90, nome: 'EASYNVEST – TITULO CV S.A.' },
// { codigo: 92, nome: 'RENASCENCA DTVM LTDA.' },
// { codigo: 93, nome: 'NOVA FUTURA CTVM LTDA' },
// { codigo: 106, nome: 'MERC. DO BRASIL COR. S.A. CTVM' },
// { codigo: 107, nome: 'TERRA INVESTIMENTOS DTVM LTDA' },
// { codigo: 110, nome: 'SLW CVC LTDA.' },
// { codigo: 114, nome: 'ITAU CV S/A' },
// { codigo: 115, nome: 'H.COMMCOR DTVM LTDA' },
// { codigo: 120, nome: 'GENIAL INSTITUCIONAL CCTVM S.A' },
// { codigo: 122, nome: 'BGC LIQUIDEZ DTVM' },
// { codigo: 127, nome: 'TULLETT PREBON' },
// { codigo: 129, nome: 'PLANNER CV S.A' },
// { codigo: 131, nome: 'FATOR S.A. CV' },
// { codigo: 133, nome: 'DIBRAN DTVM LTDA' },
// { codigo: 147, nome: 'ATIVA INVESTIMENTOS S.A. CTCV' },
// { codigo: 172, nome: 'BANRISUL S/A CVMC' },
// { codigo: 173, nome: 'GENIAL INVESTIMENTOS CVM S.A.' },
// { codigo: 174, nome: 'ELITE CCVM LTDA.' },
// { codigo: 177, nome: 'SOLIDUS S/A CCVM' },
// { codigo: 181, nome: 'MUNDINVEST S.A. CCVM' },
// { codigo: 186, nome: 'CORRETORA GERAL DE VC LTDA' },
// { codigo: 187, nome: 'SITA SCCVM S.A.' },
// { codigo: 191, nome: 'SENSO CCVM S.A.' },
// { codigo: 226, nome: 'AMARIL FRANKLIN CTV LTDA.' },
// { codigo: 234, nome: 'CODEPE CV E CAMBIO S/A' },
// { codigo: 238, nome: 'GOLDMAN SACHS DO BRASIL CTVM' },
// { codigo: 251, nome: 'BANCO BNP PARIBAS BRASIL S/A' },
// { codigo: 262, nome: 'MIRAE ASSET WEALTH MANAGEMENT' },
// { codigo: 308, nome: 'CLEAR CORRETORA – Grupo XP' },
// { codigo: 359, nome: 'BANCO DAYCOVAL' },
// { codigo: 386, nome: 'RICO INVESTIMENTOS – Grupo XP' },
// { codigo: 442, nome: 'BANCO OURINVEST' },
// { codigo: 683, nome: 'BANCO MODAL' },
// { codigo: 711, nome: 'DILLON S.A. DTVM' },
// { codigo: 713, nome: 'BB GESTAO DE RECURSOS DTVM S/A' },
// { codigo: 735, nome: 'ICAP DO BRASIL CTVM LTDA' },
// { codigo: 820, nome: 'BB BANCO DE INVESTIMENTO S/A' },
// { codigo: 979, nome: 'ADVALOR DTVM LTDA' },
// { codigo: 1089, nome: 'RB CAPITAL INVESTIMENTOS DTVM' },
// { codigo: 1099, nome: 'INTER DTVM LTDA' },
// { codigo: 1106, nome: 'Ourinvest DTVM S.A.' },
// { codigo: 1116, nome: 'BANCO CITIBANK' },
// { codigo: 1130, nome: 'INTL FCStone DTVM Ltda.' },
// { codigo: 1570, nome: 'CAIXA ECONOMICA FEDERAL' },
// { codigo: 1618, nome: 'IDEAL CTVM SA' },
// { codigo: 1982, nome: 'MODAL DTVM LTDA' },
// { codigo: 2197, nome: 'BCO FIBRA' },
// { codigo: 2379, nome: 'ORLA DTVM S/A' },
// { codigo: 2492, nome: 'POSITIVA CTVM S/A' },
// { codigo: 2570, nome: 'SANTANDER SECURITIES SERVICES' },
// { codigo: 2640, nome: 'LLA DTVM LTDA' },
// { codigo: 3112, nome: 'BANESTES DTVM S/A' },
// { codigo: 3371, nome: 'RIO BRAVO INVEST S.A. DTVM' },
// { codigo: 3701, nome: 'ORAMA DTVM S.A.' },
// { codigo: 3762, nome: 'RJI CTVM LTDA' },
// { codigo: 4002, nome: 'BANCO ANDBANK (BRASIL) S.A.' },
// { codigo: 4015, nome: 'BS2 DTVM S/A' },
// { codigo: 4090, nome: 'TORO CTVM LTDA.' },
// { codigo: 6003, nome: 'C6 CTVM LTDA' }