//Usado no LoginAction e LoginReducer
export const CARREGA_USUARIO = 'CARREGA_USUARIO';
export const CARREGA_USUARIO_FALHA = 'CARREGA_USUARIO_FALHA';
export const LOGIN_SUCESSO = 'LOGIN_SUCESSO';
export const LOGIN_FALHA = 'LOGIN_FALHA';
export const LOGOUT_SUCESSO = 'LOGOUT_SUCESSO';
export const LOGOUT_FALHA = 'LOGOUT_FALHA';
export const REENVIAR_CODIGO_SUCESSO = 'REENVIAR_CODIGO_SUCESSO';
export const REENVIAR_CODIGO_FALHA = 'REENVIAR_CODIGO_FALHA';
export const ESQUECI_SENHA_SUCESSO = 'ESQUECI_SENHA_SUCESSO';
export const ESQUECI_SENHA_FALHA = 'ESQUECI_SENHA_FALHA';
export const SET_GUIDED_TOUR_SUCESSO = 'SET_GUIDED_TOUR_SUCESSO';
export const SET_GUIDED_TOUR_FALHA = 'SET_GUIDED_TOUR_FALHA';
export const CONFIRMA_CODIGO_SUCESSO = 'CONFIRMA_CODIGO_SUCESSO';
export const CONFIRMA_CODIGO_FALHA = 'CONFIRMA_CODIGO_FALHA';
export const CRIA_CONTA_SUCESSO = 'CRIA_CONTA_SUCESSO';
export const CRIA_CONTA_FALHA = 'CRIA_CONTA_FALHA';
export const SET_LOADING = 'SET_LOADING';
export const SET_ERRO_AUTENTICACAO = 'SET_ERRO_AUTENTICACAO';
export const UPDATE_USER_ATTRIBUTE = 'UPDATE_USER_ATTRIBUTE';
export const ERROR_USER_UPDATE = 'ERROR_USER_UPDATE';