import React from 'react';

import Title from '../../../components/common/Title';
import { callAlert } from '../../../../config/helper/utils';
const MinhasAnalises = (props) => {
	return (
		<>
			<Title text='Minhas análises' textTooltip='TimeLine de investimentos?'/>
			{callAlert(props)}
		</>
	);
};

export default MinhasAnalises;
