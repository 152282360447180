import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import moment from 'moment';
import { submit, setLancamentoCurrent } from './action/ArrumandoCasaAction';
import { getMeusObjetivos } from '../fazendoCaixa/action/FazendoCaixaAction';
import CustomTextInput from '../../../components/form/CustomTextInput';
import Button from '../../../components/form/Button';
import DatePicker from '../../../components/form/DatePicker';
import CurrencyMaskedTextInput from '../../../components/form/CurrencyMaskedTextInput';
import CustomSelectOptionsInput from '../../../components/form/CustomSelectOptionsInput';
import CustomSwitchInput from '../../../components/form/CustomSwitchInput';
import {
	success,
	error,
	MSG_TOAST_ERRO_DE_NOVO
} from '../../../components/toast/action/ToastAction';
import Row from '../../../components/common/Row';
import If from '../../../components/common/If';
import { listaTipoLancamentos } from '../../../../config/helper/listaTipoLancamentos';
import { setErroAutenticacao } from '../../../minimal/autenticacao/action/AuthAction';

const ModalAddUpdateLancamento = (props) => {
	const {
		dataContextLancamentos,
		showModalLancamentos,
		lancamento,
		setLancamentoCurrent,
		success,
		error,
		submit,
		erroCountLancamentos,
		optsObjetivos,
		objetivos,
		getMeusObjetivos,
		setErroAutenticacao
	} = props;

	const [optsTipoLancamento] = useState(listaTipoLancamentos);

	const [INITIAL_VALUES] = useState({
		nome: '',
		descricao: '',
		dataOperacao: '',
		valor: 0,
		investimento: false,
		tipoLancamento: optsTipoLancamento[0],
		objetivo: '',
		recorrente: false
	});

	const validationSchema = Yup.object({
		nome: Yup.string().required('O nome é obrigatório'),
		valor: Yup.string()
			.test('Valor válido', 'O valor deve ser maior que ZERO', (val) => {
				if (val > 0) {
					return true;
				} else {
					return false;
				}
			})
			.required('O Valor é obrigatório'),
		dataOperacao: Yup.string().required('A Data é obrigatória'),
		tipoLancamento: Yup.object()
			.test('Tipo Value', 'O tipo do lançamento é obrigatório', (val) => {
				if (typeof val.value === 'undefined') {
					return false;
				} else {
					return true;
				}
			})
			.required('O tipo do lançamento é obrigatório')
	});

	const onSubmit = (values, { setSubmitting, resetForm }) => {
		const valorAnteriorLancamento = lancamento ? lancamento.valor : 0;
		console.log("values form: ", JSON.stringify(values));
		console.log("valorAnteriorLancamento: ", valorAnteriorLancamento);
		submit(values, valorAnteriorLancamento)
			.then(() => {
				cleanUp(resetForm);
				success();
			})
			.catch((err) => {
				if (err.code === 'NotAuthorizedException') {
					setErroAutenticacao(err);
				} else {
					if (erroCountLancamentos >= 1) {
						error(MSG_TOAST_ERRO_DE_NOVO);
					} else {
						error();
					}
				}
				setSubmitting(false);
			});
	};

	useEffect(() => {
		getMeusObjetivos().then(() => {
		}).catch((err) => {
			if (err.code === 'NotAuthorizedException') {
				setErroAutenticacao(err);
			}
		});
		// eslint-disable-next-line
	}, [lancamento]);

	const cleanUp = (resetForm) => {
		setLancamentoCurrent(null, false);
		resetForm({});
	};

	return (
		<Formik
			enableReinitialize
			initialValues={lancamento ? lancamento : INITIAL_VALUES}
			validationSchema={validationSchema}
			onSubmit={onSubmit}
		>
			{({ values, isSubmitting, resetForm }) => (
				<Modal
					open={showModalLancamentos}
					onClose={() => {
						cleanUp(resetForm);
					}}
					center
					classNames={{
						modal: 'modal-width'
					}}
				>
					<Row classes='my-3 modal-header'>
						<div className='col-12 text-center'>
							<h3>
								{!lancamento
									? `Novo`
									: typeof lancamento.sk === undefined
									? `Novo`
									: `Alterar`}{' '}
								lançamento
							</h3>
						</div>
					</Row>
					<Form className='form-be-custom'>
						<Row classes='form-group'>
							<div className='col-12'>
								<CustomSelectOptionsInput
									name='tipoLancamento'
									className=' custom-form-control-retangular '
									options={optsTipoLancamento}
									placeholder={optsTipoLancamento[0].label}
									value={values.tipoLancamento}
									onClick={() => {}}
								/>
							</div>
							{/* <div className='col-sm-6'>
								<If test={values.tipoLancamento.value === 'gasto'}>
									<label>
										<span className='mr-2'>
											Me marque se este lançamento for um depósito para um
											objetivo financeiro
										</span>
										<CustomSwitchInput name='investimento' />
									</label>
								</If>
							</div> */}
						</Row>
						<Row classes='form-group'>
							<If test={values.tipoLancamento.value === 'investimento'}>
								<If test={objetivos !== null && objetivos.length > 0}>
									<div className='col-12'>Meus Objetivos</div>
									<div className='col-12'>
										<CustomSelectOptionsInput
											name='objetivo'
											className='custom-form-control-retangular'
											options={optsObjetivos}
											placeholder={
												optsObjetivos.length > 0 && optsObjetivos[0].label
											}
											value={values.objetivo}
										/>
									</div>
								</If>
								<If test={objetivos === null || objetivos.length === 0}>
									<div className='col-12'>
										Você ainda não criou objetivos! Quer criar seu primeiro
										objetivo de vida agora?
									</div>
									<div className='col-12'>
										<Link
											to='/dashboard/meus-objetivos'
											onClick={() => setLancamentoCurrent(null, false)}
										>
											Clique aqui e crie seu primeiro objetivo.
										</Link>
									</div>
								</If>
							</If>
						</Row>
						<Row classes='form-group'>
							<div className='col-sm-6 mb-3 mb-sm-0'>
								<label>Nome do lançamento</label>
								<CustomTextInput
									name='nome'
									type='text'
									placeholder='Ex.: Salário'
									className='custom-form-control-retangular'
								/>
							</div>
							<div className='col-sm-6'>
								<label>Descrição do lançamento</label>
								<CustomTextInput
									name='descricao'
									type='text'
									placeholder='Ex.: Recebimento do salário da empresa Be.'
									className='custom-form-control-retangular'
									size='100'
								/>
							</div>
						</Row>
						<Row classes='form-group'>
							<div className='col-sm-6 mb-3 mb-sm-0'>
								<label>Valor do lançamento</label>
								<CurrencyMaskedTextInput
									name='valor'
									type='text'
									className='custom-form-control-retangular'
								/>
							</div>
							<div className='col-sm-6'>
								<label>Data do lançamento</label>
								<DatePicker
									name='dataOperacao'
									type='text'
									placeholder='DD/MM/AAAA'
									className='custom-form-control-retangular'
									size='100'
									dataContext={
										values.dataOperacao
											? moment(values.dataOperacao, 'DD/MM/YYYY').utc()
											: dataContextLancamentos
									}
									value={
										values.dataOperacao &&
										moment(values.dataOperacao, 'DD/MM/YYYY').utc()
									}
								/>
							</div>
						</Row>
						<If test={!values.sk}>
							<Row classes='form-group'>
								<div className='col-sm-10 mb-3 mb-sm-0'>
									<span>
										{`Deseja cadastrar este lançamento para os próximos 12 (doze)
										meses, ou seja, até ${moment(dataContextLancamentos)
											.add(11, 'month')
											.format('DD/MM/YYYY')}?`}
									</span>
								</div>
								<div className='col-sm-2'>
									<CustomSwitchInput name='recorrente' />
								</div>
							</Row>
						</If>
						<Row>
							<div className='col-12 text-right modal-footer'>
								<Button
									classes='btn btn-secondary mr-3'
									texto='Cancelar'
									disabled={false}
									onClick={() => {
										cleanUp(resetForm);
									}}
								/>
								<Button
									classes='btn btn-primary'
									texto='Salvar'
									disabled={isSubmitting}
									id='btn-salvar'
								/>
							</div>
						</Row>
					</Form>
				</Modal>
			)}
		</Formik>
	);
};

const mapStateToProps = (state) => ({
	dataContextLancamentos: state.arrumaCasaStore.dataContextLancamentos,
	showModalLancamentos: state.arrumaCasaStore.showModalLancamentos,
	lancamento: state.arrumaCasaStore.lancamento,
	erroCountLancamentos: state.arrumaCasaStore.erroCountLancamentos,
	optsObjetivos: state.fazendoCaixaStore.optsObjetivos,
	objetivos: state.fazendoCaixaStore.objetivos
});

export default connect(mapStateToProps, {
	submit,
	setLancamentoCurrent,
	success,
	error,
	getMeusObjetivos,
	setErroAutenticacao
})(ModalAddUpdateLancamento);
