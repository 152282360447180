import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { Link } from 'react-router-dom';
import LogoBeganinha from '../../components/common/SVGIcon';
import { logout } from '../../../layouts/minimal/autenticacao/action/AuthAction';

const NavBar = (props) => {
	const { usuario, logout } = props;

	const [navBarClass, setNavBarClass] = useState('');
	// const [ navBarClassToggler, setNavBarClassToggler ] = useState('');
	const [dropmenuClass, setDropmenuClass] = useState('');
	const [navBarLinkClass, setNavBarLinkClass] = useState('');
	const [hamburguerClass, setHamburguerClass] = useState('');
	const [nomelogoBe, setNomelogoBe] = useState('be_branco');
	const [show, setShow] = useState('');
	// const [ height, setHeight ] = useState('');
	// const [ width ] = useState(window.innerWidth);

	useScrollPosition(
		({ currPos }) => {
			// setHeight(currPos.y);
			if (currPos.y < -400) {
				setNavBarClass('solid');
				setDropmenuClass('solid');
				setNavBarLinkClass('text-primary');
				setHamburguerClass('custom-toggler-icon-primary');
				setNomelogoBe('be_verde_claro');
			} else {
				// parte superior
				setDropmenuClass('');
				setNavBarLinkClass('');
				setHamburguerClass('');
				setNomelogoBe('be_branco');
				setNavBarClass('');
			}
		},
		[navBarClass, dropmenuClass, navBarLinkClass, hamburguerClass, nomelogoBe]
	);

	const handleClick = () => {
		if (navBarClass !== 'solid') {
			setNavBarClass('solid-toggle');
		}
		setShow('');
	};

	return (
		<nav className={`navbar navbar-expand-lg fixed-top ${navBarClass}`}>
			<div className='container'>
				<a className='navbar-brand' href='#home'>
					{/* O logo é alterado de branco para verde claro via Jquery (plugins.js) */}
					<LogoBeganinha
						id='logo_be_nav_bar'
						name={nomelogoBe}
						classNameCustom='logo-be'
					/>
				</a>
				{/* <Link className='navbar-brand' to='/home'>
					<LogoBeganinha name='be_branco' classNameCustom='logo-be' />
				</Link> */}
				{/* <Link className="navbar-brand" to="/home"><strong>Be</strong>ganinha</Link> */}
				<button
					className='navbar-toggler'
					type='button'
					data-toggle='collapse'
					data-target='#nav-home'
					onClick={() => handleClick()}
				>
					<span
						className={`custom-toggler-icon ${hamburguerClass}`}
						id='hamburger'
					>
						<i className='fas fa-bars'></i>
					</span>
				</button>

				<div className={`navbar-collapse collapse ${show}`} id='nav-home'>
					<ul className='navbar-nav ml-auto'>
						<li className='nav-item'>
							<a
								className={`nav-link text-white ${navBarLinkClass}`}
								href='#home'
								onClick={() => setShow('hide')}
							>
								Home
							</a>
						</li>
						<li className='nav-item'>
							<a
								className={`nav-link text-white ${navBarLinkClass}`}
								href='#vamos-comecar'
								onClick={() => setShow('hide')}
							>
								Vamos Começar?
							</a>
						</li>
						<li className='nav-item'>
							<a
								className={`nav-link text-white ${navBarLinkClass}`}
								href='#servicos'
								onClick={() => setShow('hide')}
							>
								Serviços
							</a>
						</li>
						<li className='nav-item'>
							<a
								className={`nav-link text-white ${navBarLinkClass}`}
								href='#como-funciona'
								onClick={() => setShow('hide')}
							>
								Como Funciona
							</a>
						</li>
						<li className='nav-item'>
							<a
								className={`nav-link text-white ${navBarLinkClass}`}
								href='#fale-conosco'
								onClick={() => setShow('hide')}
							>
								Fale Conosco
							</a>
						</li>
						{usuario === null || typeof usuario.attributes === "undefined" ? (
							<li className='nav-item'>
								<Link
									className={`nav-link text-white ${navBarLinkClass}`}
									to='/login'
									onClick={() => setShow('hide')}
								>
									Login
								</Link>
							</li>
						) : usuario !== null && usuario.attributes !== null && typeof usuario.attributes !== "undefined" && (
							<li className='nav-item dropdown'>
								<a
									className={`nav-link text-white dropdown-toggle text-capitalize ${navBarLinkClass}`}
									data-toggle='dropdown'
									href='usuario'
									id='dropdown1'
									aria-haspopup='true'
									aria-expanded='false'
								>
									Olá {usuario.attributes.name}
								</a>
								<ul
									className={`first dropdown-menu ${dropmenuClass}`}
									aria-labelledby='dropdown1'
								>
									<li>
										<Link className='dropdown-item' to='/dashboard'>
											Dashboard
										</Link>
									</li>
									<li>
										<Link className='dropdown-item' to='/dashboard/minha-conta'>
											Minha conta
										</Link>
									</li>
									<li>
										<Link
											className='dropdown-item'
											to='/home'
											onClick={() => logout()}
										>
											Sair
										</Link>
									</li>
								</ul>
							</li>
						)}
					</ul>
				</div>
			</div>
		</nav>
	);
};

const mapStateToProps = (state) => ({
	usuario: state.authStore.usuario
});

export default connect(mapStateToProps, {
	logout
})(NavBar);
