import React from 'react';
import PropTypes from 'prop-types';
import { useField, Field } from 'formik';

const CustomCheckbox = props => {
    const [field, meta] = useField(props);
	const { className, label, id, showTextError = false } = props;
	return (
		<div className={`custom-control custom-control-inline`}>
			<Field {...field} {...props}>
				{({ field, meta }) => (
					<input
						{...field}
						{...props}
						type='checkbox'
						id={id}
						onChange={field.onChange}
						onBlur={field.onBlur}
						className={`form-control custom-control-input ${className} ${
							meta.error && `is-invalid`
						}`}
					/>
				)}
			</Field>
			{/* meta.touched &&  */}
			<label className='custom-control-label' htmlFor={id}>
				{label}
				{showTextError && meta.error ? (
					<div className='ml-1 form-be-custom-error'>{meta.error}</div>
				) : null}
			</label>
		</div>
	);
}

CustomCheckbox.propTypes = {
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	showTextError: PropTypes.bool,
}

export default CustomCheckbox
