import React from 'react';

import Title from '../../../components/common/Title';
import { callAlert } from '../../../../config/helper/utils';

const CarteiraRendaFixa = (props) => {
	return (
		<>
			<Title text='Carteira de Renda Fixa' />
			{callAlert(props)}
		</>
	);
};

export default CarteiraRendaFixa;
