import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import SimpleBar from 'simplebar-react';
import swal from 'sweetalert';

import {
	setObjetivoCurrent,
	getMeusObjetivos,
	remove,
	setShowModalObjetivos,
	setShowModalAnaliseObjetivo
} from './action/FazendoCaixaAction';
import {
	success,
	error,
	MSG_TOAST_ERRO_DE_NOVO
} from '../../../components/toast/action/ToastAction';
import { setLoading } from '../../../components/common/action/CommonAction';
import { setErroAutenticacao } from '../../../minimal/autenticacao/action/AuthAction';
import { formataMoeda } from '../../../../config/helper/utils';
import { calculaPrazo } from './helper/utils';
import Spinner from '../../../components/preLoader/Spinner';
import Button from '../../../components/form/Button';
import Row from '../../../components/common/Row';
import NaoHaObjetivos from './NaoHaObjetivos';
import { getLancamentosByObjetivoSK } from '../arrumandoCasa/action/ArrumandoCasaAction';

const ListaObjetivos = (props) => {
	const {
		loading,
		setLoading,
		setObjetivoCurrent,
		erroCountObjetivos,
		objetivos,
		getMeusObjetivos,
		success,
		error,
		remove,
		setShowModalObjetivos,
		setShowModalAnaliseObjetivo,
		setErroAutenticacao,
		getLancamentosByObjetivoSK,
		lancamentosObjetivo
	} = props;

	const [column, setColumn] = useState();
	const [order, setOrder] = useState(false); //Crescente

	useEffect(() => {
		setLoading(true);
		getMeusObjetivos().then(() => {
			setLoading(false);
		}).catch((err) => {
			if (err.code === 'NotAuthorizedException') {
				setErroAutenticacao(err);
			}
			setLoading(false);
		});

	}, [setLoading, getMeusObjetivos, setErroAutenticacao]);

	const handleRemove = (objetivo) => {
		if (lancamentosObjetivo && lancamentosObjetivo.length === 0) {
			let myhtml = document.createElement('div');
			myhtml.innerHTML = `Tem certeza que deseja apagar o objetivo de vida <b>${objetivo.nome
				}</b> com a meta de <b>${formataMoeda(objetivo.valorMeta)}</b>`;

			swal({
				title: 'Tem certaza?!',
				content: myhtml,
				icon: 'warning',

				buttons: {
					reenviar: {
						text: 'Cancelar',
						value: 'cancelar',
						className: 'btn-secondary'
					},

					confirmar: {
						text: 'Confirmar',
						value: 'confirmar',
						className: 'btn-primary'
					}
				}
			}).then((value) => {
				if (value === 'confirmar') {
					setLoading(true);
					remove(objetivo)
						.then(() => {
							success();
							setLoading(false);
						})
						.catch(() => {
							if (erroCountObjetivos >= 1) {
								error(MSG_TOAST_ERRO_DE_NOVO);
							} else {
								error();
							}
							setLoading(false);
						});
				} else {
					setObjetivoCurrent(null, false);
				}
			});
		} else {
			let myhtml = document.createElement('div');
			myhtml.innerHTML = `Vimos que você possui lançamentos atrelados ao objetivo: <b>${objetivo.nome.toUpperCase()}</b>, então não é possível exclui-lo. \n
				Caso deseje, você pode excluir os lançamentos atrelados a este objetivo e tentar novamente.`
			swal({
				title: 'Erro!',
				content: myhtml,
				icon: 'warning',

				buttons: {
					ok: {
						text: 'Ok',
						value: 'ok',
						className: 'btn-secondary'
					},
				}
			});
		}
	};

	if (loading) {
		return <Spinner id='spinner-centro-sm' />;
	}

	const renderHeader = () => (
		<tr>
			<th>Objetivo</th>
			<th style={{ width: '300px' }}>Descrição</th>
			<th
				onClick={() => {
					setColumn('dataVencimento');
					setOrder(!order);
				}}
			>
				Data de vencimento
				<span className='ml-2'>
					{order && column === 'dataVencimento' ? (
						<i className='fas fa-sort-up'></i>
					) : (
						<i className='fas fa-sort-down'></i>
					)}
				</span>
			</th>
			<th
				onClick={() => {
					setColumn('valorAtual');
					setOrder(!order);
				}}
			>
				Valor já acumulado
				<span className='ml-2'>
					{order && column === 'valorAtual' ? (
						<i className='fas fa-sort-up'></i>
					) : (
						<i className='fas fa-sort-down'></i>
					)}
				</span>
			</th>
			<th
				onClick={() => {
					setColumn('valorMeta');
					setOrder(!order);
				}}
			>
				Valor da meta
				<span className='ml-2'>
					{order && column === 'valorMeta' ? (
						<i className='fas fa-sort-up'></i>
					) : (
						<i className='fas fa-sort-down'></i>
					)}
				</span>
			</th>
			<th
				onClick={() => {
					setColumn('prazo');
					setOrder(!order);
				}}
			>
				Prazo
				<span className='ml-2'>
					{order && column === 'prazo' ? (
						<i className='fas fa-sort-up'></i>
					) : (
						<i className='fas fa-sort-down'></i>
					)}
				</span>
			</th>
			<th style={{ width: '125px' }}>Ações</th>
		</tr>
	);

	const renderRows = (order, column = 'valorMeta') => {
		return objetivos
			.sort((a, b) => {
				if (order) {
					return a[column] < b[column] ? -1 : a[column] > b[column] ? 1 : 0;
				} else {
					return b[column] < a[column] ? -1 : b[column] > a[column] ? 1 : 0;
				}
			})
			.map((objetivo, index) => (
				<tr key={index}>
					<td>
						<span className='text-gray-800'>{objetivo.nome}</span>
					</td>
					<td>{objetivo.descricao}</td>
					<td>{objetivo.dataVencimento}</td>
					<td>{formataMoeda(objetivo.valorAtual)}</td>
					<td>{formataMoeda(objetivo.valorMeta)}</td>
					<td>{calculaPrazo(objetivo.prazo)}</td>
					<td>
						<Button
							icon='fas fa-edit' title='Editar objetivo'
							classes='btn action-icon'
							onClick={() => {
								setObjetivoCurrent(objetivo);
								setShowModalObjetivos(true);
							}}
							disabled={false}
						/>

						<Button
							icon='fas fa-chart-bar' title='Análise do objetivo'
							classes='btn action-icon'
							onClick={() => {
								setLoading(true);
								setObjetivoCurrent(objetivo);
								getLancamentosByObjetivoSK(objetivo.sk).then(() => {
									setLoading(false);
									setShowModalAnaliseObjetivo(true);
								});
							}}
							disabled={false}
						/>
						<Button
							icon='fas fa-trash' title='Remover objetivo'
							classes='btn action-icon'
							onClick={() => {
								setLoading(true);
								getLancamentosByObjetivoSK(objetivo.sk).then(() => {
									setLoading(false);
									handleRemove(objetivo);
								});
							}}
							disabled={false}
						/>
					</td>
				</tr>
			));
	};

	return !loading && objetivos && objetivos.length > 0 ? (
		<>
			<Row>
				<div className='table-responsive fixed-first-row lista-height'>
					<SimpleBar style={{ height: '100%' }}>
						<table className='table table-centered mb-0'>
							<thead className='thead-light'>{renderHeader()}</thead>
							<tbody>{renderRows(order, column)}</tbody>
						</table>
					</SimpleBar>
				</div>
			</Row>
		</>
	) : (
		<NaoHaObjetivos height='60vh' />
	);
};

const mapStateToProps = (state) => ({
	objetivos: state.fazendoCaixaStore.objetivos,
	loading: state.commonStore.loading,
	erroCountObjetivos: state.fazendoCaixaStore.erroCountObjetivos,
	isErroExcluirObjetivoLancamento: state.fazendoCaixaStore.isErroExcluirObjetivoLancamento,
	lancamentosObjetivo: state.arrumaCasaStore.lancamentosObjetivo,
});

export default connect(mapStateToProps, {
	setObjetivoCurrent,
	setLoading,
	getMeusObjetivos,
	success,
	error,
	remove,
	setShowModalObjetivos,
	setShowModalAnaliseObjetivo,
	setErroAutenticacao,
	getLancamentosByObjetivoSK
})(ListaObjetivos);
