import React from 'react';

import Title from '../../../components/common/Title';
import { callAlert } from '../../../../config/helper/utils';

const CarteiraFundos = (props) => {
	return (
		<>
			<Title text='Carteira de Fundos de Investimentos - FIs' />
			{callAlert(props)}
		</>
	);
};

export default CarteiraFundos;
