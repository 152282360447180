import React from 'react';

const NaoHaAtividades = ({ height }) => {
	return (
		<div
			className='col-12 d-flex align-items-center justify-content-center'
			style={{ height: height }}
		>
			<div>
				<p className='h5 font-weight-bold'>
					{`Não encontramos nenhum lamnçamento neste mês. Bora cadastrar algo?`}
				</p>
			</div>
		</div>
	);
};


export default NaoHaAtividades;
