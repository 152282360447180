import { toast } from 'react-toastify';
import { css } from 'glamor';

import { SET_TOAST_ERRO, SET_TOAST_SUCESSO } from './types';

export const MSG_TOAST_SUCESSO = 'Operação realizada com sucesso! 🚀';
export const MSG_TOAST_ERRO = 'Ops, algo deu errado! 💩\n Vamos tentar novamente?';
export const MSG_TOAST_ERRO_DE_NOVO = `Péssimas notícias, continuamos com erro. \n
Você não quer tomar um café e tentar mais tarde? Enquanto isso, a gente vai ficar aqui trabalhando para solucionar isso. 
Te prometo!`;
export const MSG_TOAST_SUCESSO_SENHA_CEI = 'Eba! Deu tudo certo com o acesso ao CEI. \nAgora é com a gente. Estamos importando suas informações do CEI e te avisaremos assim que acabarmos. Até já! 🚀';
export const MSG_TOAST_ERRO_SENHA_CEI = 'Ops, algo deu errado com seu usuário e senha do CEI! 💩\n Vamos tentar novamente?';
export const MSG_TOAST_ERRO_DE_NOVO_SENHA_CEI = `Péssimas notícias, continuamos encontrando erros ao tentar usar seu usuário e senha. \n
Você não quer verificar se o usuário e a senha estão certos? Para isso, acesse: https://ceiapp.b3.com.br/CEI_Responsivo/login.aspx e verifique-os.`;
export const MSG_TOAST_OBJETIVO_COM_LANCAMENTO = ``;

export const success = (msg = MSG_TOAST_SUCESSO, options = {}) => {
	toast.success(msg, {
		// Merge additionals options
		...options,
		className: {
			color: '#FFF',
			minHeight: '60px',
			borderRadius: '8px',
			background: '#17bebb',
			boxShadow: '2px 2px 20px 2px rgba(0,0,0,0.3)'
		},
		progressClassName: css({
			background: '#007aff'
		})
	});
	return {
		type: SET_TOAST_SUCESSO
	};
};

export const error = (msg = MSG_TOAST_ERRO, options = {}) => {
	toast.error(msg, {
		...options,
		className: {
			color: '#fff',
			minHeight: '60px',
			borderRadius: '8px',
			background: '#ff5a5f',
			boxShadow: '2px 2px 20px 2px rgba(0,0,0,0.3)'
		},
		progressClassName: css({
			background: '#007aff'
		})
	});
	return {
		type: SET_TOAST_ERRO
	};
};
