import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import HeaderBotoesMeses from "../../../components/common/HeaderBotoesMeses";
import Card from "../../../components/common/Card";
import CardBody from "../../../components/common/CardBody";
import Title from "../../../components/common/Title";
import ListaAtividades from "./ListaAtividades";
import Erro500 from "../../../components/common/Erro500";
import Row from '../../../components/common/Row';
import {
  setErroAutenticacao,
  carregarUsuario,
} from "../../../minimal/autenticacao/action/AuthAction";
import { setLoading } from "../../../components/common/action/CommonAction";
import { setDataContextAtividades } from "./action/UsuarioAction";

const MinhasAtividades = (props) => {
  const {
    isErroGetAtividades,
    erroAtividades,
    carregarUsuario,
    erroAutenticacao,
    setErroAutenticacao,
    setLoading,
    setDataContextAtividades,
    dataContextAtividades
  } = props;

  useEffect(() => {
    setLoading(true);
    carregarUsuario()
      .then(() => {
        // console.log("carregaUsuario - meus lancamentos")
      })
      .catch((err) => {
        setLoading(false);
        setErroAutenticacao(err);
      });
    // eslint-disable-next-line
  }, []);

  if (erroAutenticacao !== null) {
    return <Redirect to="/login" />;
  } else if (isErroGetAtividades && erroAtividades !== null) {
    return <Erro500 erro={erroAtividades} />;
  }

  return (
    <>
      <Title
        text="Minhas Atividades"
        textTooltip={`Veja todas suas atividades de realizadas no Beganinha organizadas por mês`}
      />
      <Row>
        <div className="col-12">
          <Card>
            <CardBody classes="card-body-customheight">
              <Row>
                <HeaderBotoesMeses functionName={setDataContextAtividades} dataRedux={dataContextAtividades}/>
              </Row>

              <ListaAtividades />
            </CardBody>
          </Card>
        </div>
      </Row>
    </>
  );
};

const mapStateToProps = (state) => ({
  isErroGetAtividades: state.usuarioStore.isErroGetAtividades,
  erroAtividades: state.usuarioStore.erroAtividades,
  erroAutenticacao: state.authStore.erroAutenticacao,
  dataContextAtividades: state.usuarioStore.dataContextAtividades,
});

export default connect(mapStateToProps, {
  carregarUsuario,
  setErroAutenticacao,
  setLoading,
  setDataContextAtividades
})(MinhasAtividades);
