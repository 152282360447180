import React from 'react';
import PropTypes from 'prop-types';

const CardFooter = (props) => {
	const { children, classes } = props;

	return <div className={`card-footer py-3 ${classes}`}>{children}</div>;
};

CardFooter.propTypes = {
	classes: PropTypes.string
};

export default CardFooter;
