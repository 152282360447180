import React from 'react';
import PropTypes from 'prop-types'

const Row = (props) => {
	const { classes } = props;
	return <div className={`row ${classes}`}>{props.children}</div>;
};

Row.propTypes = {
    classes: PropTypes.string,
}

export default Row;
