import React from 'react';
import { connect } from 'react-redux';

import { setShowModalLancamentos } from './action/ArrumandoCasaAction';

import Row from '../../../components/common/Row';

const RowExplicacaoAnaliseFinanceira = (props) => {
	const { setShowModalLancamentos } = props;

	return (
		<>
			<Row classes='m-2'>
				<div className='col-11 text-left'>
					<span className='font-weigt-bold h4'>
						Análise financeira 50-30-20
					</span>
				</div>
				<div
					className='col-1'
					onClick={() => {
						setShowModalLancamentos(true);
					}}
				>
					<i className='fa-2x far fa-question-circle'></i>
				</div>
			</Row>
		</>
	);
};

const mapStateToProps = (state) => ({
	totalRecebimentos: state.arrumaCasaStore.totalRecebimentos
});

export default connect(mapStateToProps, { setShowModalLancamentos })(
	RowExplicacaoAnaliseFinanceira
);
