export const listaTipoAtivo = [
    {
        label: 'Selecione o tipo do ativo',
        value: ''
    },
    {
        label: 'Renda Fixa (Tesouro Direto, Certificado de Depósito Bancário (CDB), Letras de Crédito Imobiliário (LCI), Letras de Crédito do Agronegócio (LCA))',
        value: 'fixa'
    },
    {
        label: 'Renda variável (Ações da bolsa de valores, FIIs, ETFs, fundos multimercado, contratos futuros, criptomoedas)',
        value: 'variavel'
    }
];