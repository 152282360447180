import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Spinner from '../../../../../components/preLoader/Spinner';
import Row from '../../../../../components/common/Row';
// import If from '../../../../components/common/If';
import NaoHaAtivoCarteira from '../../minhaCarteira/NaoHaAtivoCarteira';
import {
	getMinhasInstituicoes,
	setInstituicaoCurrent,
	setLoadingGeneric
} from '../action/MinhasOperacoesAction';
import Card from '../../../../../components/common/Card';
import CardBody from '../../../../../components/common/CardBody';
import CardFooter from '../../../../../components/common/CardFooter';

const Instituicoes = (props) => {
	const {
		getMinhasInstituicoes,
		setInstituicaoCurrent,
		loadingInvestidorGeneric,
		instituicoes,
		setLoadingGeneric,
		isGetInstituicoes
	} = props;

	useEffect(() => {
		setLoadingGeneric(true);
		
		getMinhasInstituicoes().then(() => {
			setLoadingGeneric(false);
		});
		
		// eslint-disable-next-line
	}, [isGetInstituicoes]);

	if (loadingInvestidorGeneric) {
		return <Spinner id='spinner-centro-sm' />;
	}

	const getNomeInstituicao = (instituicao) => {
		const nomeSplit = instituicao.split('-');
		return nomeSplit[1];
	};

	return !loadingInvestidorGeneric &&
		instituicoes !== null &&
		instituicoes.length > 0 ? (
		<>
			{/* <If test={instituicoes.length > 1}> */}
			<Row>
				{instituicoes.map((item, index) => (
					<div className='col-3 mb-4' key={index}>
						<Card classes='shadow h-100 '>
							<CardBody>
								<p className='h4 mb-3'>
									<span className='mr-2'>Nome: </span>
									<span className='font-weight-bold'>
										{getNomeInstituicao(item.instituicao.label)}
									</span>
								</p>
								<p className='h5 mb-3'>
									<span className='mr-2'>Número da conta: </span>
									<span className='font-weight-bold'>{item.conta}</span>
								</p>
							</CardBody>
							<CardFooter>
								<div className='text-center'>
									<Link
										to='/dashboard/investidor/lista-operacoes'
										className='btn btn-primary'
										onClick={() => setInstituicaoCurrent(item)}
									>
										Operações
									</Link>
								</div>
							</CardFooter>
						</Card>
					</div>
				))}
			</Row>
			{/* </If> 
			<If test={instituicoes.length === 1 && instituicao !== null}>
				<ListaOperacoes />
			</If> */}
		</>
	) : (
		<Card>
			<CardBody classes='card-body-customheight'>
				<NaoHaAtivoCarteira height='60vh' />
			</CardBody>
		</Card>
	);
};

const mapStateToProps = (state) => ({
	loadingInvestidorGeneric: state.operacoesStore.loadingInvestidorGeneric,
	instituicoes: state.operacoesStore.instituicoes,
	instituicao: state.operacoesStore.instituicao,
	isGetInstituicoes: state.operacoesStore.isGetInstituicoes
});

export default connect(mapStateToProps, {
	getMinhasInstituicoes,
	setInstituicaoCurrent,
	setLoadingGeneric
})(Instituicoes);
