// //@ts-ignore
// import Finance from 'tvm-financejs';
// const finance = new Finance();

import { pmt } from 'financial';

export const TAXA_MEDIA_INVESTIMENTO_CURTO_PRAZO = 2.48;
export const TAXA_MEDIA_INVESTIMENTO_MEDIO_PRAZO = 2.97;
export const TAXA_MEDIA_INVESTIMENTO_LONGO_PRAZO = 2.92;
export const produtosObjetivos = [
	{
		prazo: 'Curto Prazo',
		produto: 'Tesouro SELIC',
		descricao: `O Tesouro Selic é um produto de Renda Fixa e é um título público emitido pelo Banco Central. 
                Possui baixa volatilidade e o montante investido é corrigido 
                pela variação da taxa básica de juros brasileira (taxa Selic) do período.`
	},
	{
		prazo: 'Curto Prazo',
		produto: 'CDB',
		descricao: `O CDB (Certificado de Depósito Bancário), um produto de Renda Fixa, é um título privado emitido por bancos,
        com o objetivo de captar recursos para financiar atividades. Em outras palavras, você empresta dinheiro ao banco emissor do CDB.`
	},
	{
		prazo: 'Curto Prazo',
		produto: 'LC',
		descricao:`Letra de Câmbio, um produto de Renda Fixa, é um título privado emitido por financeiras, com o objetivo de captar recursos para financiar atividades.
		Em outras palavras, você empresta dinheiro a entidade emissora da LC.`
	},
	{
		prazo: 'Curto Prazo',
		produto: 'Fundos de renda fixa',
		descricao: `Um fundo de renda fixa é um ativo financeiro que monta uma carteira de investimento com títulos públicos e privados. A característica desse ativo é o 
		seu dinamismo, pois o gestor pode adaptá-lo às mudanças do mercado.`
	},
	{
		prazo: 'Curto Prazo',
		produto: 'LCI/LCA',
		descricao: `LCI significa Letras de Crédito Imobiliário e são títulos emitidos por bancos com o objetivo de captar recursos para financiar o mercado imobiliário. 
        Já o LCA significa Letras de Crédito do Agronegócio e, também, são títulos emitidos por bancos, mas com o objetivo de financiar projetos de plantio e cultivo. 
        Ambos são isentos de imposto de renda sobre o ganho. Deixando-os mais atrativos em face a outros produtos de renda fixa.`
	},
	{
		prazo: 'Curto Prazo',
		produto: 'Contas digitais',
		descricao: `Conta digitais são contas correntes oferecidas por banco digitais e fintechs. Funcionam exatamente como uma conta convencional, 
        porém o saldo mantido nestas rende 100% do CDI.`
	},
	{
		prazo: 'Médio Prazo',
		produto: 'CDB',
		descricao: `O CDB (Certificado de Depósito Bancário), um produto de Renda Fixa, é um título privado emitido por bancos,
        com o objetivo de captar recursos para financiar atividades. Em outras palavras, você empresta dinheiro ao banco emissor do CDB.`
	},
	{
		prazo: 'Médio Prazo',
		produto: 'LC',
		descricao:`Letra de Câmbio, um produto de Renda Fixa, é um título privado emitido por financeiras, com o objetivo de captar recursos para financiar atividades.
		Em outras palavras, você empresta dinheiro a entidade emissora da LC.`
	},
	{
		prazo: 'Médio Prazo',
		produto: 'Fundos de renda fixa',
		descricao: `Um fundo de renda fixa é um ativo financeiro que monta uma carteira de investimento com títulos públicos e privados. A característica desse ativo é o 
		seu dinamismo, pois o gestor pode adaptá-lo às mudanças do mercado.`
	},
	{
		prazo: 'Médio Prazo',
		produto: 'LCI/LCA',
		descricao: `LCI significa Letras de Crédito Imobiliário e são títulos emitidos por bancos com o objetivo de captar recursos para financiar o mercado imobiliário. 
        Já o LCA significa Letras de Crédito do Agronegócio e, também, são títulos emitidos por bancos, mas com o objetivo de financiar projetos de plantio e cultivo. 
        Ambos são isentos de imposto de renda sobre o ganho. Deixando-os mais atrativos em face a outros produtos de renda fixa.`
	},
	{
		prazo: 'Médio Prazo',
		produto: 'Debêntures',
		descricao: `As debêntures são títulos de dívidas de um empresa, que em troca oferece direto de crédito ao investidor. Em outras palavras, você empresta dinheiro a empresa
		 emissora do debênture. Em geral, debêntures possuem uma rentabilidade superior a outros investimentos de renda fixa.`
	},
	{
		prazo: 'Médio Prazo',
		produto: 'Fundos multimercado',
		descricao: `Fundos multimercado são uma clase de ativo financeiro que tem como principal característica captar recursos de investidores e realizar aplicações em diversos tipos de ativos financeiros. 
		Um fundo multimercado pode mesclar aplicações em ativos como Tesouro Direto, ações, câmbio e CBD. A escolha dos ativos é dada a partir da estratégia de investimento do gestor do fundo.`
	},
	{
		prazo: 'Longo Prazo',
		produto: 'Ações',
		descricao: `Ações, também chamadas de papéis, são parcelas que compõem o capital social de uma empresa. As ações são unidades de títulos de emitidas por empresas de sociedade Anônima (S.A.). 
						As ações são negociadas na bolsa de valores e quando você as compra, você se torna sócio da empresa que emitiu a ação.`
	},
	{
		prazo: 'Longo Prazo',
		produto: 'Tesouro IPCA',
		descricao: `O Tesouro IPCA é um produto de Renda Fixa e é um título público emitido pelo Banco Central. 
                Possui baixa volatilidade e o montante investido é corrigido 
                por uma taxa pactuada no momento da contratação + a taxa do IPCA, o que significa dizer que o montante investido estará protegido da inflação e, portanto, não perderá seu poder de compra.`
	},
	{
		prazo: 'Longo Prazo',
		produto: 'FII',
		descricao: `FII significa Fundos de Investimentos Imobiliários e são fundos compostos por investimentos do setor imobiliário, como comprar imóveis para serem alugados. Os FIIs têm o patrimônio dividido em cotas de 
				valores iguais e são negociados na bolsa de valores. Os FIIs tem como principal vantagem a distribuição, em geral, mensal de proventos, que são oriundos dos alugueis, e estes são isentos de imposto de renda.`
	},
	{
		prazo: 'Longo Prazo',
		produto: 'Fundo de ações',
		descricao: `Fundos de ações é um ativo financeiro que tem como característica captar recursos de investidores e investí-los na bolsa de valores brasileira. 
		Ao investir em um fundo de ações, o investidor confia ao gestor do fundo a responsabilidade da escolha das ações das empresas. A escolha das ações é dada a partir da estratégia de investimento do gestor do fundo.`
	},
	{
		prazo: 'Longo Prazo',
		produto: 'ETF',
		descricao: `ETF significa exchange-traded fund e é um fundo de ações que replica um índice. Por exemplo, o BOVA11 é que replica o índice Bovespa (IBOV), portanto tem em sua carteira todas as ações que compõem o IBOV. 
					Um ETF tem seu patrimônio dividido igualmente em cotas e diferentemente de um fundo de ações, as cotas são adquiridas na bolsa de valores, ao invés de ser via uma instituição financeira, como um fundo de ações convencional.`
	},
	{
		prazo: 'Longo Prazo',
		produto: 'Fundos multimercado',
		descricao: `Fundos multimercado são uma clase de ativo financeiro que tem como principal característica captar recursos de investidores e realizar aplicações em diversos tipos de ativos financeiros. 
		Um fundo multimercado pode mesclar aplicações em ativos como Tesouro Direto, ações, câmbio e CBD. A escolha dos ativos é dada a partir da estratégia de investimento do gestor do fundo.`
	},
];

export const calculaPrazo = (prazo, longo = false) => {
	// console.log(prazo)
	if (prazo >= 0 && prazo <= 24) {
		return longo ? 'curto prazo (até dois anos)' : 'Curto Prazo';
	} else if (prazo > 24 && prazo <= 60) {
		return longo ? 'médio prazo (de dois a cinco anos)' : 'Médio Prazo';
	} else if (prazo > 60) {
		return longo ? 'longo prazo (mais de cinco anos)' : 'Longo Prazo';
	} else if (prazo === -1 || typeof prazo === undefined) {
		return '';
	}
};

export const obtemTaxa = (prazo) => {
	if (prazo >= 0 && prazo <= 24) {
		return TAXA_MEDIA_INVESTIMENTO_CURTO_PRAZO;
	} else if (prazo > 24 && prazo <= 60) {
		return TAXA_MEDIA_INVESTIMENTO_MEDIO_PRAZO;
	} else if (prazo > 60) {
		return TAXA_MEDIA_INVESTIMENTO_LONGO_PRAZO;
	} else if (prazo === -1 || typeof prazo === undefined) {
		return 0;
	}
};

export const calculaAporteMensal = ({ prazo, valorAtual, valorMeta }) => {
	const taxa = obtemTaxa(prazo) / 12 / 1000;
	const valorAtualNegativo = valorAtual * -1;
	// console.log(prazo)
	// console.log(valorAtualNegativo)
	// console.log(valorMeta)
	// console.log(taxa)

	return Math.abs(
		Math.round(pmt(taxa, prazo, valorAtualNegativo, valorMeta, 1) * 100) / 100
	);
	// return Math.abs(Math.round(
	// 	finance.PMT(
	// 		taxa,
	// 		prazo,
	// 		valorAtualNegativo,
	// 		valorMeta,
	// 		1
	// 	) * 100
	// ) / 100)
};

export const obtemTextoTaxaByPrazo = (prazo) => {
	if (prazo >= 0 && prazo <= 24) {
		return `A taxa usada para o cálculo do aporte é o acumulado dos últimos 12 meses do 
				Tesouro SELIC, pois este é um dos produtos recomendados para objetivos de ${calculaPrazo(
					prazo,
					true
				)}`;
	} else if (prazo > 24 && prazo <= 60) {
		return `A taxa usada para o cálculo do aporte é o acumulado dos últimos 12 meses do 
					CDB, pois este é um dos produtos recomendados para objetivos de ${calculaPrazo(
						prazo,
						true
					)}`;
	} else if (prazo > 60) {
		return `A taxa usada para o cálculo do aporte é o acumulado dos últimos 12 meses do 
			IBovespa, pois este é índice da nossa bolsa de valores, e renda variável é recomendada para objetivos de ${calculaPrazo(
				prazo,
				true
			)}`;
	} else if (prazo === -1 || typeof prazo === undefined) {
		return '';
	}
};