import moment from 'moment';
import swal from 'sweetalert';


export const arrayColorGraphics = ['#20c997', '#ef5054', '#74d7d7', '#087e8b', '#17a2b8', '##17bebb', '#007bff', '#fd7e14', '#ffc107', '#28a745', '#ff5a5f', '#f40e0e'];

export const setWidth = (value) => {
	if (value === 'toggled') {
		return '100%';
	} else {
		return '99%';
	}
};

export const isCpfValido = (numberWithMask = '') => {
	const number = numberWithMask
		.replace('.', '')
		.replace('.', '')
		.replace('-', '');
	var sum;
	var rest;
	sum = 0;
	if (number === '00000000000') return false;

	for (let i = 1; i <= 9; i++)
		sum = sum + parseInt(number.substring(i - 1, i)) * (11 - i);
	rest = (sum * 10) % 11;

	if (rest === 10 || rest === 11) rest = 0;
	if (rest !== parseInt(number.substring(9, 10))) return false;

	sum = 0;
	for (let i = 1; i <= 10; i++)
		sum = sum + parseInt(number.substring(i - 1, i)) * (12 - i);
	rest = (sum * 10) % 11;

	if (rest === 10 || rest === 11) rest = 0;
	if (rest !== parseInt(number.substring(10, 11))) return false;
	return true;
};

export const getMes = (valor) => {
	switch (valor) {
		case 0: {
			return 'Janeiro';
		}
		case 1: {
			return 'Fevereiro';
		}
		case 2: {
			return 'Março';
		}
		case 3: {
			return 'Abril';
		}
		case 4: {
			return 'Maio';
		}
		case 5: {
			return 'Junho';
		}
		case 6: {
			return 'Julho';
		}
		case 7: {
			return 'Agosto';
		}
		case 8: {
			return 'Setembro';
		}
		case 9: {
			return 'Outubro';
		}
		case 10: {
			return 'Novembro';
		}
		case 11: {
			return 'Dezembro';
		}
		default:
			return 'Mês';
	}
};

export const formataPorcentagem = (value) => {
	return parseFloat(value).toFixed(2)+"%";
};

export const formataMoeda = (value) => {
	// const valor = parseFloat(value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
	const valor = new Intl.NumberFormat('pt-BR', {
		style: 'currency',
		currency: 'BRL'
	}).format(value);

	return valor;
};

export const formataData = (date, mes = 'long') => {
	const data = moment(date);
	return new Intl.DateTimeFormat('pt-BR', {
		year: 'numeric',
		month: mes,
		day: '2-digit'
	}).format(data);
};

export const uniqueArray = (arrayOrigem) => {
	return arrayOrigem.reduce(function (p, c) {
		if (p.indexOf(c) < 0) p.push(c);
		return p;
	}, []);
};


export const calculaVariacao = (valorInicial, valorFinal) => {
	let result = 0;
	if (valorFinal !== 0 && valorInicial !== 0) {
		// if(valorInicial > valorFinal) { //acrescimo
			// console.log('aqui ' + valorInicial)
			result = ((valorInicial / valorFinal) - 1) * 100;
			return result.toFixed(2);
		// } else if (valorFinal > valorInicial) { //Decrescimo
		// 	console.log('aqui 2 ' + valorInicial)
		// 	result = ((valorFinal / valorInicial) - 1) * 100;
		// 	return result.toFixed(2);
		// }
		
	} else {
		//valor2 é zero (matematicamente, tende ao infinito)
		return 'valor2Zerado';
	}
};

export const callAlert = (props) => {
	let myhtml = document.createElement('div');
	myhtml.innerHTML = `Nos perdoe, mas ainda estamos trabalhando nessa parte do Be. Te prometo que em breve teremos novidades!`;

	swal({
		title: 'Te pedimos desculpas',
		content: myhtml,
		icon: 'warning',

		buttons: {
			confirmar: {
				text: 'Ok',
				value: props,
				className: 'btn-primary'
			}
		}
	}).then((props) => {
		return props ? props.history.push('/dashboard') : '';
	});
};


export const getNomeMesesDoPeriodo = (dataInicio, dataFim) => {
	const periodo = moment(dataFim).diff(dataInicio, 'months');
	const meses = [];
	for (let i = 0; i < periodo; i++) {
		const dataAtualInicio = moment()
			.utc()
			.subtract(i, 'month')
			.startOf('month')
			.month();
		meses.push(
			getMes(dataAtualInicio)
		);
	}
	return meses;
};