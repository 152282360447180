export const listaTipoOperacaoAtivo = [
    {
        label: 'Selecione o tipo da operação do ativo',
        value: ''
    },
    {
        label: 'Compra',
        value: 'C'
    },
    {
        label: 'Venda',
        value: 'V'
    }
];