import React from 'react';
import PropTypes from 'prop-types';
import CountUp from 'react-countup';
import Card from './Card';
import CardBody from './CardBody';

import { formataMoeda } from '../../../config/helper/utils';
import Row from './Row';

const CardValueWithLeftColor = (props) => {
	const { title, leftColor, icon, value, countup = false } = props;

	return (
		<Card classes={`h-100 ${leftColor}`} style={{maxHeight: '80px'}}>
			<CardBody classes='p-2'>
				<Row classes='no-gutters align-items-center'>
					<div className='col mr-1'>
						<div className='text-xs font-weight-bold text-primary text-uppercase'>
                            {title}
						</div>
						<div className='h5 mb-0 font-weight-bold text-gray-800'>
							{countup ? (
								<CountUp
									end={value}
									separator='.'
									decimals={2}
									decimal=','
									prefix='R$ '
								/>
							) : (
								formataMoeda(value)
							)}
						</div>
					</div>
					<div className='d-none d-xl-block'>
                     <i className={`${icon} fa-2x text-gray-300`}></i>
					</div>
				</Row>
			</CardBody>
		</Card>
	);
};

CardValueWithLeftColor.propTypes = {
	title: PropTypes.string.isRequired,
	leftColor: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
	value: PropTypes.number.isRequired,
	countup:PropTypes.bool,
};


export default CardValueWithLeftColor;
