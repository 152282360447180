import React from 'react';

import LogoBeganinha from '../../../layouts/components/common/SVGIcon';

const Header = () => {
	return (
		<>
			{/* Start landing Page Image */}
			<div className='landing'>
				<div className='home-wrap'>
					<div className='home-inner'></div>
				</div>
			</div>
			{/* End landing Page Image */}

			{/* Start Landing Page Caption */}
			<div className='caption text-left text-light'>
				<div className='container'>
					{/* <LogoBeganinha
						name='beganinha_branco'
						classNameCustom='logo-beganinha os-animation'
						data-animation='bounceInUp'
						data-delay='.6s'
					/> */}
					<div className='wow zoomIn' data-wow-delay='0.6s'>
						<LogoBeganinha
							name='beganinha_branco'
							classNameCustom='logo-beganinha'
						/>
					</div>
					{/* <h4
						className='text-white os-animation'
						data-animation='bounceInUp'
						data-delay='.8s'
					> */}
					<h4 className='text-white wow bounceInUp' data-wow-delay='0.8s'>
						Na dívida ou no investimento, a gente te ajuda.
					</h4>
					<a
						href='#vamos-comecar'
						className='btn btn-outline-primary btn-lg rounded-pill wow bounceInLeft text-white'
						data-wow-delay='1s'
					>
						<strong>Conheça e comece já</strong>
					</a>
				</div>
			</div>
			{/* End Landing Page Caption */}
			{/* Start Landing Page Bounde Down Arrow */}
			<a href='#vamos-comecar' className='down-arrow text-center'>
				<div className='arrow d-none d-md-block'>
					<i className='fas fa-arrow-circle-down'></i>
				</div>
			</a>
			{/* End Landing Page Bounde Down Arrow */}
		</>
	);
};

export default Header;
