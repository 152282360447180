import React from 'react';

import imgArrumandoCasa from '../../../assets/img/card4_arrumandoCasa.png';
import imgFazendoCaixa from '../../../assets/img/card5_fazendoCaixa.png';
import imgInvestidorSucesso from '../../../assets/img/card6_investidorSucesso.png';
import imgAprendendoNinja from '../../../assets/img/card7_aprendendoNinja.png';
import LinkAbraSuaConta from './LinkAbraSuaConta';

const ComoFunciona = () => {
	return (
		<div className='container-fluid' style={{ backgroundColor: 'white' }}>
			<div className='container py-4'>
				<div className='col-12 wow fadeInUp'>
					<h3 className='heading'>Como funciona</h3>
					<div className='heading-underline'></div>
				</div>
				<div className='row justify-content-center text-center px-lg-4 px-xl-5'>
					<div
						className='col-sm-12 col-md-6 col-lg-3 como-funciona-padding wow bounceInLeft mb-2'
						data-wow-delay='.2s'
					>
						<div className='comofunciona-item'>
							<img src={imgArrumandoCasa} alt='Arrumando' />
							<h4 className='text-center pt-4'>Arrume a casa</h4>
							<p className='lead'>
								Aqui você lança seus recebimentos, gastos e investimentos e nós faremos
								uma análise para onde seu dinheiro está indo.
							</p>
						</div>
					</div>
					<div
						className='col-sm-12 col-md-6 col-lg-3 como-funciona-padding wow bounceInLeft mb-2'
						data-wow-delay='.1s'
					>
						<div className='comofunciona-item'>
							<img src={imgFazendoCaixa} alt='Caixa' />
							<h4 className='text-center pt-4'>Faça um bom caixa</h4>
							<p className='lead'>
								Vamos te ajudar a criar objetivos financeiros e te mostraremos sua evolução.
							</p>
						</div>
					</div>
					<div
						className='col-sm-12 col-md-6 col-lg-3 como-funciona-padding wow bounceInRight mb-2'
						data-wow-delay='.1s'
					>
						<div className='comofunciona-item'>
							<img src={imgInvestidorSucesso} alt='Sucesso' />
							<h4 className='text-center pt-4'>
								Torne-se um investidor de sucesso
							</h4>
							<p className='lead'>
								Veja seus investimentos, faça análises sobre o desempenho e gerencie-os de acordo com seus objetivos de vida.
								Ah, também podemos te conectar ao CEI.
							</p>
						</div>
					</div>
					<div
						className='col-sm-12 col-md-6 col-lg-3 como-funciona-padding wow bounceInRight mb-2'
						data-wow-delay='.2s'
					>
						<div className='comofunciona-item'>
							<img src={imgAprendendoNinja} alt='Ninja' />
							<h4 className='text-center pt-4'>Receba dica dos Mestres</h4>
							<p className='lead'>
								Você terá acesso as melhores recomendações de investimentos,
								tanto de renda fixa como de renda variável.
							</p>
						</div>
					</div>
					<div
						className='col-12 col-lg-9 col-xl-8 text-center py-3 como-funciona-padding wow fadeInUp'
					>
						<p className='lead'>
							É bem legal, né?! E há muito mais coisa para conhecer e explorar.
						</p>
						<LinkAbraSuaConta />
					</div>

					{/*Fim da dov.row */}
				</div>
			</div>
		</div>
	);
};

export default ComoFunciona;
