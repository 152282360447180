import React from 'react';

import Row from '../../../components/common/Row';

const RowExplicacaoTotalAnualizado = () => {
	return (
		<>
			<Row classes='m-2'>
				<div className='col-11 text-left'>
					<span className='font-weigt-bold h4'>
						Lançamentos consolidados por mês
					</span>
				</div>
			</Row>
		</>
	);
};

export default RowExplicacaoTotalAnualizado;
