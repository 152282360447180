import React from 'react';

import Title from '../../../components/common/Title';
import { callAlert } from '../../../../config/helper/utils';

const CarteiraRendaFiis = (props) => {
	return (
		<>
			<Title text='Carteira de Fundo de Investimentos Imobiliários - FIIs' />
			{callAlert(props)}
		</>
	);
};

export default CarteiraRendaFiis;
