import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import SimpleBar from 'simplebar-react';

import {
	getMinhasContasPeriodoAnualizado,
	setLoadingLancamentosAnualizado
} from './action/ArrumandoCasaAction';
import { setErroAutenticacao } from '../../../minimal/autenticacao/action/AuthAction';
import { formataMoeda } from '../../../../config/helper/utils';
import Spinner from '../../../components/preLoader/Spinner';
import NaoHaLancamento from './NaoHaLancamento';
import If from '../../../components/common/If';

const TableTotalAnualizado = (props) => {
	const {
		lancamentosTotalAnualizado,
		loadingLancamentosAnualizado,
		dataContextAno,
		getMinhasContasPeriodoAnualizado,
		setLoadingLancamentosAnualizado,
		setErroAutenticacao
	} = props;

	useEffect(() => {
		setLoadingLancamentosAnualizado(true);
		getMinhasContasPeriodoAnualizado(dataContextAno)
			.then(() => {
				setLoadingLancamentosAnualizado(false);
			})
			.catch((err) => {
				if (err.code === 'NotAuthorizedException') {
					setErroAutenticacao(err);
				}
				setLoadingLancamentosAnualizado(false);
			});

		// eslint-disable-next-line
	}, [dataContextAno]);

	const [isRowExpanded, setIsRowExpanded] = useState(false);
	const [rowExpandedId, setRowExpandedId] = useState(-1);

	const handlePlusClick = (id) => {
		setIsRowExpanded(
			id !== rowExpandedId || rowExpandedId === -1 || isRowExpanded === false ? true : false
		);
		setRowExpandedId(id);
	};

	const renderRows = () => {
		return lancamentosTotalAnualizado.map((item, index) => (
			<>
				<tr key={`row-${index}`} onClick={() => handlePlusClick(index)}>
					<td
						className='text-center sticky-col first-col table-fonte-small'
						onClick={() => handlePlusClick(index)}
					>
						<i
							className={
								isRowExpanded && rowExpandedId === index
									? `fas fa-minus`
									: `fas fa-plus`
							}
						></i>
					</td>
					<td className='sticky-col second-col font-weight-bold table-fonte-small'>
						{item.lancamento.nome}
					</td>
					<td className='table-fonte-small'>
						{item.lancamento.valor_1
							? formataMoeda(item.lancamento.valor_1)
							: formataMoeda(0)}
					</td>
					<td className='table-fonte-small'>
						{item.lancamento.valor_2
							? formataMoeda(item.lancamento.valor_2)
							: formataMoeda(0)}
					</td>
					<td className='table-fonte-small'>
						{item.lancamento.valor_3
							? formataMoeda(item.lancamento.valor_3)
							: formataMoeda(0)}
					</td>
					<td className='table-fonte-small'>
						{item.lancamento.valor_4
							? formataMoeda(item.lancamento.valor_4)
							: formataMoeda(0)}
					</td>
					<td className='table-fonte-small'>
						{item.lancamento.valor_5
							? formataMoeda(item.lancamento.valor_5)
							: formataMoeda(0)}
					</td>
					<td className='table-fonte-small'>
						{item.lancamento.valor_6
							? formataMoeda(item.lancamento.valor_6)
							: formataMoeda(0)}
					</td>
					<td className='table-fonte-small'>
						{item.lancamento.valor_7
							? formataMoeda(item.lancamento.valor_7)
							: formataMoeda(0)}
					</td>
					<td className='table-fonte-small'>
						{item.lancamento.valor_8
							? formataMoeda(item.lancamento.valor_8)
							: formataMoeda(0)}
					</td>
					<td className='table-fonte-small'>
						{item.lancamento.valor_9
							? formataMoeda(item.lancamento.valor_9)
							: formataMoeda(0)}
					</td>
					<td className='table-fonte-small'>
						{item.lancamento.valor_10
							? formataMoeda(item.lancamento.valor_10)
							: formataMoeda(0)}
					</td>
					<td className='table-fonte-small'>
						{item.lancamento.valor_11
							? formataMoeda(item.lancamento.valor_11)
							: formataMoeda(0)}
					</td>
					<td className='table-fonte-small'>
						{item.lancamento.valor_12
							? formataMoeda(item.lancamento.valor_12)
							: formataMoeda(0)}
					</td>
					<td className='table-fonte-small'>
						{item.lancamento.total
							? formataMoeda(item.lancamento.total)
							: formataMoeda(0)}
					</td>
				</tr>
				<If test={isRowExpanded && rowExpandedId === index}>
					{item.lancamento.itens.map((itemRowExpand, idx) => (
						<tr key={`innex-row-${(index + idx)}`}>
							<td className='sticky-col first-col table-fonte-small'></td>
							<td className='sticky-col second-col table-fonte-small'>{itemRowExpand.nome}</td>
							<td className='table-fonte-small'>
								{itemRowExpand.valor_1
									? formataMoeda(itemRowExpand.valor_1)
									: formataMoeda(0)}
							</td>
							<td className='table-fonte-small'>
								{itemRowExpand.valor_2
									? formataMoeda(itemRowExpand.valor_2)
									: formataMoeda(0)}
							</td>
							<td className='table-fonte-small'>
								{itemRowExpand.valor_3
									? formataMoeda(itemRowExpand.valor_3)
									: formataMoeda(0)}
							</td>
							<td className='table-fonte-small'>
								{itemRowExpand.valor_4
									? formataMoeda(itemRowExpand.valor_4)
									: formataMoeda(0)}
							</td>
							<td className='table-fonte-small'>
								{itemRowExpand.valor_5
									? formataMoeda(itemRowExpand.valor_5)
									: formataMoeda(0)}
							</td>
							<td className='table-fonte-small'>
								{itemRowExpand.valor_6
									? formataMoeda(itemRowExpand.valor_6)
									: formataMoeda(0)}
							</td>
							<td className='table-fonte-small'>
								{itemRowExpand.valor_7
									? formataMoeda(itemRowExpand.valor_7)
									: formataMoeda(0)}
							</td>
							<td className='table-fonte-small'>
								{itemRowExpand.valor_8
									? formataMoeda(itemRowExpand.valor_8)
									: formataMoeda(0)}
							</td>
							<td className='table-fonte-small'>
								{itemRowExpand.valor_9
									? formataMoeda(itemRowExpand.valor_9)
									: formataMoeda(0)}
							</td>
							<td className='table-fonte-small'>
								{itemRowExpand.valor_10
									? formataMoeda(itemRowExpand.valor_10)
									: formataMoeda(0)}
							</td>
							<td className='table-fonte-small'>
								{itemRowExpand.valor_11
									? formataMoeda(itemRowExpand.valor_11)
									: formataMoeda(0)}
							</td>
							<td className='table-fonte-small'>
								{itemRowExpand.valor_12
									? formataMoeda(itemRowExpand.valor_12)
									: formataMoeda(0)}
							</td>
							<td className='table-fonte-small'>
								{itemRowExpand.total
									? formataMoeda(itemRowExpand.total)
									: formataMoeda(0)}
							</td>
						</tr>
					))}
				</If>
			</>
		));
	};

	if (loadingLancamentosAnualizado) {
		return <Spinner id='spinner-centro-sm' />;
	}

	return !loadingLancamentosAnualizado &&
		lancamentosTotalAnualizado &&
		lancamentosTotalAnualizado.length > 0 ? (
		<div className='table-responsive mt-sm-4'>
			<SimpleBar style={{ maxHeight: 600 }}>
				<table className='table table-striped mb-0'>
					<thead>
						<tr>
							<th scope='col' className='sticky-col first-col mx-0'>
								Detalhes
							</th>
							<th scope='col' className='sticky-col second-col mx-0'>
								Lançamento
							</th>
							<th scope='col'>Janeiro</th>
							<th scope='col'>Fevereiro</th>
							<th scope='col'>Março</th>
							<th scope='col'>Abril</th>
							<th scope='col'>Maio</th>
							<th scope='col'>Junho</th>
							<th scope='col'>Julho</th>
							<th scope='col'>Agosto</th>
							<th scope='col'>Setembro</th>
							<th scope='col'>Outubro</th>
							<th scope='col'>Novembro</th>
							<th scope='col'>Dezembro</th>
							<th scope='col'>Total</th>
						</tr>
					</thead>
					<tbody>{renderRows()}</tbody>
				</table>
			</SimpleBar>
		</div>
	) : (
		<NaoHaLancamento />
	);
};

const mapStateToProps = (state) => ({
	dataContextAno:
		state.commonStore.dataContextAno,
	lancamentosTotalAnualizado: state.arrumaCasaStore.lancamentosTotalAnualizado,
	loadingLancamentosAnualizado:
		state.arrumaCasaStore.loadingLancamentosAnualizado
});

export default connect(mapStateToProps, {
	setLoadingLancamentosAnualizado,
	getMinhasContasPeriodoAnualizado,
	setErroAutenticacao
})(TableTotalAnualizado);
