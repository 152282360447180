import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import amplify from 'aws-amplify';

import { handleConfigure } from './config/aws/config';
import App from './App';
import store from './store';

amplify.configure(handleConfigure());

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById('root')
);