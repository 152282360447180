import React from 'react';

import AbrirContaForm from './AbrirContaForm';
import Row from '../../../components/common/Row';

const AbrirConta = (props) => {
	// useEffect(() => {
	// 	if (usuario !== null && !usuario.userConfirmed) {
	// 		props.history.push('/confirmar-conta');
	// 	}
	// }, [usuario, props.history]);

	return (
		<Row>
			<div className='col-lg-5 d-none d-lg-block bg-register-image'></div>
			<div className='col-lg-7 px-4'>
				<AbrirContaForm />
			</div>
		</Row>
	);
};

export default AbrirConta;
