import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import Title from '../../../components/common/Title';
import Button from '../../../components/form/Button';
import Row from '../../../components/common/Row';
import Card from '../../../components/common/Card';
import CardBody from '../../../components/common/CardBody';
import CardFooter from '../../../components/common/CardFooter';
import CustomTextInput from '../../../components/form/CustomTextInput';
import CustomSwitchInput from '../../../components/form/CustomSwitchInput';
import {
	success,
	error,
	MSG_TOAST_ERRO_DE_NOVO
} from '../../../components/toast/action/ToastAction';

import {
	updateUserAttributes,
	setErroAutenticacao,
	carregarUsuario
} from '../../../minimal/autenticacao/action/AuthAction';
import { setLoading } from '../../../components/common/action/CommonAction';

const MinhaConta = (props) => {
	const {
		updateUserAttributes,
		carregarUsuario,
		setLoading,
		loading,
		usuario,
		erroCountUsuario,
		erroAutenticacao
	} = props;

	const [submit, setSubmit] = useState(false);

	const validationSchema = Yup.object({
		name: Yup.string().required('O nome é obrigatório'),
		family_name: Yup.string().required('O sobrenome é obrigatório'),
		email: Yup.string().required('O e-mail é obrigatório')
	});

	useEffect(() => {
		setLoading(true);
		carregarUsuario()
			.then(() => {
				// console.log("carregaUsuario - meus lancamentos")
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
				setErroAutenticacao(err);
			});
		// eslint-disable-next-line
	}, [submit]);

	const onSubmit = (values, { setSubmitting, resetForm }) => {
		updateUserAttributes(usuario, values)
			.then(() => {
				resetForm({});
				success();
				setSubmit(!submit);

			})
			.catch((err) => {
				if (err.code === 'NotAuthorizedException') {
					setErroAutenticacao(err);
				} else {
					if (erroCountUsuario >= 1) {
						error(MSG_TOAST_ERRO_DE_NOVO);
					} else {
						error();
					}
				}
				setSubmitting(false);
			});
	};

	if (erroAutenticacao !== null) {
		return <Redirect to='/login' />;
	}

	return (
		!loading &&
		usuario !== null && (
			<>
				<Title
					text='Minha Conta'
					textTooltip={`Aqui você confere dados da sua conta e pode alterá-los`}
				/>
				<Formik
					enableReinitialize
					initialValues={{
						name: usuario.attributes.name,
						family_name: usuario.attributes.family_name,
						email: usuario.attributes.email,
						isMostrarGuidedTour: usuario.attributes['custom:isMostrarGuidedTour'] === '1' ? true : false
					}}
					validationSchema={validationSchema}
					onSubmit={onSubmit}
				>
					{({ values, isSubmitting, resetForm }) => (
						<Row>
							<div className='col-12'>
								<Form className='form-be-custom'>
									<Card>
										<CardBody classes='card-body-customheight'>
											<Row classes='my-3 modal-header'>
												<div className='col-12 text-center'>
													<h3>Dados da sua conta</h3>
												</div>
											</Row>
											<Row classes='form-group'>
												<div className='col-12 mb-3 mb-sm-0'>
													<label>Meu CPF</label>
													<CustomTextInput
														name='name'
														type='text'
														className='custom-form-control-retangular'
														value={usuario.username}
														disabled
													/>
												</div>
											</Row>
											<Row classes='form-group'>
												<div className='col-sm-6 mb-3 mb-sm-0'>
													<label>Meu Nome</label>
													<CustomTextInput
														name='name'
														type='text'
														className='custom-form-control-retangular'
													/>
												</div>
												<div className='col-sm-6'>
													<label>Meu sobrenome</label>
													<CustomTextInput
														name='family_name'
														type='text'
														className='custom-form-control-retangular'
													/>
												</div>
											</Row>
											<Row classes='form-group'>
												<div className='col-12 mb-3 mb-sm-0'>
													<label>Meu e-mail</label>
													<CustomTextInput
														name='email'
														type='text'
														className='custom-form-control-retangular'
														value={usuario.attributes.email}
														disabled
													/>
												</div>
											</Row>
											<Row classes='form-group'>
												<div className='col-sm-10 mb-3 mb-sm-0'>
													<span>
														{`Deseja visualizar o Tour guiado do Beganinha?`}
													</span>
												</div>
												<div className='col-sm-2'>
													<CustomSwitchInput name='isMostrarGuidedTour' />
												</div>
											</Row>
										</CardBody>
										<CardFooter>
											<Row>
												<div className='col-12 text-right'>
													<Button
														classes='btn btn-secondary mr-3'
														texto='Cancelar'
														disabled={false}
														onClick={() => {
															resetForm({});
														}}
													/>
													<Button
														classes='btn btn-primary'
														texto='Salvar'
														disabled={isSubmitting}
														id='btn-salvar'
													/>
												</div>
											</Row>
										</CardFooter>
									</Card>
								</Form>
							</div>
						</Row>
					)}
				</Formik>
			</>
		)
	);
};

const mapStateToProps = (state) => ({
	loading: state.commonStore.loading,
	usuario: state.authStore.usuario,
	erroCountUsuario: state.authStore.erroCountUsuario,
	erroAutenticacao: state.authStore.erroAutenticacao
});

export default connect(mapStateToProps, {
	updateUserAttributes,
	carregarUsuario,
	setLoading
})(MinhaConta);
