import React from 'react';
import Title from '../../../components/common/Title';
import Row from '../../../components/common/Row';
// import GraficoRecebimentosGastos from './GraficoRecebimentosGastos';
// import MeusUltimosLancamentosTimeline from './MeusUltimosLancamentosTimeline';
import AnaliseFinanceira from './AnaliseFinanceira';

const ComoEstaDinheiro = (props) => {


	return (
		<>
			<Title
				text='Como está meu dinheiro'
				textTooltip={`Aqui fazemos uma análise financeira detalhada, baseado na regra 50-30-20, que te explicamos em detalhes, basta clicar no ícone "?".
							Também montamos uma tabela para te mostrar seus recebimentos, gastos e investimentos no período de um ano. Assim você pode analisar sua vida financeira de uma forma mais detalhada em uma única foto.`}
			/>
			<Row classes='my-3'>
				<div className='col-12'>
					<AnaliseFinanceira />
				</div>
			</Row>
			{/* <Row>
				<div className='col-xl-8 col-lg-7'>
					<GraficoRecebimentosGastos height={310} />
				</div>
				<div className='col-xl-4 col-lg-5'>
					<MeusUltimosLancamentosTimeline />
				</div>
			</Row> */}
		</>
	);
};

export default ComoEstaDinheiro;
