import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { setShowModalNovoAtivo } from '../minhasOperacoes/action/MinhasOperacoesAction';
import { clearErrorSemAtivos } from '../minhaCarteira/action/MinhaCarteiraAction';

const NaoHaAtivoCarteira = ({
	height,
	setShowModalNovoAtivo,
	clearErrorSemAtivos
}) => {
	useEffect(() => {
		clearErrorSemAtivos();
	}, []);

	return (
		<div
			className='col-12 d-flex align-items-center justify-content-center'
			style={{ height: height }}
		>
			<div>
				<p className='h5 text-justify'>
					{`Você ainda não cadastrou nenhum ativo financeiro. Vamos mudar isso? É fácil, você tem duas opções. A primeira é conectando o Beganinha ao CEI (Canal Eletrônico do Investidor) 
						e a partir disso, faremos a importações de todos os ativos de renda variável e do Tesouro Direto. Depois é só atualizar as informações de tempos em tempos e ficar de olhos nas análises que criamos.
						Ah, caso você tenha outros ativos, como um CDB, você terá que cadastrá-lo manualmente.  `}
				</p>
				<p className='h5 text-justify'>
					{`A segunda opções é cadastrar seus ativos manualmente, mas não se preocupe, tentando deixar o processo o mais fácil possível. Uma vez cadastrado, também, geramos uma análise completa de todos seus ativos. Tá bom?`}
				</p>
				<p className='h5 text-justify'>
					{`Para conectar o Beganinha ao CEI, clique no botão "Conecte-me ao CEI, já para começar a cadastrar seus ativos,  `}
					<Link
						to='/dashboard/investidor/minhas-operacoes'
						onClick={() => setShowModalNovoAtivo(true)}
						className='font-weight-bold'
					>
						clique aqui
					</Link>
					.
				</p>
				<p className='h5 font-weight-bold text-justify'>{`Não se preocupe quanto a segurança do processo de importação, pois nós não guardamos sua senha. Sempre que você quiser uma atualização, 
					você terá que repetir o processo. Combinado?`}</p>
			</div>
		</div>
	);
};

NaoHaAtivoCarteira.propTypes = {
	height: PropTypes.string.isRequired
};

export default connect(null, { setShowModalNovoAtivo, clearErrorSemAtivos })(
	NaoHaAtivoCarteira
);
