import React, { useState } from 'react';
import { connect } from 'react-redux';
import Row from '../../../components/common/Row';
import NaoHaLancamento from '../arrumandoCasa/NaoHaLancamento';
import SimpleBar from 'simplebar-react';
import { formataMoeda } from '../../../../config/helper/utils';
import Pagination from '../../../components/common/Pagination';


const LancamentoObjetivos = (props) => {
	const { 
		lancamentosObjetivo,
		loading
	} = props;
	const [column, setColumn] = useState();
	const [order, setOrder] = useState(false); //Crescente

	// =============== bloco necessário para a paginação funcionar =======
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage] = useState(5);

	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentLancamentosObjetivo =
		lancamentosObjetivo &&
		lancamentosObjetivo.slice(indexOfFirstItem, indexOfLastItem);
	// =============== fim do bloco necessário para a paginação funcionar =======	

	const renderHeader = () => (
		<tr>
			<th>Lançamento</th>
			<th style={{ width: '400px' }}>Descrição</th>
			<th
				onClick={() => {
					setColumn('dataVencimento');
					setOrder(!order);
				}}
			>
				Data
				<span className='ml-2'>
					{order && column === 'dataOperacao' ? (
						<i className='fas fa-sort-up'></i>
					) : (
						<i className='fas fa-sort-down'></i>
					)}
				</span>
			</th>
			<th
				onClick={() => {
					setColumn('valor');
					setOrder(!order);
				}}
			>
				Valor
				<span className='ml-2'>
					{order && column === 'valor' ? (
						<i className='fas fa-sort-up'></i>
					) : (
						<i className='fas fa-sort-down'></i>
					)}
				</span>
			</th>
		</tr>
	);

	const renderRows = (order, column = 'valor') => {
		return currentLancamentosObjetivo
			.sort((a, b) => {
				if (order) {
					return a[column] < b[column] ? -1 : a[column] > b[column] ? 1 : 0;
				} else {
					return b[column] < a[column] ? -1 : b[column] > a[column] ? 1 : 0;
				}
			})
			.map((lancamento, index) => (
				<tr key={index}>
					<td>
						<span className='text-gray-800'>{lancamento.nome}</span>
					</td>
					<td>{lancamento.descricao}</td>
					<td>{lancamento.dataOperacao}</td>
					<td>{formataMoeda(lancamento.valor)}</td>
				</tr>
			));
	};

	return !loading && currentLancamentosObjetivo && currentLancamentosObjetivo.length > 0 ? (
		<>
			<Row>
				<div className='table-responsive fixed-first-row lista-height'>
					<SimpleBar>
						<table className='table table-centered mb-0'>
							<thead className='thead-light'>{renderHeader()}</thead>
							<tbody>{renderRows(order, column)}</tbody>
						</table>
					</SimpleBar>
				</div>
			</Row>
			{currentLancamentosObjetivo && currentLancamentosObjetivo.length > 1 && (
					<Pagination
						activePage={currentPage}
						itemsPerPage={itemsPerPage}
						totalItems={lancamentosObjetivo ? lancamentosObjetivo.length : 0}
						currentPage={currentPage}
						setCurrentPage={setCurrentPage}
					/>
				)}			
		</>
	) : (
		<NaoHaLancamento height='60vh' />
	);
};

const mapStateToProps = (state) => ({
	lancamentosObjetivo: state.arrumaCasaStore.lancamentosObjetivo,
	loading: state.commonStore.loading,
});
export default connect(mapStateToProps)(LancamentoObjetivos);
