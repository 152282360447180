import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

import SimulacaoObjetivo from './SimulacaoObjetivo';
import {
	setObjetivoCurrent,
	setShowModalAnaliseObjetivo
} from './action/FazendoCaixaAction';
import { setTabActiveById } from '../../../components/tabs/action/TabsAction';

import Row from '../../../components/common/Row';
import Tabs from '../../../components/tabs/Tabs';
import TabItem from '../../../components/tabs/TabItem';
import TabContent from '../../../components/tabs/TabContent';
import Button from '../../../components/form/Button';
import OpcoesInvestimentos from './OpcoesInvestimentos';
import LancamentoObjetivos from './LancamentoObjetivos';

const ModalAnaliseObjetivo = (props) => {
	const {
		showModalAnaliseObjetivo,
		setObjetivoCurrent,
		setShowModalAnaliseObjetivo,
		setTabActiveById,
		objetivo
	} = props;

	const closeUp = () => {
		setShowModalAnaliseObjetivo(false);
		setTabActiveById(1); //setando o ID da primeira aba para garantir que seja a primeira a ser reebixida em caso de reabertura do modal
		setObjetivoCurrent(null);
	};

	useEffect(() => {
		setTabActiveById(1); //setando o ID para que seja apontado para a primeira ABA na primeira vez.

		// eslint-disable-next-line
	}, []);

	return objetivo && (
		<Modal
			open={showModalAnaliseObjetivo}
			onClose={() => closeUp()}
			center
			classNames={{
				modal: 'customModal-evolucaoObjetivo'
			}}
		>
			<Row classes='my-3'>
				<div className='col-12 text-center'>
					<h3>Análise do meu objetivo <b>{objetivo.nome.toUpperCase()}</b></h3>
				</div>
			</Row>
			<Row classes='my-3'>
				<div className='col-12'>
					<Tabs>
						<TabItem id={1} text='Meus lançamentos' />
						<TabItem id={2} text='Simulação' />
						<TabItem id={3} text='Onde Investir' />
					</Tabs>
					<TabContent id={1}>
						<LancamentoObjetivos />
					</TabContent>
					<TabContent id={2}>
						<SimulacaoObjetivo />
					</TabContent>
					<TabContent id={3}>
						<OpcoesInvestimentos />
					</TabContent>
				</div>
			</Row>
			<Row>
				<div className='text-right col-12'>
					<Button
						classes='btn btn-primary'
						texto='Ok'
						id='btn-ok'
						disabled={false}
						onClick={() => {
							closeUp();
						}}
					/>
				</div>
			</Row>
		</Modal>
	);
};

const mapStateToProps = (state) => ({
	showModalAnaliseObjetivo: state.fazendoCaixaStore.showModalAnaliseObjetivo,
	objetivo: state.fazendoCaixaStore.objetivo
});

export default connect(mapStateToProps, {
	setObjetivoCurrent,
	setShowModalAnaliseObjetivo,
	setTabActiveById
})(ModalAnaliseObjetivo);
