import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import swal from 'sweetalert';

import LogoBeganinha from '../../../components/common/SVGIcon';
import Button from '../../../components/form/Button';
import CustomTextInput from '../../../components/form/CustomTextInput';
import CustomMaskedTextInput from '../../../components/form/CustomMaskedTextInput';
import { isCpfValido } from '../../../../config/helper/utils';
import Row from '../../../components/common/Row';
import { novoCodigoSenha } from '../action/AuthAction';

const EsqueciSenha = (props) => {
	const { novoCodigoSenha, history } = props;
	const [INITIAL_VALUES] = useState({
		cpf: '',
		codigo: '',
		senha: '',
		senhaRepita: ''
	});
	
	const validationSchema = Yup.object({
		cpf: Yup.string().test('CPF-valido', 'CPF Inválido', (value) =>
			isCpfValido(value)
		),
		codigo: Yup.string()
			.length(6, 'Digite seu código com 6 dígitos')
			.matches(/[0-9]+/gi, 'Somente números')
			.required('O código é obrigatório'),
		senha: Yup.string()
			.min(
				8,
				'A senha deve ter no mínimo oito caracteres com letras e números'
			)
			.required('A senha é obrigatória'),
		senhaRepita: Yup.string()
			.min(8, 'A senha deve ter no mínimo oito caracteres')
			.oneOf([Yup.ref('senha'), null], 'As senhas devem ser iguais')
			.required('A senha é obrigatória')
	})
	
	const onSubmitNovaSenhaCodigo = (values, { setFieldError, setSubmitting }) => {
		novoCodigoSenha(
				values.cpf,
				values.codigo,
				values.senha
			).then(() => {
				swal({
					title: 'Tudo certo!',
					text:
					'Tua nova senha foi alterada e já está pronta para uso.',
				icon: 'success',
				buttons: {
					ok: {
						text: 'Ok',
						className: 'btn-primary'
					}
				}
			}).then(() => {
				history.push('/login');
			});
		}).catch((err) => {
			if (err.code === 'CodeMismatchException') {
				setFieldError('codigo', 'codigo inválido');
			}
			setSubmitting(false);
		});
	}
	
	return (
		<Row>
			<div className='col-lg-5 d-none d-lg-block bg-esqueci-senha-image'></div>
			<div className='col-lg-7'>
				<div className='p-5'>
					<div className='d-flex justify-content-center py-3'>
						<Link to='/home'>
							<LogoBeganinha name='be_preto' classNameCustom='logo-be' />
						</Link>
					</div>
					<div className='text-center'>
						<h1 className='h4 py-3'>Esqueci minha senha</h1>
					</div>
					<Formik
						initialValues={INITIAL_VALUES}
						validationSchema={validationSchema}
						onSubmit={onSubmitNovaSenhaCodigo}
					>
						{({ handleSubmit, isSubmitting }) => (
							<Form className='form-be-custom' onSubmit={handleSubmit}>
								<div className='form-group'>
									<CustomMaskedTextInput
										name='cpf'
										type='tel'
										placeholder='CPF'
										mask={[
											/[0-9]/,
											/\d/,
											/\d/,
											'.',
											/\d/,
											/\d/,
											/\d/,
											'.',
											/\d/,
											/\d/,
											/\d/,
											'-',
											/\d/,
											/\d/
										]}
										className='custom-form-control'
									/>
								</div>
								<div className='form-group'>
									<CustomTextInput
										name='codigo'
										type='tel'
										maxLength='6'
										placeholder='Código de verificação'
										className='custom-form-control'
									/>
								</div>
								<Row classes='form-group'>
									<div className='col-sm-6 mb-3 mb-sm-0'>
										<CustomTextInput
											name='senha'
											type='password'
											placeholder='Senha com letras e números'
											className='custom-form-control'
										/>
									</div>
									<div className='col-sm-6'>
										<CustomTextInput
											name='senhaRepita'
											type='password'
											placeholder='Repita a senha'
											className='custom-form-control'
										/>
									</div>
								</Row>
								<Button
									classes='btn btn-secondary btn-block rounded-pill'
									texto='Alterar senha'
									disabled={isSubmitting}
								/>
							</Form>
						)}
					</Formik>
				</div>
			</div>
		</Row>
	);
};

export default compose(
	withRouter,
	connect(null, { novoCodigoSenha }))(EsqueciSenha);
