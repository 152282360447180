import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// import SimpleBar from 'simplebar-react';

import { formataMoeda } from '../../../../../config/helper/utils';
import Card from '../../../../components/common/Card';
import CardBody from '../../../../components/common/CardBody';
import CardHeader from '../../../../components/common/CardHeader';
import CardFooter from '../../../../components/common/CardFooter';
import Button from '../../../../components/form/Button';
// import NaoHaAtivoCarteira from '../NaoHaAtivoCarteira';
import Row from '../../../../components/common/Row';
import Spinner from '../../../../components/preLoader/Spinner';
import {
	getMinhaCarteira,
	setShowModalCarteira,
	setIdCarteiraCurrent
} from './action/MinhaCarteiraAction';
import { setShowModalNovoAtivo } from '../minhasOperacoes/action/MinhasOperacoesAction';
import { setErroAutenticacao } from '../../../../minimal/autenticacao/action/AuthAction';
import { setLoading } from '../../../../components/common/action/CommonAction';

const ListaCarteira = (props) => {
	const {
		getMinhaCarteira,
		setIdCarteiraCurrent,
		loading,
		carteiras,
		setShowModalCarteira,
		setErroAutenticacao,
		setLoading
	} = props;

	useEffect(() => {
		setLoading(true);
		getMinhaCarteira()
			.then(() => {
				setLoading(false);
			})
			.catch((err) => {
				if (err.code === 'NotAuthorizedException') {
					setErroAutenticacao(err);
				}
				setLoading(false);
			});

		// eslint-disable-next-line
	}, []);

	if (loading) {
		return <Spinner id='spinner-centro-lg' />;
	}

	const getNomeInstituicao = (instituicao) => {
		const nomeSplit = instituicao.split('-');
		return nomeSplit[1];
	};

	return (
		!loading &&
		carteiras !== null &&
		carteiras.length > 0 && (
			<>
				{/* <Title
					text='Minha Carteira'
					textTooltip={`Aqui te mostramos sua carteira com todos seus ativos da instituição que você selecionou.`}
				/> */}
				<Row>
					{carteiras.map((item, i) => (
						<div className='col-4' key={i}>
							<Card classes='mb-3 border-primary'>
								<CardHeader classes='text-center'>
									<Row>
										<div className='col-12 text-left'>
											<span>Instituição:</span>{' '}
											<span className='font-weight-bold'>
												{getNomeInstituicao(item.instituicao)}
											</span>
										</div>
									</Row>
									<Row>
										<div className='col-12 text-left'>
											<span>Conta:</span>{' '}
											<span className='font-weight-bold'>{item.conta}</span>
										</div>
									</Row>
								</CardHeader>
								<CardBody>
									<Row classes='mb-2'>
										<div className='col-12 text-left'>
											<p className='font-weight-bold pl-3'>
												Valores da Carteira
											</p>
										</div>
									</Row>
									<Row classes='mb-3'>
										<div className='col-6'>Valor carteira Renda Variável: </div>
										<div className='col-6 font-weight-bold'>
											{formataMoeda(item.valorTotalCarteiraRV)}
										</div>
									</Row>
									<Row classes='mb-3'>
										<div className='col-6'>Valor carteira Renda Fixa: </div>
										<div className='col-6 font-weight-bold'>
											{formataMoeda(item.valorTotalCarteiraRF)}
										</div>
									</Row>
								</CardBody>
								<CardFooter>
									<div className='col-12 text-center'>
										<p className='font-weight-bold pl-3'>
											<Link
												to='/dashboard/investidor/lista-carteira'
											>
												<Button
													icon='fas fa-plus'
													texto='Detalhes'
													classes='btn btn-primary'
													onClick={() => {
														setIdCarteiraCurrent(item.sk);
													}}
													disabled={false}
												/>
											</Link>
										</p>
									</div>
								</CardFooter>
							</Card>
						</div>
					))}
				</Row>
				{/* <Row>
					<div className='col-12 text-right'>
						<Button
							classes='btn btn-secondary mr-3'
							texto='Voltar'
							disabled={false}
							onClick={() => goBack()}
						/>
						<Button
							icon='fas fa-plus'
							texto='Novo Ativo Financeiro'
							classes='btn btn-primary'
							onClick={() => setShowModalNovoAtivo(true)}
							disabled={false}
						/>
					</div>
				</Row>*/}
			</>
		)
	);
};

const mapStateToProps = (state) => ({
	loading: state.commonStore.loading,
	erroInvestidorCarteira: state.carteiraStore.erroInvestidorCarteira,
	isErroGetInvestidorCarteira: state.carteiraStore.isErroGetInvestidorCarteira,
	isErroGetAutorizacaoCei: state.usuarioStore.isErroGetAutorizacaoCei,
	erroConnectmeCei: state.usuarioStore.erroConnectmeCei,
	carteiras: state.carteiraStore.carteiras
});

export default connect(mapStateToProps, {
	getMinhaCarteira,
	setShowModalNovoAtivo,
	setErroAutenticacao,
	setLoading,
	setShowModalCarteira,
	setIdCarteiraCurrent
})(ListaCarteira);
