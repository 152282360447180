import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import If from '../../../../../components/common/If';
import Spinner from '../../../../../components/preLoader/Spinner';
import Row from '../../../../../components/common/Row';
import NaoHaAtivoCarteira from '../../minhaCarteira/NaoHaAtivoCarteira';
import Title from '../../../../../components/common/Title';
import {
	getMinhasOperacoesBySk,
	setLoadingGeneric,
	setShowModalNovoAtivo,
	setInstituicaoCurrent
} from '../action/MinhasOperacoesAction';
import Card from '../../../../../components/common/Card';
import CardBody from '../../../../../components/common/CardBody';
import CardHeader from '../../../../../components/common/CardHeader';
import HeaderBotoesAnos from '../../../../../components/common/HeaderBotoesAnos';
import ErroVoltar from '../../../../../components/common/ErroVoltar';
import Button from '../../../../../components/form/Button';
import ModalAddUpdateOperacaoAtivo from './ModalAddUpdateOperacaoAtivo';
import Erro500 from '../../../../../components/common/Erro500';

import ListaDetalhesOperacoes from './ListaDetalhesOperacoes';

const ListaOperacoes = (props) => {
	const {
		getMinhasOperacoesBySk,
		loadingInvestidorGeneric,
		operacoesRV,
		operacoesRF,
		dataContextAno,
		instituicao,
		setLoadingGeneric,
		setShowModalNovoAtivo,
		history,
		setInstituicaoCurrent,
		isGetOperacoes,
		erroGetInvestidorGeneric,
		erroInvestidorGeneric,
		isErroGetAutorizacaoCei,
		erroConnectmeCei
	} = props;

	const [erroVoltar, setErroVoltar] = useState(false);

	useEffect(() => {
		// console.log('insti', instituicao)
		setLoadingGeneric(true);
		if (instituicao) {
			getMinhasOperacoesBySk(dataContextAno, instituicao.sk).then(() => {
				setLoadingGeneric(false);
			});
		} else {
			setErroVoltar(true);
			setLoadingGeneric(false);
		}

		// eslint-disable-next-line
	}, [dataContextAno, isGetOperacoes]);

	if (loadingInvestidorGeneric) {
		return <Spinner id='spinner-centro-sm' />;
	}

	if (erroVoltar) {
		return <ErroVoltar path='/dashboard/investidor/minhas-operacoes' />;
	}

	if (erroGetInvestidorGeneric || isErroGetAutorizacaoCei) {
		let msg = 'erro padrão';
		if (erroConnectmeCei !== null) {
			msg = erroConnectmeCei;
		} else if (erroGetInvestidorGeneric !== null) {
			msg = erroInvestidorGeneric;
		}
		return <Erro500 erroMsg={msg} />;
	}

	const goBack = () => {
		setInstituicaoCurrent(null);
		history.push('/dashboard/investidor/minhas-operacoes');
	};

	const getNomeInstituicao = (instituicao) => {
		const nomeSplit = instituicao.split('-');
		return nomeSplit[1];
	};

	return (
		<>
			<Title
				text='Minhas Operações'
				textTooltip={`Aqui te mostramos suas operações divididas por ano.`}
			/>
			<Row classes='mb-3'>
				<HeaderBotoesAnos />
			</Row>
			<Card classes='mb-3'>
				<CardHeader classes='h4 text-center'>
					<Row>
						<div className='div-12'>
							<span className='font-weight-bold'>Instituição:</span>{' '}
							{getNomeInstituicao(instituicao.instituicao.label)}
						</div>
					</Row>
				</CardHeader>
				<CardBody>
					<ListaDetalhesOperacoes operacoes={operacoesRV} title='Operações de ativos de renda variável' />
					<ListaDetalhesOperacoes operacoes={operacoesRF} title='Operações de ativos de renda fixa'/>
					<If
						test={
							(operacoesRV === null ||
							operacoesRV.length === 0) &&
							(operacoesRF === null ||
							operacoesRF.length === 0)
						}
					>
						<NaoHaAtivoCarteira height='60vh' />
					</If>
				</CardBody>
			</Card>
			<Row>
				<div className='col-12 text-right'>
					<Button
						classes='btn btn-secondary mr-3'
						texto='Voltar'
						disabled={false}
						onClick={() => goBack()}
					/>
					<Button
						icon='fas fa-plus'
						texto='Nova operação'
						classes='btn btn-primary'
						onClick={() => setShowModalNovoAtivo(true)}
						disabled={false}
					/>
				</div>
			</Row>
			<ModalAddUpdateOperacaoAtivo />
		</>
	);
};

const mapStateToProps = (state) => ({
	loadingInvestidorGeneric: state.operacoesStore.loadingInvestidorGeneric,
	operacoesRV: state.operacoesStore.operacoesRV,
	operacoesRF: state.operacoesStore.operacoesRF,
	instituicao: state.operacoesStore.instituicao,
	isGetOperacoes: state.operacoesStore.isGetOperacoes,
	dataContextAno: state.commonStore.dataContextAno,
	erroGetInvestidorGeneric: state.operacoesStore.erroGetInvestidorGeneric,
	erroInvestidorGeneric: state.operacoesStore.erroInvestidorGeneric,
	isErroGetAutorizacaoCei: state.usuarioStore.isErroGetAutorizacaoCei,
	erroConnectmeCei: state.usuarioStore.erroConnectmeCei
});

export default connect(mapStateToProps, {
	getMinhasOperacoesBySk,
	setLoadingGeneric,
	setShowModalNovoAtivo,
	setInstituicaoCurrent
})(ListaOperacoes);
