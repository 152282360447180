import {
	GET_MEUS_OBJETIVOS,
	ERROR_OBJETIVOS,
	ADD_OBJETIVO,
	UPDATE_OBJETIVO,
	HANDLE_OPTION_OBJETIVOS,
	SET_OBJETIVO,
	SET_SHOW_MODAL_OBJETIVOS,
	SET_SHOW_MODAL_ANALISE_OBJETIVO,
	SET_LOADING_OBJETIVOS,
	REMOVE_OBJETIVO,
	GET_MEUS_OBJETIVOS_DETALHES,
	SET_LOADING_OBJETIVOS_DETALHES,
	SET_INDEX_ROW,
	SET_OBJETIVOS_DETALHES_TO_NULL,
	SET_TOTAL_ACUMULADO_OBJETIVOS,
	GET_ARRAY_SERIE_MEUS_OBJETIVOS,
	CLEAR_STATE_OBJETIVO,
	ERROR_OBJETIVOS_COM_LANCAMENTOS
} from './types';

const INITIAL_STATE = {
	objetivos: null,
	objetivo: null,
	loadingObjetivos: true,
	erroObjetivos: null,
	erroCountObjetivos: 0,
	isErroGetObjetivos: false,
	isErroExcluirObjetivoLancamento: false,
	showModalObjetivos: false,
	showModalAnaliseObjetivo: false,
	optsObjetivos: [],
	objetivoDetalhes: null,
	loadingObjetivoDetalhes: true,
	idx: -1,
	totalObjetivosAcumulado: 0,
	arraySerieGraficoPrazoObjetivo: null
};

export const fazendoCaixaReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case GET_MEUS_OBJETIVOS: {
			return {
				...state,
				objetivos: action.payload,
				errorObjetivos: null,
				isErroGetObjetivos: false
			};
		}
		case GET_MEUS_OBJETIVOS_DETALHES: {
			return {
				...state,
				objetivoDetalhes: action.payload,
				errorObjetivos: null,
				isErroGetObjetivos: false,
			};
		}
		case GET_ARRAY_SERIE_MEUS_OBJETIVOS: {
			return {
				...state,
				arraySerieGraficoPrazoObjetivo: action.payload[0],
				objetivos: action.payload[1],
				loadingObjetivos: false
			};
		}
		case ERROR_OBJETIVOS: {
			const hasCount = action.payload[2] && ++state.erroCountObjetivos;
			return {
				...state,
				erroObjetivos: action.payload[0],
				isErroGetObjetivos: action.payload[1],
				erroCountObjetivos: hasCount,
				optsObjetivos: []
			};
		}
		case ERROR_OBJETIVOS_COM_LANCAMENTOS: {
			return {
				...state,
				isErroExcluirObjetivoLancamento: true,
				isErroGetObjetivos: false
			};
		}
		case HANDLE_OPTION_OBJETIVOS: {
			return {
				...state,
				optsObjetivos: action.payload,
				isErroGetObjetivos: false
			};
		}
		case SET_OBJETIVO: {
			return {
				...state,
				objetivo: action.payload,
				erroObjetivos: null
			};
		}
		case SET_TOTAL_ACUMULADO_OBJETIVOS: {
			return {
				...state,
				totalObjetivosAcumulado: action.payload
			};
		}
		case SET_SHOW_MODAL_OBJETIVOS: {
			return {
				...state,
				showModalObjetivos: action.payload
			};
		}
		case SET_SHOW_MODAL_ANALISE_OBJETIVO: {
			return {
				...state,
				showModalAnaliseObjetivo: action.payload
			};
		}
		case SET_LOADING_OBJETIVOS: {
			return {
				...state,
				loadingObjetivos: action.payload,
			};
		}
		case SET_LOADING_OBJETIVOS_DETALHES: {
			return {
				...state,
				loadingObjetivoDetalhes: action.payload,
			};
		}
		case SET_OBJETIVOS_DETALHES_TO_NULL: {
			return {
				...state,
				objetivoDetalhes: null
			};
		}
		case SET_INDEX_ROW: {
			return {
				...state,
				idx: action.payload,
			};
		}
		case CLEAR_STATE_OBJETIVO: {
			return INITIAL_STATE;
		}
		case ADD_OBJETIVO: {
			return {
				...state,
				objetivos: [action.payload, ...state.objetivos],
				objetivo: null,
				erroObjetivos: null,
				erroCountObjetivos: 0
			};
		}
		case UPDATE_OBJETIVO: {
			return {
				...state,
				objetivos: state.objetivos.map((plano) =>
					plano.sk === action.payload.sk ? action.payload : plano
				),
				plano: null,
				erroObjetivos: null,
				erroCountObjetivos: 0
			};
		}
		case REMOVE_OBJETIVO: {
			return {
				...state,
				objetivos: state.objetivos.filter(
					(objetivo) => objetivo.sk !== action.payload
				),
				plano: null,
				erroObjetivos: null,
				erroCountObjetivos: 0
			};
		}
		default:
			return state;
	}
};
